import { Currency } from '../utils/interfaces'

export enum RequestedProduct {
  SEPA_PAYMENTS = 'SEPA_PAYMENTS',
  INTERNATIONAL_PAYMENTS = 'INTERNATIONAL_PAYMENTS',
  CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
  BUSINESS_CARDS = 'BUSINESS_CARDS',
  MERCHANT_ACCOUNT = 'MERCHANT_ACCOUNT',
  SEGREGATED_ACCOUNT = 'SEGREGATED_ACCOUNT',
}

export enum SourceOfIncome {
  DIVIDENDS = 'DIVIDENDS',
  INTEREST = 'INTEREST',
  DONATIONS = 'DONATIONS',
  OTHER = 'OTHER',
  BUSINESS_PROCEEDS = 'BUSINESS_PROCEEDS',
}

export enum PurposeOfAccount {
  INTRA_GROUP_TRANSACTIONS_CLIENTS = 'INTRA_GROUP_TRANSACTIONS_CLIENTS',
  RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS = 'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS',
  EMPLOYEE_REMUNERATION_SALARY = 'EMPLOYEE_REMUNERATION_SALARY',
  PAYMENTS_TO_SERVICE_PROVIDER = 'PAYMENTS_TO_SERVICE_PROVIDER',
  RECEIVING_FUNDS_FROM_YOUR_CLIENTS = 'RECEIVING_FUNDS_FROM_YOUR_CLIENTS',
  OTHER = 'OTHER',
  RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS = 'RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS',
}

export enum MonthlyGrossTurnover {
  LESS_THAN_10_000_EUR = 'LESS_THAN_10000',
  '10_000_TO_50_000_EUR' = '10000_TO_50000',
  '50_000_TO_100_000_EUR' = '50000_TO_100000',
  '100_000_TO_1_000_000_EUR' = '100000_TO_1000000',
  '1_000_000_TO_10_000_000_EUR' = '1000000_TO_10000000',
  MORE_THAN_10_000_000_EUR = 'MORE_THAN_10000000',
}

export enum AnnualGrossTurnover {
  LESS_THAN_100_000_EUR = 'LESS_THAN_100000',
  '100_000_TO_500_000_EUR' = '100000_TO_500000',
  '500_000_TO_1_000_000_EUR' = '500000_TO_1000000',
  '1_000_000_TO_10_000_000_EUR' = '1000000_TO_10000000',
  '10_000_000_TO_50_000_000_EUR' = '10000000_TO_50000000',
  MORE_THAN_50_000_000_EUR = 'MORE_THAN_50000000',
}

export enum MonthlyTransactionVolume {
  LESS_THAN_100_TRANSACTIONS = 'LESS_THAN_100',
  '100_TO_1_000_TRANSACTIONS' = '100_TO_1000',
  '1_000_TO_5_000_TRANSACTIONS' = '1000_TO_5000',
  '5_000_TO_10_000_TRANSACTIONS' = '5000_TO_10000',
  '10_000_TO_50_000_TRANSACTIONS' = '10000_TO_50000',
  MORE_THAN_50_000_TRANSACTIONS = 'MORE_THAN_50000',
}

export enum UboSourceOfWealth {
  EMPLOYMENT_INCOME = 'EMPLOYMENT_INCOME',
  BUSINESS_PROFITS_OR_DIVIDENDS = 'BUSINESS_PROFITS_OR_DIVIDENDS',
  SALE_OF_INVESTMENTS_OR_LIQUIDATION_OF_INVESTMENT_PORTFOLIO = 'SALE_OF_INVESTMENTS_OR_LIQUIDATION_OF_INVESTMENT_PORTFOLIO',
  SALE_OF_PROPERTY = 'SALE_OF_PROPERTY',
  LOAN_AND_FINANCING = 'LOAN_AND_FINANCING',
  INHERITANCE = 'INHERITANCE',
  GIFT = 'GIFT',
  OTHER = 'OTHER',
}

export enum OwnershipType {
  OTHER = 'OTHER',
  PROTECTOR = 'PROTECTOR',
  FIDUCIARY = 'FIDUCIARY',
  TRUSTEE = 'TRUSTEE',
  SETTLOR = 'SETTLOR',
  BENEFICIARY = 'BENEFICIARY',
  INDIVIDUAL_WHO_OTHERWISE_EXERCISES_CONTROL = 'INDIVIDUAL_WHO_OTHERWISE_EXERCISES_CONTROL',
  DIRECT_OR_INDIRECT_SHAREHOLDER = 'DIRECT_OR_INDIRECT_SHAREHOLDER',
}

export enum IdentityDocumentType {
  PASSPORT = 'PASSPORT',
  ID = 'ID',
  RESIDENT_PERMIT = 'RESIDENT_PERMIT',
}

export enum ClientRole {
  MAR = 'MAR',
  UBO = 'UBO',
  DIRECTOR = 'DIRECTOR',
}

export enum CompanyType {
  ASSOCIATION = 'ASSOCIATION',
  CHARITABLE_ORGANIZATION = 'CHARITABLE_ORGANIZATION',
  FOUNDATION = 'FOUNDATION',
  GOVERNMENT_OWNED_COMPANY = 'GOVERNMENT_OWNED_COMPANY',
  HOLDING_COMPANY = 'HOLDING_COMPANY',
  NON_PROFIT_ORGANIZATION = 'NON_PROFIT_ORGANIZATION',
  ONE_PERSON_COMPANY_OR_SOLE_PROPRIETORSHIP = 'ONE_PERSON_COMPANY_OR_SOLE_PROPRIETORSHIP',
  PARTNERSHIP = 'PARTNERSHIP',
  PRIVATELY_OWNED_COMPANY = 'PRIVATELY_OWNED_COMPANY',
  PUBLICLY_TRADED_COMPANY = 'PUBLICLY_TRADED_COMPANY',
  TRADING_COMPANY = 'TRADING_COMPANY',
  TRUST = 'TRUST',
  OTHER = 'OTHER',
}

export enum NumberOfEmployees {
  ONE = '1',
  TWO_TO_FIVE = '2_TO_5',
  SIX_TO_TEN = '6_TO_10',
  ELEVEN_TO_FIFTY = '11_TO_50',
  FIFTY_ONE_TO_ONE_HUNDRED = '51_TO_100',
  MORE_THAN_ONE_HUNDRED = 'MORE_THAN_100',
}

export enum CrsFatcaType {
  ACTIVE_FINANCIAL_ENTITY = 'ACTIVE_FINANCIAL_ENTITY',
  PASSIVE_FINANCIAL_ENTITY = 'PASSIVE_FINANCIAL_ENTITY',
  FINANCIAL_AND_FOREIGN_ENTITY = 'FINANCIAL_AND_FOREIGN_ENTITY',
}

export enum BusinessActivityIndustry {
  AGRICULTURE_FORESTRY_AND_FISHING = 'AGRICULTURE_FORESTRY_AND_FISHING',
  MINING_AND_QUARRYING = 'MINING_AND_QUARRYING',
  MANUFACTURING = 'MANUFACTURING',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY = 'ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES = 'WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  WHOLESALE_AND_RETAIL_TRADE = 'WHOLESALE_AND_RETAIL_TRADE',
  TRANSPORTATION_AND_STORAGE = 'TRANSPORTATION_AND_STORAGE',
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES = 'ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES',
  INFORMATION_AND_COMMUNICATION = 'INFORMATION_AND_COMMUNICATION',
  FINANCIAL_AND_INSURANCE_ACTIVITIES = 'FINANCIAL_AND_INSURANCE_ACTIVITIES',
  REAL_ESTATE_ACTIVITIES = 'REAL_ESTATE_ACTIVITIES',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES = 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
  ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES = 'ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY = 'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY',
  EDUCATION = 'EDUCATION',
  HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES = 'HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES',
  ARTS_ENTERTAINMENT_AND_RECREATION = 'ARTS_ENTERTAINMENT_AND_RECREATION',
  GAMBLING_AND_BETTING_ACTIVITIES = 'GAMBLING_AND_BETTING_ACTIVITIES',
  OTHER_SERVICE_ACTIVITIES = 'OTHER_SERVICE_ACTIVITIES',
}

export enum BusinessActivitySubIndustry {
  CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES = 'CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES',
  FORESTRY_AND_LOGGING = 'FORESTRY_AND_LOGGING',
  FISHING_AND_AQUACULTURE = 'FISHING_AND_AQUACULTURE',
  MINING_OF_COAL_AND_LIGNITE = 'MINING_OF_COAL_AND_LIGNITE',
  EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS = 'EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS',
  MINING_OF_METAL_ORES = 'MINING_OF_METAL_ORES',
  OTHER_MINING_AND_QUARRYING = 'OTHER_MINING_AND_QUARRYING',
  MINING_SUPPORT_SERVICE_ACTIVITIES = 'MINING_SUPPORT_SERVICE_ACTIVITIES',
  MANUFACTURE_OF_FOOD_PRODUCTS = 'MANUFACTURE_OF_FOOD_PRODUCTS',
  MANUFACTURE_OF_BEVERAGES = 'MANUFACTURE_OF_BEVERAGES',
  MANUFACTURE_OF_TOBACCO_PRODUCTS = 'MANUFACTURE_OF_TOBACCO_PRODUCTS',
  MANUFACTURE_OF_TEXTILES = 'MANUFACTURE_OF_TEXTILES',
  MANUFACTURE_OF_WEARING_APPAREL = 'MANUFACTURE_OF_WEARING_APPAREL',
  MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS = 'MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS',
  MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS = 'MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS',
  MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS = 'MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS',
  PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA = 'PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA',
  MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS = 'MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS',
  MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS = 'MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS',
  MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS = 'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS',
  MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS = 'MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS',
  MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS = 'MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS',
  MANUFACTURE_OF_BASIC_METALS = 'MANUFACTURE_OF_BASIC_METALS',
  FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT = 'FABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT',
  MANUFACTURE_OF_WEAPONS_AND_AMMUNITION = 'MANUFACTURE_OF_WEAPONS_AND_AMMUNITION',
  MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS = 'MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS',
  MANUFACTURE_OF_ELECTRICAL_EQUIPMENT = 'MANUFACTURE_OF_ELECTRICAL_EQUIPMENT',
  MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_NEC = 'MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_NEC',
  MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS = 'MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS',
  MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT = 'MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT',
  MANUFACTURE_OF_FURNITURE = 'MANUFACTURE_OF_FURNITURE',
  OTHER_MANUFACTURING = 'OTHER_MANUFACTURING',
  REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT = 'REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY = 'ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES = 'WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES = 'WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
  WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS = 'WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS',
  WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO = 'WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO',
  WHOLESALE_OF_HOUSEHOLD_GOODS = 'WHOLESALE_OF_HOUSEHOLD_GOODS',
  WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT = 'WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT',
  WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES = 'WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES',
  OTHER_SPECIALISED_WHOLESALE = 'OTHER_SPECIALISED_WHOLESALE',
  NON_SPECIALISED_WHOLESALE_TRADE = 'NON_SPECIALISED_WHOLESALE_TRADE',
  RETAIL_SALE_IN_NON_SPECIALISED_STORES = 'RETAIL_SALE_IN_NON_SPECIALISED_STORES',
  RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES',
  DISPENSING_CHEMIST_IN_SPECIALISED_STORES = 'DISPENSING_CHEMIST_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES = 'RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES',
  OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES = 'OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES',
  RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES = 'RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES',
  RETAIL_TRADE_IN_MARKETPLACES = 'RETAIL_TRADE_IN_MARKETPLACES',
  LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES = 'LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES',
  WATER_TRANSPORT = 'WATER_TRANSPORT',
  AIR_TRANSPORT = 'AIR_TRANSPORT',
  WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION = 'WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION',
  POSTAL_AND_COURIER_ACTIVITIES = 'POSTAL_AND_COURIER_ACTIVITIES',
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES = 'ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES',
  PUBLISHING_ACTIVITIES = 'PUBLISHING_ACTIVITIES',
  MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES = 'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
  PROGRAMMING_AND_BROADCASTING_ACTIVITIES = 'PROGRAMMING_AND_BROADCASTING_ACTIVITIES',
  TELECOMMUNICATIONS = 'TELECOMMUNICATIONS',
  COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES = 'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES',
  INFORMATION_SERVICE_ACTIVITIES = 'INFORMATION_SERVICE_ACTIVITIES',
  BANKS_OR_CREDIT_UNIONS = 'BANKS_OR_CREDIT_UNIONS',
  ACTIVITIES_OF_HOLDING_COMPANIES = 'ACTIVITIES_OF_HOLDING_COMPANIES',
  CONSUMER_CREDIT_PAYDAY_LOANS_PAWNBROKERS_PREPAID_AND_OR_GIFT_CARDS = 'CONSUMER_CREDIT_PAYDAY_LOANS_PAWNBROKERS_PREPAID_AND_OR_GIFT_CARDS',
  CROWDFUNDING = 'CROWDFUNDING',
  PHYSICAL_CURRENCY_EXCHANGE = 'PHYSICAL_CURRENCY_EXCHANGE',
  PAYMENT_SERVICE_PROVIDERS_ELECTRONIC_MONEY_INSTITUTIONS = 'PAYMENT_SERVICE_PROVIDERS_ELECTRONIC_MONEY_INSTITUTIONS',
  PRIVATE_BANKING = 'PRIVATE_BANKING',
  VIRTUAL_CURRENCY = 'VIRTUAL_CURRENCY',
  INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY = 'INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY',
  TRADING_FX_AND_INVESTMENT = 'TRADING_FX_AND_INVESTMENT',
  REAL_ESTATE_ACTIVITIES = 'REAL_ESTATE_ACTIVITIES',
  LEGAL_AND_ACCOUNTING_ACTIVITIES = 'LEGAL_AND_ACCOUNTING_ACTIVITIES',
  ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES = 'ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES',
  ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS = 'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS',
  SCIENTIFIC_RESEARCH_AND_DEVELOPMENT = 'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT',
  ADVERTISING_AND_MARKET_RESEARCH = 'ADVERTISING_AND_MARKET_RESEARCH',
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES = 'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
  VETERINARY_AND_PET_CARE_SERVICES = 'VETERINARY_AND_PET_CARE_SERVICES',
  RENTAL_AND_LEASING_ACTIVITIES = 'RENTAL_AND_LEASING_ACTIVITIES',
  EMPLOYMENT_ACTIVITIES = 'EMPLOYMENT_ACTIVITIES',
  TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES = 'TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
  SECURITY_AND_INVESTIGATION_ACTIVITIES = 'SECURITY_AND_INVESTIGATION_ACTIVITIES',
  SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES = 'SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES',
  OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES = 'OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY = 'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY',
  EDUCATION = 'EDUCATION',
  HUMAN_HEALTH_ACTIVITIES = 'HUMAN_HEALTH_ACTIVITIES',
  RESIDENTIAL_CARE_AND_SOCIAL_WORK_ACTIVITIES = 'RESIDENTIAL_CARE_AND_SOCIAL_WORK_ACTIVITIES',
  CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES = 'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES',
  LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES = 'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES',
  SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES = 'SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES',
  LOTTERY = 'LOTTERY',
  ONLINE_GAMING_OR_GAMBLING = 'ONLINE_GAMING_OR_GAMBLING',
  PAYING_AGENT = 'PAYING_AGENT',
  PHYSICAL_BETTING_OR_BOOKMAKER = 'PHYSICAL_BETTING_OR_BOOKMAKER',
  PHYSICAL_CASINOS = 'PHYSICAL_CASINOS',
  PHYSICAL_SLOT_MACHINES = 'PHYSICAL_SLOT_MACHINES',
  ACTIVITIES_OF_MEMBERSHIP_ORGANIZATIONS = 'ACTIVITIES_OF_MEMBERSHIP_ORGANIZATIONS',
  REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS = 'REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS',
  WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS = 'WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS',
  HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT = 'HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT',
  FUNERAL_AND_RELATED_ACTIVITIES = 'FUNERAL_AND_RELATED_ACTIVITIES',
  PHYSICAL_WELL_BEING_ACTIVITIES = 'PHYSICAL_WELL_BEING_ACTIVITIES',
  OTHER_PERSONAL_SERVICE_ACTIVITIES_NEC = 'OTHER_PERSONAL_SERVICE_ACTIVITIES_NEC',
}

export interface OnboardingAddress {
  addressLine1: string
  addressLine2?: string
  postcode: string
  city: string
  countryCode: string
}

export interface ListedSubsidiary {
  legalName: string
  isinCode: string
}

export interface LicensedSubsidiary {
  legalName: string
  licensePublicSource: string
}

export type OnboardingCountry = {
  countryCode: string
  tin: string
}

export interface TaxIdentification {
  tinCountryOfResidency: string
  additionalCountries: OnboardingCountry[]
}

export interface IdentityDocument {
  fileId: string
  number: string
  issueDate: string
  expiryDate: string
  issuingCountryCode: string
  type: IdentityDocumentType
}

export interface Ownership {
  type: string
  typeOther: string
  shareSize: number
  hasVotingControl: boolean
  hasVotingControlAdditionalInformation: string
}

export interface UploadedDocument {
  fileId: string
}

export interface UploadedDocumentWithDescription {
  fileId: string
  description: string
}

export interface CompanyGroup {
  stockExchange: {
    listedSubsidiaries: ListedSubsidiary[]
  }
  governmentalLicense: {
    licensedSubsidiaries: LicensedSubsidiary[]
  }
}

export interface Company {
  externalReference: string
  name: string
  dateOfIncorporation: string
  registrationNumber: string
  countryOfIncorporation: string
  businessActivityDescription: string
  businessActivityIndustry: BusinessActivityIndustry
  businessActivitySubIndustry: BusinessActivitySubIndustry
  registeredOfficeAddress: OnboardingAddress
  headquartersOfficeAddress: OnboardingAddress
  email: string
  phoneNumber: {
    countryCode: string
    number: string
  }
  website: string
  companyType: CompanyType
  companyTypeOther: string
  isSubsidiary: boolean
  group: CompanyGroup
  governmentalLicense: {
    isLicensed: boolean
    licensePublicSource: string
    fileId: string
  }
  stockExchange: {
    isListed: boolean
    isinCode: string
  }
  shareholders: {
    isOwnedByNominee: boolean
    nominee: {
      fullName: string
      regulatingCountryCode: string
    }
  }
  numberOfEmployees: NumberOfEmployees
  crsFatcaType: CrsFatcaType
  taxIdentification: {
    tinCountryOfIncorporation: string
    additionalCountries: OnboardingCountry[]
  }
  memorandumOfAssociation: UploadedDocument[]
  articleOfAssociation: UploadedDocument[]
  beneficialOwnershipDocument: UploadedDocument[]
  extractFromCompanyRegistry: UploadedDocument[]
  uboStructureChart: UploadedDocument[]
  memorandumArticleOfAssociation: UploadedDocument[]
  amlFraudProcedures: UploadedDocument[]
  certificateIncorporation: UploadedDocument[]
  certificateIncumbency: UploadedDocument[]
  purposeOfAccount: UploadedDocument[]
  businessActivityDocuments: UploadedDocument[]
  moneyFlowChart: UploadedDocument[]
}

export interface OnboardingAccount {
  purposesOfAccount: PurposeOfAccount[]
  purposesOfAccountOther: string
  purposesOfAccountAdditionalInformation: {
    serviceProviders: {
      name: string
      locationCountryCode: string
    }[]
    clients: {
      name: string
      locationCountryCode: string
    }[]
    receiveFundsOnBehalfOfOthers: string
  }
  receiveFunds: {
    countries: string[]
  }
  sendFunds: {
    countries: string[]
  }
  sourceOfIncome: SourceOfIncome[]
  sourceOfIncomeOther: string
  monthlyGrossTurnover: MonthlyGrossTurnover
  annualGrossTurnover: AnnualGrossTurnover
  monthlyTransactionVolume: MonthlyTransactionVolume
  limits: {
    daily: number
    monthly: number
  }
  requestedProducts: RequestedProduct[]
}

export interface Pep {
  isPep: boolean
  position: string
}

export interface Person {
  personId?: string
  externalReference: string
  firstName: string
  lastName: string
  dateOfBirth: string
  personalCode?: string
  citizenshipCountryCode?: string // country code?
  roles: ClientRole[]
  identityDocument?: IdentityDocument
  pep: Pep
  address: OnboardingAddress
  email: string
  taxIdentification: TaxIdentification
  ownership: Ownership
  uboSourceOfWealth: string
  isDirectorExecutive: boolean
  isMultipleTaxResidency?: boolean
}

export interface OtherInformation {
  currenciesForPayment?: Currency[]
  isPartOfGroup?: boolean
  isGovGroup?: boolean
  isStockGroup?: boolean
  fatcaStatus?: {
    incorporatedInUS: string
    taxResidentInUS: string
    UBOisUSTaxResidentOrUSCitizen: string
    UBOwithVetoIsUSTaxResidentOrUSCitizen: string
  }
  agreedToDataProtectionDisclaimer?: boolean
}

export interface OnboardingRequest {
  company: Company
  account: OnboardingAccount
  persons: Person[]
  additionalDocuments?: UploadedDocumentWithDescription[]
  other?: OtherInformation
}

// Example objects
const company: Company = {
  externalReference: 'de051e14-1bbc-4fd2-9eb2-a9643cef5a60',
  name: 'kokkuinc',
  dateOfIncorporation: '1993-02-03',
  registrationNumber: 'GA6816151716',
  countryOfIncorporation: 'LT',
  businessActivityDescription: 'Business Transportation Infrastructure Transportation Infrastructure',
  businessActivityIndustry: BusinessActivityIndustry.MINING_AND_QUARRYING,
  businessActivitySubIndustry: BusinessActivitySubIndustry.MINING_SUPPORT_SERVICE_ACTIVITIES,
  registeredOfficeAddress: {
    addressLine1: 'Office Address 1',
    addressLine2: 'Office Address 2',
    city: 'Maybe',
    postcode: '00110',
    countryCode: 'LT',
  },
  headquartersOfficeAddress: {
    addressLine1: 'HQ Address 1',
    addressLine2: 'HQ Address 2',
    city: 'Houston',
    postcode: '00110',
    countryCode: 'US',
  },
  email: 'user@company.com',
  phoneNumber: {
    countryCode: '+91',
    number: '99999999',
  },
  website: 'https://www.company.com',
  companyType: CompanyType.PRIVATELY_OWNED_COMPANY,
  companyTypeOther: 'Water supply; sewerage, waste management and remediation activities',
  isSubsidiary: true,
  group: {
    stockExchange: {
      listedSubsidiaries: [],
    },
    governmentalLicense: {
      licensedSubsidiaries: [
        {
          legalName: 'Sony',
          licensePublicSource: 'government',
        },
      ],
    },
  },
  governmentalLicense: {
    isLicensed: false,
    licensePublicSource: '',
    fileId: '',
  },
  stockExchange: {
    isListed: true,
    isinCode: 'A90NAG',
  },
  shareholders: {
    isOwnedByNominee: true,
    nominee: {
      fullName: 'John Peter',
      regulatingCountryCode: 'LT',
    },
  },
  numberOfEmployees: NumberOfEmployees.TWO_TO_FIVE,
  crsFatcaType: CrsFatcaType.FINANCIAL_AND_FOREIGN_ENTITY,
  taxIdentification: {
    tinCountryOfIncorporation: 'T897676768R',
    additionalCountries: [
      {
        countryCode: 'SE',
        tin: 'T8976AYA768R',
      },
    ],
  },
  memorandumOfAssociation: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  articleOfAssociation: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  beneficialOwnershipDocument: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  extractFromCompanyRegistry: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  uboStructureChart: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  memorandumArticleOfAssociation: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  amlFraudProcedures: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  certificateIncorporation: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  certificateIncumbency: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  purposeOfAccount: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  businessActivityDocuments: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
  moneyFlowChart: [
    {
      fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    },
  ],
}

const person: Person = {
  personId: '4946b0ed-1460-45f5-9ff5-59f93471bf9f',
  externalReference: 'af7c1fe6-d669-414e-b066-e9733f0de7a8',
  firstName: 'Vlad',
  lastName: 'Pollan',
  email: 'vlad@examples.com',
  dateOfBirth: '1978-02-02',
  personalCode: '76675657',
  citizenshipCountryCode: 'LT',
  roles: [ClientRole.MAR, ClientRole.UBO],
  identityDocument: {
    fileId: '69bdb405-3e00-430a-8b77-3e6773ca40bf',
    number: '766756',
    issueDate: '1978-02-02',
    expiryDate: '1989-02-02',
    issuingCountryCode: 'LT',
    type: IdentityDocumentType.PASSPORT,
  },
  pep: {
    isPep: true,
    position: 'Director',
  },
  address: {
    addressLine1: 'address line 1',
    addressLine2: 'address line 2',
    postcode: '588675',
    city: 'Ghaziabad',
    countryCode: 'LT',
  },
  taxIdentification: {
    tinCountryOfResidency: 'T897676768R',
    additionalCountries: [
      {
        countryCode: 'SE',
        tin: 'T8976AYA768R',
      },
    ],
  },
  ownership: {
    type: 'PROTECTOR',
    typeOther: 'type other',
    shareSize: 67,
    hasVotingControl: true,
    hasVotingControlAdditionalInformation: 'Venu Has Controlling Stake',
  },
  uboSourceOfWealth: UboSourceOfWealth.EMPLOYMENT_INCOME,
  isDirectorExecutive: true,
}

const account: OnboardingAccount = {
  purposesOfAccount: [
    PurposeOfAccount.RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS,
    PurposeOfAccount.RECEIVING_FUNDS_FROM_YOUR_CLIENTS,
    PurposeOfAccount.PAYMENTS_TO_SERVICE_PROVIDER,
    PurposeOfAccount.RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS,
  ],
  purposesOfAccountOther: '',
  purposesOfAccountAdditionalInformation: {
    serviceProviders: [
      {
        name: 'InTomart',
        locationCountryCode: 'LT',
      },
    ],
    clients: [
      {
        name: 'InTomart',
        locationCountryCode: 'LT',
      },
    ],
    receiveFundsOnBehalfOfOthers: 'Tesla',
  },
  receiveFunds: {
    countries: ['IN', 'US', 'GB'],
  },
  sendFunds: {
    countries: ['IN', 'US', 'GB'],
  },
  sourceOfIncome: [SourceOfIncome.BUSINESS_PROCEEDS, SourceOfIncome.INTEREST],
  sourceOfIncomeOther: 'Revenue from business',
  monthlyGrossTurnover: MonthlyGrossTurnover.LESS_THAN_10_000_EUR,
  annualGrossTurnover: AnnualGrossTurnover.LESS_THAN_100_000_EUR,
  monthlyTransactionVolume: MonthlyTransactionVolume.LESS_THAN_100_TRANSACTIONS,
  limits: {
    daily: 1000,
    monthly: 10000,
  },
  requestedProducts: [
    RequestedProduct.SEPA_PAYMENTS,
    RequestedProduct.INTERNATIONAL_PAYMENTS,
    RequestedProduct.CURRENCY_EXCHANGE,
    RequestedProduct.BUSINESS_CARDS,
    RequestedProduct.MERCHANT_ACCOUNT,
  ],
}

export const exampleOnboardingData: OnboardingRequest = {
  company: company,
  persons: [person],
  account: account,
  other: {
    currenciesForPayment: [Currency.EUR, Currency.USD],
    fatcaStatus: {
      incorporatedInUS: 'FALSE',
      taxResidentInUS: 'FALSE',
      UBOisUSTaxResidentOrUSCitizen: 'FALSE',
      UBOwithVetoIsUSTaxResidentOrUSCitizen: 'FALSE',
    },
    agreedToDataProtectionDisclaimer: false,
    isPartOfGroup: true,
    isStockGroup: false,
    isGovGroup: true,
  },
}

export enum OnboardingSteps {
  STEP_01_BASIC_DETAILS = 'STEP_01_BASIC_DETAILS',
  STEP_02_CONTACT_DETAILS = 'STEP_02_CONTACT_DETAILS',
  STEP_03_ADVANCED_DETAILS = 'STEP_03_ADVANCED_DETAILS',
  STEP_04_REPRESENTATIVES = 'STEP_04_REPRESENTATIVES',
  STEP_05_FINANCIAL_DETAILS = 'STEP_05_FINANCIAL_DETAILS',
  STEP_06_TAX_REGULATIONS = 'STEP_06_TAX_REGULATIONS',
  STEP_07_BUSINESS_ACTIVITY = 'STEP_07_BUSINESS_ACTIVITY',
  STEP_08_DATA_PROTECTION = 'STEP_08_DATA_PROTECTION',
  STEP_09_DOCUMENTS = 'STEP_09_DOCUMENTS',
  STEP_10_ID_VERIFICATION = 'STEP_10_ID_VERIFICATION',
  STEP_11_REVIEW_APPLICATION = 'STEP_11_REVIEW_APPLICATION',
}

export type OnboardingStepStatus = {
  step: OnboardingSteps
  status: 'untouched' | 'in_progress' | 'completed' | 'not_applicable'
  last_modified_by?: string
  last_modified_date?: string
}

export type OnboardingStepStatusMap = {
  [key in OnboardingSteps]: OnboardingStepStatus
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export interface OnboardingSession {
  version: number
  last_update: string
  onboarding_request: DeepPartial<OnboardingRequest>
  steps_status_map: OnboardingStepStatusMap
}

export enum OnboardingSessionReducerActionType {
  UPDATE_ONBOARDING_REQUEST = 'UPDATE_ONBOARDING_REQUEST',
}

export interface Step01BasicDetailsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_01_BASIC_DETAILS
  status: 'in_progress' | 'completed'
  account: Pick<
    OnboardingAccount,
    'purposesOfAccount' | 'purposesOfAccountAdditionalInformation' | 'purposesOfAccountOther'
  >
  company: Pick<Company, 'name' | 'dateOfIncorporation' | 'registrationNumber'>
}

export interface Step02ContactDetailsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_02_CONTACT_DETAILS
  status: 'in_progress' | 'completed'
  company: Pick<Company, 'registeredOfficeAddress' | 'headquartersOfficeAddress' | 'phoneNumber' | 'email' | 'website'>
}

export interface Step03AdvancedDetailsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_03_ADVANCED_DETAILS
  status: 'in_progress' | 'completed'
  account: Pick<OnboardingAccount, 'receiveFunds' | 'sendFunds'>
  company: Pick<Company, 'companyType' | 'group' | 'governmentalLicense' | 'stockExchange' | 'shareholders'>
  other: Pick<OtherInformation, 'isPartOfGroup' | 'isGovGroup' | 'isStockGroup'>
}

export interface Step04RepresentativeDetailsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_04_REPRESENTATIVES
  status: 'in_progress' | 'completed'
  persons: Person[]
}

export interface Step05FinancialDetailsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_05_FINANCIAL_DETAILS
  status: 'in_progress' | 'completed'
  account: Pick<
    OnboardingAccount,
    | 'annualGrossTurnover'
    | 'monthlyGrossTurnover'
    | 'monthlyTransactionVolume'
    | 'limits'
    | 'sourceOfIncome'
    | 'requestedProducts'
    | 'sourceOfIncomeOther'
  >
  company: Pick<Company, 'numberOfEmployees'>
  other: Pick<OtherInformation, 'currenciesForPayment'>
}

export interface Step06TaxRegulationsRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_06_TAX_REGULATIONS
  status: 'in_progress' | 'completed'
  company: Pick<Company, 'taxIdentification' | 'crsFatcaType'>
  other: Pick<OtherInformation, 'fatcaStatus'>
}

export interface Step07BusinessActivityRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_07_BUSINESS_ACTIVITY
  status: 'in_progress' | 'completed'
  company: Pick<Company, 'businessActivityDescription' | 'businessActivityIndustry' | 'businessActivitySubIndustry'>
}

export interface Step08DataProtectionRequest extends OnboardingStepStatus {
  step: OnboardingSteps.STEP_08_DATA_PROTECTION
  status: 'in_progress' | 'completed'
  other: Pick<OtherInformation, 'agreedToDataProtectionDisclaimer'>
}

export type UpdateOnboardingRequestPayloads =
  | Step01BasicDetailsRequest
  | Step02ContactDetailsRequest
  | Step03AdvancedDetailsRequest
  | Step04RepresentativeDetailsRequest
  | Step05FinancialDetailsRequest
  | Step06TaxRegulationsRequest
  | Step07BusinessActivityRequest
  | Step08DataProtectionRequest

export type UpdateOnboardingRequest = {
  type: OnboardingSessionReducerActionType.UPDATE_ONBOARDING_REQUEST
  payload: {
    step: OnboardingSteps
    status: 'in_progress' | 'completed'
    company?: DeepPartial<Company>
    account?: DeepPartial<OnboardingAccount>
    persons?: DeepPartial<Person>[]
    other?: OtherInformation
  }
}
export type OnboardingSessionReducerAction = UpdateOnboardingRequest
