import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Stack, Text } from '@mantine/core'
import ReviewBase from './ReviewBase'
import { ContactDetailsValidation } from '../step2/ContactDetails'

const ReviewContactDetails = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 2 | Contact Details' step={2} validationFields={ContactDetailsValidation}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Registered office address
        </Text>
        {form.getValues().company.registeredOfficeAddress.addressLine1.length ? (
          <Text fz='md' fw='500' c='textPrimary'>
            <p>{form.getValues().company.registeredOfficeAddress.addressLine1}</p>
            <p>{form.getValues().company.registeredOfficeAddress.addressLine2}</p>
            <p>{form.getValues().company.registeredOfficeAddress.postcode}</p>
            <p>{form.getValues().company.registeredOfficeAddress.countryCode}</p>
          </Text>
        ) : (
          '-'
        )}

        {form.getValues().company.headquartersOfficeAddress.addressLine1.length > 0 && (
          <>
            <Text fz='sm' my='3xs' c='secondary'>
              Headquarters/main office address
            </Text>
            <Text fz='md' fw='500' c='textPrimary'>
              <p>{form.getValues().company.headquartersOfficeAddress.addressLine1}</p>
              <p>{form.getValues().company.headquartersOfficeAddress.addressLine2}</p>
              <p>{form.getValues().company.headquartersOfficeAddress.postcode}</p>
              <p>{form.getValues().company.headquartersOfficeAddress.countryCode}</p>
            </Text>
          </>
        )}

        <Text fz='sm' my='3xs' c='secondary'>
          Company phone number
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().company.phoneNumber.countryCode.length && form.getValues().company.phoneNumber.number
            ? `(${form.getValues().company.phoneNumber.countryCode})-${form.getValues().company.phoneNumber.number}`
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Company email address
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().company.email.length ? form.getValues().company.email : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Company website
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().company.website.length ? form.getValues().company.website : '-'}
        </Text>
      </Stack>
    </ReviewBase>
  )
}

export default ReviewContactDetails
