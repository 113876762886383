import { IconArrowRight, IconCross, IconEdit, IconPlus } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { ActionIcon, Anchor, Box, Breadcrumbs, Button, Flex, Group, Paper, Stack, Table, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FormEvent } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AddRepresentativeModal, { RepresentativeRoleMapper } from './AddRepresentativeModal'
import OnboardingStatus from '../status/OnboardingStatus'

export const RepresentativeDetailsValidation = ['persons']

const RepresentativesDetails = () => {
  const { invitationCode } = useParams()
  const navigate = useNavigate()
  const [opened, { open, close }] = useDisclosure(false)
  const { form, validateFields, saveForm } = useOnboardingForm()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(RepresentativeDetailsValidation) && submitterName === 'saveAndContinue') {
      navigate(`/onboarding/${invitationCode}/step/5`)
    }
  }

  const removeRepresentative = (atIndex: number) => {
    form.removeListItem('persons', atIndex)
    saveForm()
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 4 | Representative Details</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <Group bg='light.6' p='md'>
              <Text fz='lg' c='secondary'>
                Representatives
              </Text>
              <div style={{ flexGrow: 1 }} />
              <ActionIcon bg='primary' onClick={open}>
                <IconPlus />
              </ActionIcon>
            </Group>
            {form.errors['persons'] && (
              <Text c='red' size='sm'>
                {form.errors['persons']}
              </Text>
            )}
            <Table striped highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>First Name</Table.Th>
                  <Table.Th>Last Name</Table.Th>
                  <Table.Th>Roles</Table.Th>
                  <Table.Th>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {form.getValues().persons.length === 0 && (
                  <Table.Tr>
                    <Table.Td colSpan={4} align='center'>
                      <Anchor c='red' onClick={open}>
                        Click here to add a representative
                      </Anchor>
                    </Table.Td>
                  </Table.Tr>
                )}
                {form.getValues().persons.map((p, i) => {
                  return (
                    <Table.Tr key={p.firstName + p.lastName}>
                      <Table.Td>{p.firstName ?? '-'}</Table.Td>
                      <Table.Td>{p.lastName ?? '-'}</Table.Td>
                      <Table.Td>
                        {p.roles.map((r) => (
                          <Box key={r} bg='gray' p='xs' style={{ borderRadius: '1rem', display: 'inline-block' }}>
                            {RepresentativeRoleMapper[r]}
                          </Box>
                        ))}
                      </Table.Td>
                      <Table.Td w='6rem'>
                        <Box>
                          <Group>
                            <ActionIcon bg='grape'>
                              <IconEdit />
                            </ActionIcon>
                            <ActionIcon bg='red' onClick={() => removeRepresentative(i)}>
                              <IconCross />
                            </ActionIcon>
                          </Group>
                        </Box>
                      </Table.Td>
                    </Table.Tr>
                  )
                })}
              </Table.Tbody>
            </Table>
          </Stack>

          <Group justify='space-between' mt='lg'>
            <Button color='dark' onClick={() => navigate(`/onboarding/${invitationCode}/step/3`)}>
              Previous
            </Button>
            <Group>
              <Button name='save' type='submit'>
                Save
              </Button>
              <Button name='saveAndContinue' type='submit'>
                Save and continue
              </Button>
            </Group>
          </Group>
        </form>
        <AddRepresentativeModal opened={opened} close={close} />
      </Paper>
    </Flex>
  )
}

export default RepresentativesDetails
