import { IconTickCircle } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Box, Grid, GridCol, Group, Stack, Text } from '@mantine/core'
import { DisplayCountryNameWithFlag } from '@/components/common/DisplayCountryNameWithFlag'
import { BasicDetailsValidationFields, PurposeOfAccountMapper } from '../step1/BasicDetails'
import ReviewBase from './ReviewBase'

const ReviewBasicDetails = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 1 | Basic Details' step={1} validationFields={BasicDetailsValidationFields}>
      <Grid>
        <GridCol span={6}>
          <Text fz='sm' my='3xs' c='secondary'>
            Company name
          </Text>
          <Text fz='md' fw='500' c='textPrimary'>
            {form.getValues().company.name.length ? form.getValues().company.name : '-'}
          </Text>
          <Text fz='sm' my='3xs' c='secondary'>
            Company registration number
          </Text>
          <Text fz='md' fw='500' c='textPrimary'>
            {form.getValues().company.registrationNumber.length ? form.getValues().company.registrationNumber : '-'}
          </Text>
        </GridCol>
        <GridCol span={6}>
          <Text fz='sm' my='3xs' c='secondary'>
            Date of registration of incorporation
          </Text>
          <Text fz='md' fw='500' c='textPrimary'>
            {form.getValues().company.dateOfIncorporation.length ? form.getValues().company.dateOfIncorporation : '-'}
          </Text>
          <Text fz='sm' my='3xs' c='secondary'>
            Country of incorporation
          </Text>
          {form.getValues().company.countryOfIncorporation ? (
            <DisplayCountryNameWithFlag countryCode={form.getValues().company.countryOfIncorporation!} />
          ) : (
            <Text fz='md' fw='500' c='textPrimary'>
              -
            </Text>
          )}
        </GridCol>
      </Grid>
      <Text fz='sm' my='3xs' c='secondary'>
        Purpose of the account
      </Text>
      {form.getValues().account.purposesOfAccount.length ? (
        <Stack gap='xs'>
          {form.getValues().account.purposesOfAccount.map((p) =>
            p === 'PAYMENTS_TO_SERVICE_PROVIDER' ? (
              <Group key={p} align='start'>
                <Stack pt='0.3rem'>
                  <IconTickCircle style={{ height: '1em' }} />
                </Stack>
                <Stack>
                  <Text>- {PurposeOfAccountMapper[p]}:</Text>
                  <Group pl='md'>
                    {form.getValues().account.purposesOfAccountAdditionalInformation.serviceProviders.map((s) => (
                      <Box key={s.name} bg='gray' p='xs' style={{ borderRadius: '1rem' }}>
                        <Group>
                          <Text fz='md' fw='500' c='textPrimary'>
                            {s.name}
                          </Text>
                          <DisplayCountryNameWithFlag countryCode={s.locationCountryCode!} />
                        </Group>
                      </Box>
                    ))}
                  </Group>
                </Stack>
              </Group>
            ) : p === 'RECEIVING_FUNDS_FROM_YOUR_CLIENTS' ? (
              <Group key={p} align='start'>
                <Stack pt='0.3rem'>
                  <IconTickCircle style={{ height: '1em' }} />
                </Stack>
                <Stack gap='xs'>
                  <Text>- {PurposeOfAccountMapper[p]}:</Text>
                  <Group pl='md'>
                    {form.getValues().account.purposesOfAccountAdditionalInformation.clients.map((c) => (
                      <Box key={c.name} bg='gray' p='xs' style={{ borderRadius: '1rem' }}>
                        <Group>
                          <Text fz='md' fw='500' c='textPrimary'>
                            {c.name}
                          </Text>
                          <DisplayCountryNameWithFlag countryCode={c.locationCountryCode!} />
                        </Group>
                      </Box>
                    ))}
                  </Group>
                </Stack>
              </Group>
            ) : p === 'OTHER' ? (
              <Group key={p} align='start'>
                <Stack pt='0.3rem'>
                  <IconTickCircle style={{ height: '1em' }} />
                </Stack>
                <Stack gap='xs'>
                  <Text>- {PurposeOfAccountMapper[p]}:</Text>
                  <Text pl='md'>{form.getValues().account.purposesOfAccountOther}</Text>
                </Stack>
              </Group>
            ) : (
              <Group key={p}>
                <IconTickCircle style={{ height: '1em' }} />
                <Text>{PurposeOfAccountMapper[p]}</Text>
              </Group>
            )
          )}
        </Stack>
      ) : (
        <Text>-</Text>
      )}
    </ReviewBase>
  )
}

export default ReviewBasicDetails
