import { useEffect } from 'react'
import { FileButton, Button, Group, Text, Stack, Box, Anchor, ActionIcon, Loader } from '@mantine/core'
import { IconCloseCross, IconPlus } from '../../../components/icons'
import { useDeleteOnboardingDocument, useUploadBusinessOnboardingDocument } from '@/api/org/hooks'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { BusinessOnboardingDocumentType } from '@/api/org/types'
import { useParams } from 'react-router-dom'
import { GetFormValue } from '@/utils/form'

interface IProps {
  label: string
  keyPath: string
  businessOnboardingDocumentType: BusinessOnboardingDocumentType
}

function DocumentUploadButton({ label, keyPath, businessOnboardingDocumentType }: IProps) {
  const { invitationCode } = useParams()

  const {
    mutate: uploadDocument,
    isSuccess: isDocumentUploadSuccess,
    isLoading: isDocumentUploadLoading,
  } = useUploadBusinessOnboardingDocument()

  const {
    mutate: deleteDocument,
    isSuccess: isDocumentDeleteSuccess,
    isLoading: isDocumentDeleteLoading,
  } = useDeleteOnboardingDocument()

  const { fetchForm, form } = useOnboardingForm()

  useEffect(() => {
    if (isDocumentDeleteLoading || isDocumentUploadSuccess) {
      fetchForm()
    }
  }, [isDocumentDeleteSuccess, isDocumentUploadSuccess])

  function handleDocumentDelete(key: string) {
    if (!invitationCode) {
      throw new Error('Invitation code is required.')
    }

    deleteDocument({
      invitationCode,
      request: {
        key,
        businessOnboardingDocumentType,
      },
    })
  }

  function handleDocumentUpload(file: File) {
    if (!invitationCode) {
      throw new Error('Invitation code is required.')
    }

    uploadDocument({
      invitationCode,
      onboardingDocument: {
        businessOnboardingDocumentType,
        file,
      },
    })
  }

  const handleFileChange = (file: File | null) => {
    if (file) {
      handleDocumentUpload(file)
    }
  }

  if (isDocumentDeleteLoading || isDocumentUploadLoading) {
    return (
      <Group align='center' justify='center'>
        <Loader />
      </Group>
    )
  }

  const documentData = GetFormValue<{ key: string; fileId: string }[]>(form, keyPath)

  return (
    <Stack gap='xs'>
      <Text fz='sm' c='secondary'>
        {label}
      </Text>
      {form.errors[keyPath] && (
        <Text c='red' size='sm'>
          {form.errors[keyPath]}
        </Text>
      )}
      <Group>
        <Box>
          <FileButton onChange={handleFileChange} accept='*' disabled={documentData?.length > 0}>
            {(props) => (
              <Button {...props} disabled={documentData?.length > 0}>
                <Group justify='space-between'>
                  <IconPlus size='xs' />
                  <Text size='xs'>Upload document</Text>
                </Group>
              </Button>
            )}
          </FileButton>
        </Box>
        {documentData?.map((d: { fileId: string; key: string }) => (
          <Group key={d.key} align='center'>
            <Anchor size='sm' href={d.fileId} target='_blank'>
              {d.key.split('/').pop()}
            </Anchor>
            <ActionIcon bg='red' onClick={() => handleDocumentDelete(d.key)}>
              <IconCloseCross />
            </ActionIcon>
          </Group>
        ))}
      </Group>
    </Stack>
  )
}

export default DocumentUploadButton
