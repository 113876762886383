import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Paper,
  Select,
  getThemeColor,
  Flex,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { IconArrowRight, IconPlusClear } from '@/components/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormEvent } from 'react'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { PurposeOfAccountType } from '@/api/org/types'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { colorAliases } from '@/theme/mantineTheme'
import { checkboxStyles } from '../onboardingUtils'
import dayjs from 'dayjs'
import OnboardingStatus from '../status/OnboardingStatus'

export const PurposeOfAccountMapper: Record<PurposeOfAccountType, string> = {
  PAYMENTS_TO_SERVICE_PROVIDER: 'Payments to service providers',
  RECEIVING_FUNDS_FROM_YOUR_CLIENTS: 'Receiving funds from clients',
  RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS: 'Receiving funds on behalf of company(ies) or person(s)',
  INTRA_GROUP_TRANSACTIONS_CLIENTS: 'Intra-group transactions',
  EMPLOYEE_REMUNERATION_SALARY: 'Employee remuneration / Salary',
  RECEIVING_MERCHANT_PROCESSING_SETTLEMENTS: 'Receiving merchant processing settlements',
  OTHER: 'Other',
}

export const BasicDetailsValidationFields = [
  'company.name',
  'company.countryOfIncorporation',
  'company.registrationNumber',
  'company.dateOfIncorporation',
  'account.purposesOfAccount',
  'account.purposesOfAccountAdditionalInformation.serviceProviders',
  'account.purposesOfAccountAdditionalInformation.clients',
  'account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers',
  'account.purposesOfAccountOther',
]

const BasicDetails = () => {
  const { invitationCode } = useParams()
  const navigate = useNavigate()
  const { form, saveForm, validateFields } = useOnboardingForm()

  const handleChangeInPurposesOfAccount = (newValues: PurposeOfAccountType[]) => {
    form.setFieldValue('account.purposesOfAccount', newValues)

    if (newValues.includes('PAYMENTS_TO_SERVICE_PROVIDER')) {
      if (form.getValues().account?.purposesOfAccountAdditionalInformation?.serviceProviders?.length === 0) {
        form.insertListItem('account.purposesOfAccountAdditionalInformation.serviceProviders', {
          locationCountryCode: null,
          name: '',
        })
      }
    } else {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.serviceProviders', [])
    }

    if (newValues.includes('RECEIVING_FUNDS_FROM_YOUR_CLIENTS')) {
      if (form.getValues().account?.purposesOfAccountAdditionalInformation?.clients?.length === 0) {
        form.insertListItem('account.purposesOfAccountAdditionalInformation.clients', {
          locationCountryCode: null,
          name: '',
        })
      }
    } else {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.clients', [])
    }

    if (
      newValues.includes('RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS') &&
      form.getValues().account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers === ''
    ) {
      form.setFieldValue('account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers', '')
    }

    if (newValues.includes('OTHER') && form.getValues().account.purposesOfAccountOther === '') {
      form.setFieldValue('account.purposesOfAccountOther', '')
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(BasicDetailsValidationFields)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/2`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 1 | Basic Details</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              label='Company name'
              labelProps={{ c: 'secondary' }}
              placeholder='Enter company name'
              {...form.getInputProps('company.name')}
            />

            <DatePickerInput
              label='Registration date'
              labelProps={{ c: 'secondary' }}
              placeholder='Pick a date'
              {...form.getInputProps('company.dateOfIncorporation')}
              mt='md'
              value={
                form.getValues().company?.dateOfIncorporation
                  ? new Date(form.getValues().company?.dateOfIncorporation)
                  : null
              }
              onChange={(e) => {
                if (e) {
                  const formattedDate = dayjs(e).format('YYYY-MM-DD')
                  form.setFieldValue('company.dateOfIncorporation', formattedDate)
                } else {
                  form.setFieldValue('company.dateOfIncorporation', '')
                }
              }}
            />

            <TextInput
              label='Company registration number'
              labelProps={{ c: 'secondary' }}
              placeholder='Enter registration number'
              {...form.getInputProps('company.registrationNumber')}
              mt='md'
            />

            <Select
              label='Country of incorporation'
              labelProps={{ c: 'secondary' }}
              placeholder='Enter country of incorporation'
              {...getSelectPropsForCountry(form.getValues().company.countryOfIncorporation)}
              {...form.getInputProps('company.countryOfIncorporation')}
              key={form.key('company.countryOfIncorporation')}
              mt='md'
            />

            <Checkbox.Group
              labelProps={{ c: 'secondary' }}
              label='What is the purpose of this account?'
              {...form.getInputProps('account.purposesOfAccount')}
              key={form.key('account.purposesOfAccount')}
              onChange={(v) => handleChangeInPurposesOfAccount(v as PurposeOfAccountType[])}
            >
              <Stack mt='xs'>
                {(Object.keys(PurposeOfAccountMapper) as PurposeOfAccountType[]).map((k) => (
                  <Stack key={k}>
                    <Checkbox value={k} label={PurposeOfAccountMapper[k]} styles={checkboxStyles} />

                    {/* Section - PAYMENTS_TO_SERVICE_PROVIDER */}

                    {k === 'PAYMENTS_TO_SERVICE_PROVIDER' &&
                      form.getValues().account.purposesOfAccount?.includes('PAYMENTS_TO_SERVICE_PROVIDER') && (
                        <Stack gap='xl' pl='2xl'>
                          <Box c='textPrimary' fz='sm'>
                            Who are your main service providers?
                          </Box>

                          {(form.getValues().account.purposesOfAccountAdditionalInformation.serviceProviders ?? []).map(
                            (s, index) => (
                              <Group
                                key={index}
                                p='lg'
                                sx={(theme) => ({
                                  background: getThemeColor(colorAliases.light700, theme),
                                  border: '1pt dashed gray',
                                })}
                              >
                                <TextInput
                                  labelProps={{ c: 'secondary' }}
                                  label='Service provider name'
                                  {...form.getInputProps(
                                    `account.purposesOfAccountAdditionalInformation.serviceProviders.${index}.name`
                                  )}
                                />
                                <Select
                                  labelProps={{ c: 'secondary' }}
                                  label='Location'
                                  searchable
                                  {...getSelectPropsForCountry(s?.locationCountryCode)}
                                  {...form.getInputProps(
                                    `account.purposesOfAccountAdditionalInformation.serviceProviders.${index}.locationCountryCode`
                                  )}
                                />
                                {index > 0 && (
                                  <Button
                                    bg='negative'
                                    c='error'
                                    onClick={() =>
                                      form.removeListItem(
                                        'account.purposesOfAccountAdditionalInformation.serviceProviders',
                                        index
                                      )
                                    }
                                    sx={{ justifySelf: 'end' }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Group>
                            )
                          )}
                          {form.getValues().account.purposesOfAccountAdditionalInformation?.serviceProviders.length <
                            3 && (
                            <Button
                              color='secondary'
                              h='2.6rem'
                              fz='xs'
                              onClick={() =>
                                form.insertListItem('account.purposesOfAccountAdditionalInformation.serviceProviders', {
                                  locationCountryCode: null,
                                  name: '',
                                })
                              }
                              leftSection={<IconPlusClear style={{ color: 'white', height: '1.25rem' }} />}
                            >
                              Add another service provider
                            </Button>
                          )}
                        </Stack>
                      )}

                    {/* Section - RECEIVING_FUNDS_FROM_YOUR_CLIENTS */}

                    {k === 'RECEIVING_FUNDS_FROM_YOUR_CLIENTS' &&
                      form.getValues().account.purposesOfAccount.includes('RECEIVING_FUNDS_FROM_YOUR_CLIENTS') && (
                        <Stack gap='xl' pl='2xl'>
                          <Box c='textPrimary' fz='sm'>
                            Who are your main clients?
                          </Box>

                          {(form.getValues().account.purposesOfAccountAdditionalInformation?.clients ?? []).map(
                            (client, index) => (
                              <Group
                                gap='xl'
                                key={index}
                                px='lg'
                                py='md'
                                sx={(theme) => ({
                                  background: getThemeColor(colorAliases.light700, theme),
                                  border: '1pt dashed gray',
                                })}
                              >
                                <TextInput
                                  labelProps={{ c: 'secondary' }}
                                  label='Client name'
                                  {...form.getInputProps(
                                    `account.purposesOfAccountAdditionalInformation.clients.${index}.name`
                                  )}
                                />

                                <Select
                                  labelProps={{ c: 'secondary' }}
                                  label='Location'
                                  searchable
                                  {...getSelectPropsForCountry(client?.locationCountryCode)}
                                  {...form.getInputProps(
                                    `account.purposesOfAccountAdditionalInformation.clients.${index}.locationCountryCode`
                                  )}
                                />

                                {index > 0 && (
                                  <Button
                                    color='error'
                                    onClick={() =>
                                      form.removeListItem(
                                        'account.purposesOfAccountAdditionalInformation.clients',
                                        index
                                      )
                                    }
                                    sx={{ justifySelf: 'end' }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Group>
                            )
                          )}

                          {form.getValues().account.purposesOfAccountAdditionalInformation?.clients?.length ===
                            undefined ||
                            (form.getValues().account.purposesOfAccountAdditionalInformation?.clients.length < 3 && (
                              <Button
                                color='secondary'
                                h='2.6rem'
                                fz='xs'
                                onClick={() =>
                                  form.insertListItem('account.purposesOfAccountAdditionalInformation.clients', {
                                    locationCountryCode: null,

                                    name: '',
                                  })
                                }
                                leftSection={<IconPlusClear style={{ color: 'white', height: '1.25rem' }} />}
                              >
                                Add another client
                              </Button>
                            ))}
                        </Stack>
                      )}

                    {/* Section - RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS */}

                    {k === 'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS' &&
                      form
                        .getValues()
                        .account.purposesOfAccount.includes(
                          'RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS'
                        ) && (
                        <Box pl='2xl'>
                          <TextInput
                            labelProps={{ c: 'secondary' }}
                            label='On whose behalf will the payments be collected?'
                            {...form.getInputProps(
                              'account.purposesOfAccountAdditionalInformation.receiveFundsOnBehalfOfOthers'
                            )}
                          />
                        </Box>
                      )}

                    {/* Section - OTHER */}

                    {k === 'OTHER' && form.getValues().account.purposesOfAccount.includes('OTHER') && (
                      <Box pl='2xl'>
                        <TextInput
                          labelProps={{ c: 'secondary' }}
                          label='Please provide details, including what the purpose of the incoming and outgoing funds will be and names and locations of the main parties transacting with your Leikur account.'
                          {...form.getInputProps('account.purposesOfAccountOther')}
                          onChange={(e) => form.setFieldValue('account.purposesOfAccountOther', e.target.value)}
                          description={`${
                            form.getValues().account.purposesOfAccountOther?.length ?? '0'
                          } of 500 characters`}
                          maxLength={500}
                        />
                      </Box>
                    )}
                  </Stack>
                ))}
              </Stack>
            </Checkbox.Group>
          </Stack>

          <Group justify='space-between'>
            <Button name='save' type='submit' mt='lg'>
              Save
            </Button>
            <Button name='saveAndContinue' type='submit' mt='lg'>
              Save and continue
            </Button>
          </Group>
        </form>
      </Paper>
    </Flex>
  )
}

export default BasicDetails
