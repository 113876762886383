import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Group, Stack, Text, Box } from '@mantine/core'
import { DisplayCountryNameWithFlag } from '@/components/common/DisplayCountryNameWithFlag'
import { TaxRegulationsValidationFields, CrsFatcaMapper } from '../step6/TaxRegulations'
import ReviewBase from './ReviewBase'

const ReviewTaxRegulations = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 6 | Tax Regulations' step={6} validationFields={TaxRegulationsValidationFields}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Company country of residence for tax purpose
        </Text>
        {form.getValues().company.countryOfIncorporation ? (
          <DisplayCountryNameWithFlag countryCode={form.getValues().company.countryOfIncorporation!} />
        ) : (
          <Text fz='md' fw='500' c='textPrimary'>
            -
          </Text>
        )}

        {form.getValues().company.taxIdentification.additionalCountries.length > 0 && (
          <Stack>
            <Text fz='sm' my='3xs' c='secondary'>
              Additional countries of residence for tax purposes
            </Text>
            <Group>
              {form.getValues().company.taxIdentification.additionalCountries.map((c) => (
                <Box key={c.countryCode} bg='gray' p='xs' style={{ borderRadius: '1rem' }}>
                  <Group>
                    <Text fz='md' fw='500' c='textPrimary'>
                      {c.tin}
                    </Text>
                    <DisplayCountryNameWithFlag countryCode={c.countryCode!} />
                  </Group>
                </Box>
              ))}
            </Group>
          </Stack>
        )}

        <Text fz='sm' my='3xs' c='secondary'>
          Is the company an Active or Passive Non Financial Entity/Non Financial Foreign Entity for CRS/FATCA purposes?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {(form.getValues().company.crsFatcaType && CrsFatcaMapper[form.getValues().company.crsFatcaType!]) || '-'}
        </Text>
      </Stack>
    </ReviewBase>
  )
}

export default ReviewTaxRegulations
