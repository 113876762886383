import { Anchor, Box, Breadcrumbs, Button, Flex, Group, List, Paper, Select, Stack, Textarea } from '@mantine/core'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { helpBorder } from '../onboardingUtils'
import { IconArrowRight } from '@/components/icons'
import { colorAliases } from '@/theme/mantineTheme'
import { FormEvent } from 'react'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { BusinessActivityIndustryType, BusinessActivitySubIndustryType } from '@/api/org/types'
import OnboardingStatus from '../status/OnboardingStatus'

export const BusinessActivityIndustryMapper: Record<BusinessActivityIndustryType, string> = {
  AGRICULTURE_FORESTRY_AND_FISHING: 'Agriculture, Forestry, and Fishing',
  MINING_AND_QUARRYING: 'Mining and Quarrying',
  MANUFACTURING: 'Manufacturing',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: 'Electricity, Gas, Steam, and Air Conditioning Supply',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES:
    'Water Supply, Sewerage, Waste Management and Remediation Activities',
  CONSTRUCTION: 'Construction',
  WHOLESALE_AND_RETAIL_TRADE: 'Wholesale and Retail Trade',
  TRANSPORTATION_AND_STORAGE: 'Transportation and Storage',
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES: 'Accommodation and Food Service Activities',
  INFORMATION_AND_COMMUNICATION: 'Information and Communication',
  FINANCIAL_AND_INSURANCE_ACTIVITIES: 'Financial and Insurance Activities',
  REAL_ESTATE_ACTIVITIES: 'Real Estate Activities',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: 'Professional, Scientific and Technical Activities',
  ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES: 'Administrative and Support Service Activities',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY:
    'Public Administration and Defence, Compulsory Social Security',
  EDUCATION: 'Education',
  HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES: 'Human Health and Social Work Activities',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'Arts, Entertainment, and Recreation',
  GAMBLING_AND_BETTING_ACTIVITIES: 'Gambling and Betting Activities',
  OTHER_SERVICE_ACTIVITIES: 'Other Service Activities',
}

export const BusinessActivitySubIndustryMapper: Record<BusinessActivitySubIndustryType, string> = {
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES: 'Accommodation and Food Service Activities',
  EMPLOYMENT_ACTIVITIES: 'Employment Activities',
  OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES:
    'Office Administrative, Office Support, and Other Business Support Activities',
  RENTAL_AND_LEASING_ACTIVITIES: 'Rental and Leasing Activities',
  SECURITY_AND_INVESTIGATION_ACTIVITIES: 'Security and Investigation Activities',
  SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES: 'Services to Buildings and Landscape Activities',
  TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES:
    'Travel Agency, Tour Operator, and Other Reservation Service and Related Activities',
  CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES:
    'Crop and Animal Production, Hunting, and Related Service Activities',
  FISHING_AND_AQUACULTURE: 'Fishing and Aquaculture',
  FORESTRY_AND_LOGGING: 'Forestry and Logging',
  CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES: 'Creative Arts and Entertainment Activities',
  LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES:
    'Libraries, Archives, Museums, and Other Cultural Activities',
  SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES:
    'Sports Activities and Amusement and Recreation Activities',
  CONSTRUCTION: 'Construction',
  EDUCATION: 'Education',
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: 'Electricity, Gas, Steam, and Air Conditioning Supply',
  ACTIVITIES_OF_HOLDING_COMPANIES: 'Activities of Holding Companies',
  BANKS_OR_CREDIT_UNIONS: 'Banks or Credit Unions',
  CONSUMER_CREDIT_PAYDAY_LOANS_PAWNBROKERS_PREPAID_AND_OR_GIFT_CARDS:
    'Consumer Credit, Payday Loans, Pawnbrokers, Prepaid and/or Gift Cards',
  CROWDFUNDING: 'Crowdfunding',
  INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY:
    'Insurance, Reinsurance, and Pension Funding, except Compulsory Social Security',
  PAYMENT_SERVICE_PROVIDERS_ELECTRONIC_MONEY_INSTITUTIONS: 'Payment Service Providers, Electronic Money Institutions',
  PHYSICAL_CURRENCY_EXCHANGE: 'Physical Currency Exchange',
  PRIVATE_BANKING: 'Private Banking',
  TRADING_FX_AND_INVESTMENT: 'Trading FX and Investment',
  VIRTUAL_CURRENCY: 'Virtual Currency',
  LOTTERY: 'Lottery',
  ONLINE_GAMING_OR_GAMBLING: 'Online Gaming or Gambling',
  PAYING_AGENT: 'Paying Agent',
  PHYSICAL_BETTING_OR_BOOKMAKER: 'Physical Betting or Bookmaker',
  PHYSICAL_CASINOS: 'Physical Casinos',
  PHYSICAL_SLOT_MACHINES: 'Physical Slot Machines',
  HUMAN_HEALTH_ACTIVITIES: 'Human Health Activities',
  RESIDENTIAL_CARE_AND_SOCIAL_WORK_ACTIVITIES: 'Residential Care and Social Work Activities',
  COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES: 'Computer Programming, Consultancy, and Related Activities',
  INFORMATION_SERVICE_ACTIVITIES: 'Information Service Activities',
  MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES:
    'Motion Picture, Video and Television Programme Production, Sound Recording, and Music Publishing Activities',
  PROGRAMMING_AND_BROADCASTING_ACTIVITIES: 'Programming and Broadcasting Activities',
  PUBLISHING_ACTIVITIES: 'Publishing Activities',
  TELECOMMUNICATIONS: 'Telecommunications',
  MANUFACTURE_OF_BASIC_METALSFABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT:
    'Manufacture of Basic Metals, Fabricated Metal Products except Machinery and Equipment',
  MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS:
    'Manufacture of Basic Pharmaceutical Products and Pharmaceutical Preparations',
  MANUFACTURE_OF_BEVERAGES: 'Manufacture of Beverages',
  MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS: 'Manufacture of Chemicals and Chemical Products',
  MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS: 'Manufacture of Coke and Refined Petroleum Products',
  MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS: 'Manufacture of Computer, Electronic and Optical Products',
  MANUFACTURE_OF_ELECTRICAL_EQUIPMENT: 'Manufacture of Electrical Equipment',
  MANUFACTURE_OF_FOOD_PRODUCTS: 'Manufacture of Food Products',
  MANUFACTURE_OF_FURNITURE: 'Manufacture of Furniture',
  MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS: 'Manufacture of Leather and Related Products',
  MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_NEC: 'Manufacture of Machinery and Equipment NEC',
  MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS: 'Manufacture of Motor Vehicles, Trailers and Semi-Trailers',
  MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS: 'Manufacture of Other Non-Metallic Mineral Products',
  MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT: 'Manufacture of Other Transport Equipment',
  MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS: 'Manufacture of Paper and Paper Products',
  MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS: 'Manufacture of Rubber and Plastic Products',
  MANUFACTURE_OF_TEXTILES: 'Manufacture of Textiles',
  MANUFACTURE_OF_TOBACCO_PRODUCTS: 'Manufacture of Tobacco Products',
  MANUFACTURE_OF_WEAPONS_AND_AMMUNITION: 'Manufacture of Weapons and Ammunition',
  MANUFACTURE_OF_WEARING_APPAREL: 'Manufacture of Wearing Apparel',
  MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS:
    'Manufacture of Wood and Products of Wood and Cork, except Furniture, Manufacture of Articles of Straw and Plaiting Materials',
  OTHER_MANUFACTURING: 'Other Manufacturing',
  PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA: 'Printing and Reproduction of Recorded Media',
  REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT: 'Repair and Installation of Machinery and Equipment',
  EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS: 'Extraction of Crude Petroleum and Natural Gas',
  MINING_OF_COAL_AND_LIGNITE: 'Mining of Coal and Lignite',
  MINING_OF_METAL_ORES: 'Mining of Metal Ores',
  MINING_SUPPORT_SERVICE_ACTIVITIES: 'Mining Support Service Activities',
  OTHER_MINING_AND_QUARRYING: 'Other Mining and Quarrying',
  ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS: 'Activities of Membership Organisations',
  FUNERAL_AND_RELATED_ACTIVITIES: 'Funeral and Related Activities',
  HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT: 'Hairdressing and Other Beauty Treatment',
  OTHER_PERSONAL_SERVICE_ACTIVITIES_NEC: 'Other Personal Service Activities NEC',
  PHYSICAL_WELL_BEING_ACTIVITIES: 'Physical Well-Being Activities',
  REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS: 'Repair of Computers and Personal and Household Goods',
  WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS: 'Washing and Dry Cleaning of Textile and Fur Products',
  ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES:
    'Activities of Head Offices, Management Consultancy Activities',
  ADVERTISING_AND_MARKET_RESEARCH: 'Advertising and Market Research',
  ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS:
    'Architectural and Engineering Activities, Technical Testing and Analysis',
  LEGAL_AND_ACCOUNTING_ACTIVITIES: 'Legal and Accounting Activities',
  OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: 'Other Professional, Scientific and Technical Activities',
  SCIENTIFIC_RESEARCH_AND_DEVELOPMENT: 'Scientific Research and Development',
  VETERINARY_AND_PET_CARE_SERVICES: 'Veterinary and Pet Care Services',
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY:
    'Public Administration and Defence, Compulsory Social Security',
  REAL_ESTATE_ACTIVITIES: 'Real Estate Activities',
  AIR_TRANSPORT: 'Air Transport',
  LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES: 'Land Transport and Transport via Pipelines',
  POSTAL_AND_COURIER_ACTIVITIES: 'Postal and Courier Activities',
  WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION: 'Warehousing and Support Activities for Transportation',
  WATER_TRANSPORT: 'Water Transport',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES:
    'Water Supply, Sewerage, Waste Management and Remediation Activities',
  DISPENSING_CHEMIST_IN_SPECIALISED_STORES: 'Dispensing Chemist in Specialised Stores',
  NON_SPECIALISED_WHOLESALE_TRADE: 'Non-Specialised Wholesale Trade',
  OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES: 'Other Retail Sale of New Goods in Specialised Stores',
  OTHER_SPECIALISED_WHOLESALE: 'Other Specialised Wholesale',
  RETAIL_SALE_IN_NON_SPECIALISED_STORES: 'Retail Sale in Non-Specialised Stores',
  RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES: 'Retail Sale of Automotive Fuel in Specialised Stores',
  RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES: 'Retail Sale of Clothing in Specialised Stores',
  RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES:
    'Retail Sale of Cosmetic and Toilet Articles in Specialised Stores',
  RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES:
    'Retail Sale of Cultural and Recreation Goods in Specialised Stores',
  RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES:
    'Retail Sale of Flowers, Plants, Seeds, Fertilisers, Pet Animals and Pet Food in Specialised Stores',
  RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES:
    'Retail Sale of Food, Beverages, and Tobacco in Specialised Stores',
  RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES:
    'Retail Sale of Footwear and Leather Goods in Specialised Stores',
  RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES:
    'Retail Sale of Information and Communication Equipment in Specialised Stores',
  RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES:
    'Retail Sale of Medical and Orthopaedic Goods in Specialised Stores',
  RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES:
    'Retail Sale of Other Household Equipment in Specialised Stores',
  RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES: 'Retail Sale of Second-Hand Goods in Stores',
  RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES:
    'Retail Sale of Watches and Jewellery in Specialised Stores',
  RETAIL_TRADE_IN_MARKETPLACES: 'Retail Trade in Marketplaces',
  WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES:
    'Wholesale and Retail Trade and Repair of Motor Vehicles and Motorcycles',
  WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS: 'Wholesale of Agricultural Raw Materials and Live Animals',
  WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO: 'Wholesale of Food, Beverages, and Tobacco',
  WHOLESALE_OF_HOUSEHOLD_GOODS: 'Wholesale of Household Goods',
  WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT: 'Wholesale of Information and Communication Equipment',
  WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES: 'Wholesale of Other Machinery, Equipment, and Supplies',
}

export const SubIndustriesForIndustry: Record<BusinessActivityIndustryType, BusinessActivitySubIndustryType[]> = {
  ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES: ['ACCOMMODATION_AND_FOOD_SERVICE_ACTIVITIES'],
  ADMINISTRATIVE_AND_SUPPORT_SERVICE_ACTIVITIES: [
    'EMPLOYMENT_ACTIVITIES',
    'OFFICE_ADMINISTRATIVE_OFFICE_SUPPORT_AND_OTHER_BUSINESS_SUPPORT_ACTIVITIES',
    'RENTAL_AND_LEASING_ACTIVITIES',
    'SECURITY_AND_INVESTIGATION_ACTIVITIES',
    'SERVICES_TO_BUILDINGS_AND_LANDSCAPE_ACTIVITIES',
    'TRAVEL_AGENCY_TOUR_OPERATOR_AND_OTHER_RESERVATION_SERVICE_AND_RELATED_ACTIVITIES',
  ],
  AGRICULTURE_FORESTRY_AND_FISHING: [
    'CROP_AND_ANIMAL_PRODUCTION_HUNTING_AND_RELATED_SERVICE_ACTIVITIES',
    'FISHING_AND_AQUACULTURE',
    'FORESTRY_AND_LOGGING',
  ],
  ARTS_ENTERTAINMENT_AND_RECREATION: [
    'CREATIVE_ARTS_AND_ENTERTAINMENT_ACTIVITIES',
    'LIBRARIES_ARCHIVES_MUSEUMS_AND_OTHER_CULTURAL_ACTIVITIES',
    'SPORTS_ACTIVITIES_AND_AMUSEMENT_AND_RECREATION_ACTIVITIES',
  ],
  CONSTRUCTION: ['CONSTRUCTION'],
  EDUCATION: ['EDUCATION'],
  ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY: ['ELECTRICITY_GAS_STEAM_AND_AIR_CONDITIONING_SUPPLY'],
  FINANCIAL_AND_INSURANCE_ACTIVITIES: [
    'ACTIVITIES_OF_HOLDING_COMPANIES',
    'BANKS_OR_CREDIT_UNIONS',
    'CONSUMER_CREDIT_PAYDAY_LOANS_PAWNBROKERS_PREPAID_AND_OR_GIFT_CARDS',
    'CROWDFUNDING',
    'INSURANCE_REINSURANCE_AND_PENSION_FUNDING_EXCEPT_COMPULSORY_SOCIAL_SECURITY',
    'PAYMENT_SERVICE_PROVIDERS_ELECTRONIC_MONEY_INSTITUTIONS',
    'PHYSICAL_CURRENCY_EXCHANGE',
    'PRIVATE_BANKING',
    'TRADING_FX_AND_INVESTMENT',
    'VIRTUAL_CURRENCY',
  ],
  GAMBLING_AND_BETTING_ACTIVITIES: [
    'LOTTERY',
    'ONLINE_GAMING_OR_GAMBLING',
    'PAYING_AGENT',
    'PHYSICAL_BETTING_OR_BOOKMAKER',
    'PHYSICAL_CASINOS',
    'PHYSICAL_SLOT_MACHINES',
  ],
  HUMAN_HEALTH_AND_SOCIAL_WORK_ACTIVITIES: ['HUMAN_HEALTH_ACTIVITIES', 'RESIDENTIAL_CARE_AND_SOCIAL_WORK_ACTIVITIES'],
  INFORMATION_AND_COMMUNICATION: [
    'COMPUTER_PROGRAMMING_CONSULTANCY_AND_RELATED_ACTIVITIES',
    'INFORMATION_SERVICE_ACTIVITIES',
    'MOTION_PICTURE_VIDEO_AND_TELEVISION_PROGRAMME_PRODUCTION_SOUND_RECORDING_AND_MUSIC_PUBLISHING_ACTIVITIES',
    'PROGRAMMING_AND_BROADCASTING_ACTIVITIES',
    'PUBLISHING_ACTIVITIES',
    'TELECOMMUNICATIONS',
  ],
  MANUFACTURING: [
    'MANUFACTURE_OF_BASIC_METALSFABRICATED_METAL_PRODUCTS_EXCEPT_MACHINERY_AND_EQUIPMENT',
    'MANUFACTURE_OF_BASIC_PHARMACEUTICAL_PRODUCTS_AND_PHARMACEUTICAL_PREPARATIONS',
    'MANUFACTURE_OF_BEVERAGES',
    'MANUFACTURE_OF_CHEMICALS_AND_CHEMICAL_PRODUCTS',
    'MANUFACTURE_OF_COKE_AND_REFINED_PETROLEUM_PRODUCTS',
    'MANUFACTURE_OF_COMPUTER_ELECTRONIC_AND_OPTICAL_PRODUCTS',
    'MANUFACTURE_OF_ELECTRICAL_EQUIPMENT',
    'MANUFACTURE_OF_FOOD_PRODUCTS',
    'MANUFACTURE_OF_FURNITURE',
    'MANUFACTURE_OF_LEATHER_AND_RELATED_PRODUCTS',
    'MANUFACTURE_OF_MACHINERY_AND_EQUIPMENT_NEC',
    'MANUFACTURE_OF_MOTOR_VEHICLES_TRAILERS_AND_SEMI_TRAILERS',
    'MANUFACTURE_OF_OTHER_NON_METALLIC_MINERAL_PRODUCTS',
    'MANUFACTURE_OF_OTHER_TRANSPORT_EQUIPMENT',
    'MANUFACTURE_OF_PAPER_AND_PAPER_PRODUCTS',
    'MANUFACTURE_OF_RUBBER_AND_PLASTIC_PRODUCTS',
    'MANUFACTURE_OF_TEXTILES',
    'MANUFACTURE_OF_TOBACCO_PRODUCTS',
    'MANUFACTURE_OF_WEAPONS_AND_AMMUNITION',
    'MANUFACTURE_OF_WEARING_APPAREL',
    'MANUFACTURE_OF_WOOD_AND_OF_PRODUCTS_OF_WOOD_AND_CORK_EXCEPT_FURNITURE_MANUFACTURE_OF_ARTICLES_OF_STRAW_AND_PLAITING_MATERIALS',
    'OTHER_MANUFACTURING',
    'PRINTING_AND_REPRODUCTION_OF_RECORDED_MEDIA',
    'REPAIR_AND_INSTALLATION_OF_MACHINERY_AND_EQUIPMENT',
  ],
  MINING_AND_QUARRYING: [
    'EXTRACTION_OF_CRUDE_PETROLEUM_AND_NATURAL_GAS',
    'MINING_OF_COAL_AND_LIGNITE',
    'MINING_OF_METAL_ORES',
    'MINING_SUPPORT_SERVICE_ACTIVITIES',
    'OTHER_MINING_AND_QUARRYING',
  ],
  OTHER_SERVICE_ACTIVITIES: [
    'ACTIVITIES_OF_MEMBERSHIP_ORGANISATIONS',
    'FUNERAL_AND_RELATED_ACTIVITIES',
    'HAIRDRESSING_AND_OTHER_BEAUTY_TREATMENT',
    'OTHER_PERSONAL_SERVICE_ACTIVITIES_NEC',
    'PHYSICAL_WELL_BEING_ACTIVITIES',
    'REPAIR_OF_COMPUTERS_AND_PERSONAL_AND_HOUSEHOLD_GOODS',
    'WASHING_AND_DRY_CLEANING_OF_TEXTILE_AND_FUR_PRODUCTS',
  ],
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES: [
    'ACTIVITIES_OF_HEAD_OFFICES_MANAGEMENT_CONSULTANCY_ACTIVITIES',
    'ADVERTISING_AND_MARKET_RESEARCH',
    'ARCHITECTURAL_AND_ENGINEERING_ACTIVITIES_TECHNICAL_TESTING_AND_ANALYSIS',
    'LEGAL_AND_ACCOUNTING_ACTIVITIES',
    'OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_ACTIVITIES',
    'SCIENTIFIC_RESEARCH_AND_DEVELOPMENT',
    'VETERINARY_AND_PET_CARE_SERVICES',
  ],
  PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY: [
    'PUBLIC_ADMINISTRATION_AND_DEFENCE_COMPULSORY_SOCIAL_SECURITY',
  ],
  REAL_ESTATE_ACTIVITIES: ['REAL_ESTATE_ACTIVITIES'],
  TRANSPORTATION_AND_STORAGE: [
    'AIR_TRANSPORT',
    'LAND_TRANSPORT_AND_TRANSPORT_VIA_PIPELINES',
    'POSTAL_AND_COURIER_ACTIVITIES',
    'WAREHOUSING_AND_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION',
    'WATER_TRANSPORT',
  ],
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES: [
    'WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_AND_REMEDIATION_ACTIVITIES',
  ],
  WHOLESALE_AND_RETAIL_TRADE: [
    'DISPENSING_CHEMIST_IN_SPECIALISED_STORES',
    'NON_SPECIALISED_WHOLESALE_TRADE',
    'OTHER_RETAIL_SALE_OF_NEW_GOODS_IN_SPECIALISED_STORES',
    'OTHER_SPECIALISED_WHOLESALE',
    'RETAIL_SALE_IN_NON_SPECIALISED_STORES',
    'RETAIL_SALE_OF_AUTOMOTIVE_FUEL_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_CLOTHING_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_COSMETIC_AND_TOILET_ARTICLES_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_CULTURAL_AND_RECREATION_GOODS_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_FLOWERS_PLANTS_SEEDS_FERTILISERS_PET_ANIMALS_AND_PET_FOOD_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_FOOD_BEVERAGES_AND_TOBACCO_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_FOOTWEAR_AND_LEATHER_GOODS_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_MEDICAL_AND_ORTHOPAEDIC_GOODS_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_OTHER_HOUSEHOLD_EQUIPMENT_IN_SPECIALISED_STORES',
    'RETAIL_SALE_OF_SECOND_HAND_GOODS_IN_STORES',
    'RETAIL_SALE_OF_WATCHES_AND_JEWELLERY_IN_SPECIALISED_STORES',
    'RETAIL_TRADE_IN_MARKETPLACES',
    'WHOLESALE_AND_RETAIL_TRADE_AND_REPAIR_OF_MOTOR_VEHICLES_AND_MOTORCYCLES',
    'WHOLESALE_OF_AGRICULTURAL_RAW_MATERIALS_AND_LIVE_ANIMALS',
    'WHOLESALE_OF_FOOD_BEVERAGES_AND_TOBACCO',
    'WHOLESALE_OF_HOUSEHOLD_GOODS',
    'WHOLESALE_OF_INFORMATION_AND_COMMUNICATION_EQUIPMENT',
    'WHOLESALE_OF_OTHER_MACHINERY_EQUIPMENT_AND_SUPPLIES',
  ],
}

export const BusinessActivityValidationFields = [
  'company.businessActivityDescription',
  'company.businessActivityIndustry',
  'company.businessActivitySubIndustry',
]

const BusinessActivity = () => {
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()
  const navigate = useNavigate()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(BusinessActivityValidationFields)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/9`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 7 | Business Activity</Box>
        </Breadcrumbs>

        <Stack gap='lg'>
          <form onSubmit={handleSubmit}>
            <Box p='lg' pb={0} lh='1.55' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
              <Box fz='sm' fw='bold' c='secondary' pr='1rem' lh='1.6'>
                Please provide a detailed description of the business activity of the company applying for the account
              </Box>
              <List px='xs' py='lg' fz='xs'>
                <List.Item>
                  If the business is formed within a group structure of companies, please provide a detailed description
                  of the business activities of the group forming the business and in addition explain separately for
                  the company applying for the account the exact activity within the group.
                </List.Item>

                <List.Item>If available, please attach a description of your business model.</List.Item>
                <List.Item>If you sell products, please describe the products and how the delivery happens.</List.Item>
              </List>
            </Box>
            <Box>
              <Textarea
                labelProps={{ c: 'secondary' }}
                label='Detailed description of the business activity'
                description={`At least 50 characters required. ${
                  form.getValues().company?.businessActivityDescription?.length ?? ''
                } of 50000 characters`}
                minRows={8}
                maxLength={50000}
                {...form.getInputProps('company.businessActivityDescription')}
                onChange={(e) => form.setFieldValue('company.businessActivityDescription', e.target.value)}
              />
            </Box>
            <Select
              labelProps={{ c: 'secondary' }}
              label='One core industry that is applicable to your business'
              description=''
              searchable
              {...form.getInputProps('company.businessActivityIndustry')}
              key={form.key('company.businessActivityIndustry')}
              onChange={(v) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                form.getInputProps('company.businessActivityIndustry').onChange(v)
                form.setFieldValue('businessActivitySubIndustry', null)
              }}
              data={(Object.keys(BusinessActivityIndustryMapper) as BusinessActivityIndustryType[]).map((k) => ({
                value: k,
                label: BusinessActivityIndustryMapper[k],
              }))}
            />
            <Select
              labelProps={{ c: 'secondary' }}
              disabled={form.getValues().company?.businessActivityIndustry === undefined}
              label='Type of industry that is applicable to your business'
              searchable
              {...form.getInputProps('company.businessActivitySubIndustry')}
              key={form.key('company.businessActivitySubIndustry')}
              data={
                form.getValues().company?.businessActivityIndustry
                  ? SubIndustriesForIndustry[form.getValues().company.businessActivityIndustry!].map((s) => ({
                      value: s,
                      label: BusinessActivitySubIndustryMapper[s],
                    }))
                  : []
              }
            />
            <Group justify='space-between' mt='lg'>
              <Button color='dark' onClick={() => navigate(`/onboarding/${invitationCode}/step/6`)}>
                Previous
              </Button>
              <Group>
                <Button name='save' type='submit'>
                  Save
                </Button>
                <Button name='saveAndContinue' type='submit'>
                  Save and continue
                </Button>
              </Group>
            </Group>
          </form>
        </Stack>
      </Paper>
    </Flex>
  )
}

export default BusinessActivity
