import { Stack, Text } from '@mantine/core'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { ConvertBooleanToYesNoUndefined } from '@/utils/formatting'
import { DataProtectionValidationFields } from '../step8/DataProtection'
import ReviewBase from './ReviewBase'

const ReviewDataProtection = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 8 | Data Protection' step={8} validationFields={DataProtectionValidationFields}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Leikur respects your privacy and will process your personal data in accordance with the GDPR (General Data
          Protection Regulation) and other applicable legal acts. <br /> Has the Privacy Policy been accepted?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {ConvertBooleanToYesNoUndefined(form.getValues().hasAcceptedPrivacyPolicy) ?? '-'}
        </Text>
      </Stack>
    </ReviewBase>
  )
}

export default ReviewDataProtection
