import { useQuery, useMutation } from '@tanstack/react-query'
import { ApiError } from '../interface'
import { BusinessDetailsRequest, BusinessDetailsResponse } from './types'
import {
  DeleteOnboardingDocumentRequest,
  OnboardingInfoResponse,
  SubmitOnboardingInfoRequest,
  UploadOnboardingDocumentRequest,
} from './interface'
import {
  deleteOnboardingDocument,
  getOnboardingDetails,
  submitOrgSignUp,
  submitPartialOnboardingDetails,
  uploadOnboardingDocument,
  validateOrgDetails,
} from './service'
import { notify } from '@/utils/notify'

export const useValidateOrgDetails = (invitationCode: string, options = {}) => {
  return useQuery<BusinessDetailsResponse | null, ApiError>(
    ['validateOrgDetails', invitationCode],
    async () => validateOrgDetails(invitationCode),
    {
      retry: 1,
      staleTime: 1000 * 60 * 5,
      enabled: !!invitationCode,
      ...options,
      onError: (error) => {
        console.error(`Validate Org Details error: ${error.code} - ${error.description}`)
      },
    }
  )
}

export const useSubmitOrgSignUp = () => {
  return useMutation<
    BusinessDetailsResponse | null,
    ApiError,
    { invitationCode: string; request: BusinessDetailsRequest }
  >(({ invitationCode, request }) => submitOrgSignUp(invitationCode, request), {
    onSuccess: () => {
      notify('success', 'Organization signup details submitted successfully')
    },
    onError: (error) => {
      notify('error', `Error: ${error.code} - ${error.description}`)
    },
  })
}

export const useGetOnboardingDetails = (invitationCode: string, options = {}) => {
  return useQuery<OnboardingInfoResponse | null, ApiError>(
    ['onboardingDetails', invitationCode],
    async () => getOnboardingDetails(invitationCode),
    {
      retry: 1,
      refetchOnWindowFocus: true,
      enabled: !!invitationCode,
      ...options,
      onError: (error) => {
        console.error(`Get Onboarding Details error: ${error.code} - ${error.description}`)
        return error
      },
    }
  )
}

export const useSubmitPartialOnboardingDetails = () => {
  return useMutation<
    OnboardingInfoResponse | null,
    ApiError,
    { invitationCode: string; onboardingDetails: SubmitOnboardingInfoRequest }
  >(({ invitationCode, onboardingDetails }) => submitPartialOnboardingDetails(invitationCode, onboardingDetails), {
    onSuccess: () => {
      notify('success', 'Onboarding details submitted successfully')
    },
    onError: (error) => {
      notify('error', `Error: ${error.code} - ${error.description}`)
    },
  })
}

export const useUploadBusinessOnboardingDocument = () => {
  return useMutation<void, ApiError, { invitationCode: string; onboardingDocument: UploadOnboardingDocumentRequest }>(
    ({ invitationCode, onboardingDocument }) => uploadOnboardingDocument(invitationCode, onboardingDocument),
    {
      onSuccess: (data) => {
        console.log('Partial onboarding details submitted successfully:', data)
      },
      onError: (error) => {
        console.error(`Submit Partial Onboarding Details error: ${error.code} - ${error.description}`)
      },
    }
  )
}

export const useDeleteOnboardingDocument = () => {
  return useMutation<void, ApiError, { invitationCode: string; request: DeleteOnboardingDocumentRequest }>(
    ({ invitationCode, request }) => deleteOnboardingDocument(invitationCode, request),
    {
      onSuccess: (data) => {
        console.log('Onboarding document deleted successfully:', data)
      },
      onError: (error) => {
        console.error(`Deleting onboarding document error: ${error.code} - ${error.description}`)
      },
    }
  )
}
