import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Flex,
  Group,
  NumberInput,
  Paper,
  Select,
  Stack,
  Text,
  Textarea,
} from '@mantine/core'
import { IconArrowRight } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormEvent } from 'react'
import {
  AnnualGrossTurnoverType,
  MonthlyGrossTurnoverType,
  MonthlyTransactionVolumeType,
  NumberOfEmployeesType,
  RequestedProductType,
  SourceOfIncomeType,
} from '@/api/org/types'
import OnboardingStatus from '../status/OnboardingStatus'

export const FinancialDetailsValidation = [
  'account.annualGrossTurnover',
  'account.monthlyGrossTurnover',
  'account.monthlyTransactionVolume',
  'account.limits.daily',
  'account.limits.monthly',
  'company.numberOfEmployees',
  'account.requestedProducts',
  'account.sourceOfIncome',
]

export const SourceOfIncomeTypeMap: Record<SourceOfIncomeType, string> = {
  DIVIDENDS: 'Dividends',
  INTEREST: 'Interest',
  DONATIONS: 'Donations',
  OTHER: 'Other',
  BUSINESS_PROCEEDS: 'Business Proceeds',
}

export const RequestedProductTypeMap: Record<RequestedProductType, string> = {
  SEPA_PAYMENTS: 'SEPA Payments',
  INTERNATIONAL_PAYMENTS: 'International Payments',
  CURRENCY_EXCHANGE: 'Currency Exchange',
  BUSINESS_CARDS: 'Business Cards',
  MERCHANT_ACCOUNT: 'Merchant Account',
  SEGREGATED_ACCOUNT: 'Segregated Account',
}

export const NumberOfEmployeesMapper: Record<NumberOfEmployeesType, string> = {
  _1: '1',
  _2_TO_5: '2 - 5',
  _6_TO_10: '6 - 10',
  _11_TO_50: '11 - 50',
  _51_TO_100: '51 - 100',
  MORE_THAN_100: 'More than 100',
}

export const MonthlyGrossTurnoverMap: Record<MonthlyGrossTurnoverType, string> = {
  LESS_THAN_10000: 'Less than 10,000',
  _10000_TO_50000: '10,000 to 50,000',
  _50000_TO_100000: '50,000 to 100,000',
  _100000_TO_1000000: '100,000 to 1,000,000',
  _1000000_TO_10000000: '1,000,000 to 10,000,000',
  MORE_THAN_10000000: 'More than 10,000,000',
}

export const AnnualGrossTurnoverMap: Record<AnnualGrossTurnoverType, string> = {
  LESS_THAN_100000: 'Less than 100,000',
  _100000_TO_500000: '100,000 to 500,000',
  _500000_TO_1000000: '500,000 to 1,000,000',
  _1000000_TO_10000000: '1,000,000 to 10,000,000',
  _10000000_TO_50000000: '10,000,000 to 50,000,000',
  MORE_THAN_50000000: 'More than 50,000,000',
}

export const MonthlyTransactionVolumeMap: Record<MonthlyTransactionVolumeType, string> = {
  LESS_THAN_100: 'Less than 100',
  _100_TO_1000: '100 to 1,000',
  _1000_TO_5000: '1,000 to 5,000',
  _5000_TO_10000: '5,000 to 10,000',
  _10000_TO_50000: '10,000 to 50,000',
  MORE_THAN_50000: 'More than 50,000',
}

const FinancialDetails = () => {
  const navigate = useNavigate()
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const otherFields = ['account.sourceOfIncomeOther']

    const hasOtherSourceOfIncome = form.getValues().account?.sourceOfIncome?.includes('OTHER')

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    const fieldsToValidate = hasOtherSourceOfIncome
      ? [...FinancialDetailsValidation, ...otherFields]
      : FinancialDetailsValidation

    if (validateFields(fieldsToValidate)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/7`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 5 | Financial Details</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <Select
              labelProps={{ c: 'secondary' }}
              data={(Object.keys(AnnualGrossTurnoverMap) as AnnualGrossTurnoverType[]).map((k) => ({
                value: k,
                label: AnnualGrossTurnoverMap[k],
              }))}
              label='Expected annual gross turnover within Leikur'
              placeholder='E.g. 500 000 to 1 000 000 EUR'
              {...form.getInputProps('account.annualGrossTurnover')}
              key={form.key('account.annualGrossTurnover')}
            />
            <Select
              labelProps={{ c: 'secondary' }}
              data={(Object.keys(MonthlyGrossTurnoverMap) as MonthlyGrossTurnoverType[]).map((k) => ({
                value: k,
                label: MonthlyGrossTurnoverMap[k],
              }))}
              label='Expected monthly gross turnover to Leikur'
              placeholder='E.g. 50 000 to 100 000 EUR'
              {...form.getInputProps('account.monthlyGrossTurnover')}
              key={form.key('account.monthlyGrossTurnover')}
            />
            <Select
              labelProps={{ c: 'secondary' }}
              data={(Object.keys(MonthlyTransactionVolumeMap) as MonthlyTransactionVolumeType[]).map((k) => ({
                value: k,
                label: MonthlyTransactionVolumeMap[k],
              }))}
              label='Expected monthly transactions to and from Leikur'
              placeholder='E.g. 5000 to 10 000 transactions'
              {...form.getInputProps('account.monthlyTransactionVolume')}
              key={form.key('account.monthlyTransactionVolume')}
            />
            <NumberInput
              labelProps={{ c: 'secondary' }}
              label='Desired daily account limit (EUR)'
              {...form.getInputProps('account.limits.daily')}
              hideControls
              decimalScale={2}
              placeholder='E.g. 50000'
              key={form.key('account.limits.daily')}
            />
            <NumberInput
              labelProps={{ c: 'secondary' }}
              label='Desired monthly account limit (EUR)'
              {...form.getInputProps('account.limits.monthly')}
              hideControls
              decimalScale={2}
              placeholder='E.g. 700000'
              key={form.key('account.limits.monthly')}
            />
            <Select
              labelProps={{ c: 'secondary' }}
              data={(Object.keys(NumberOfEmployeesMapper) as NumberOfEmployeesType[]).map((k) => ({
                value: k,
                label: NumberOfEmployeesMapper[k],
              }))}
              label='Total number of employees and exclusive contractors that work for the company'
              placeholder='E.g. 6 - 10'
              {...form.getInputProps('company.numberOfEmployees')}
              key={form.key('company.numberOfEmployees')}
            />
            <Checkbox.Group
              {...form.getInputProps('account.requestedProducts')}
              key={form.key('account.requestedProducts')}
              labelProps={{ c: 'secondary' }}
              label='Select the services that you would like to use.'
              onChange={(v) => {
                form.setFieldValue('account.requestedProducts', v as RequestedProductType[])
              }}
            >
              <Stack>
                {(Object.keys(RequestedProductTypeMap) as RequestedProductType[]).map((k) => (
                  <Checkbox key={k} c='grey.6' label={RequestedProductTypeMap[k]} value={k} />
                ))}
              </Stack>
            </Checkbox.Group>

            <Box p='16px' bg='light.7'>
              <Text size='sm'>Note: Only EURO is available for incoming funds.</Text>
            </Box>

            <Checkbox.Group
              {...form.getInputProps('account.sourceOfIncome')}
              key={form.key('account.sourceOfIncome')}
              labelProps={{ c: 'secondary' }}
              label='What will be the source of incoming funds into your Leikur account?'
              onChange={(v) => {
                form.setFieldValue('account.sourceOfIncome', v as SourceOfIncomeType[])
              }}
            >
              <Stack>
                {(Object.keys(SourceOfIncomeTypeMap) as SourceOfIncomeType[]).map((k) => (
                  <Stack key={k}>
                    <Checkbox c='grey.6' label={SourceOfIncomeTypeMap[k]} value={k} />
                    {k === 'OTHER' && form.getValues().account.sourceOfIncome?.includes('OTHER') && (
                      <Box>
                        <Textarea
                          {...form.getInputProps('account.sourceOfIncomeOther')}
                          onChange={(e) => form.setFieldValue('account.sourceOfIncomeOther', e.target.value)}
                          placeholder='More details on "Other"'
                          description={`${
                            form.getValues().account.sourceOfIncomeOther?.length ?? '0'
                          } of 200 characters`}
                          maxLength={200}
                        />
                      </Box>
                    )}
                  </Stack>
                ))}
              </Stack>
            </Checkbox.Group>
          </Stack>
          <Group justify='space-between' mt='lg'>
            <Button color='dark' onClick={() => navigate(`/onboarding/${invitationCode}/step/4`)}>
              Previous
            </Button>
            <Group>
              <Button name='save' type='submit'>
                Save
              </Button>
              <Button name='saveAndContinue' type='submit'>
                Save and continue
              </Button>
            </Group>
          </Group>
        </form>
      </Paper>
    </Flex>
  )
}

export default FinancialDetails
