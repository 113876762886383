import { useState } from 'react'
import { Button, Container, Paper, TextInput, Text, Flex } from '@mantine/core'
import { useForgotPassword } from '@/api/auth/hooks'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutate: sendForgotPasswordEmail, isLoading } = useForgotPassword()

  const handleForgotPassword = () => {
    setErrorMessage(null)
    setMessage(null)

    if (!email) {
      setErrorMessage('Please enter a valid email address.')
      return
    }

    sendForgotPasswordEmail({ username: email })
  }

  return (
    <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Paper p='xl' shadow='md' w={500} radius='md'>
        <Text size='xl' ta='center' mb='lg'>
          Forgot Password
        </Text>

        <TextInput
          label='Email Address'
          placeholder='Enter your email'
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          mb='md'
          required
        />

        {errorMessage && (
          <Text color='red' size='sm' mb='md'>
            {errorMessage}
          </Text>
        )}

        {message && (
          <Text color='green' size='sm' mb='md'>
            {message}
          </Text>
        )}

        <Flex mt='lg' justify='space-between'>
          <Button fullWidth onClick={handleForgotPassword} disabled={isLoading} loading={isLoading}>
            {isLoading ? 'Sending Email...' : 'Send Reset Link'}
          </Button>
        </Flex>
      </Paper>
    </Container>
  )
}

export default ForgotPassword
