import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { DocumentsValidationFields, OnboardingDocumentFields } from '../step9/Documents'
import ReviewBase from './ReviewBase'
import { Anchor, Grid, Text } from '@mantine/core'
import { GetFormValue } from '@/utils/form'

const ReviewDocuments = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 9 | Documents' step={9} validationFields={DocumentsValidationFields}>
      <Grid>
        {OnboardingDocumentFields.map((f) => {
          const formValue = GetFormValue<{ key: string; fileId: string }[]>(form, f.key)

          const documentData = formValue && formValue[0]
          const displayValue = formValue?.length > 0 ? formValue[0].key.split('/').pop() : '-'

          return (
            <Grid.Col span={6} key={f.key}>
              <Text fz='sm' my='3xs' c='secondary'>
                {f.label}
              </Text>
              {documentData?.fileId ? (
                <Anchor size='sm' href={documentData.fileId} target='_blank'>
                  {displayValue}
                </Anchor>
              ) : (
                <Text fz='md' fw='500' c='textPrimary'>
                  {displayValue}
                </Text>
              )}
            </Grid.Col>
          )
        })}
      </Grid>
    </ReviewBase>
  )
}

export default ReviewDocuments
