const API_URL: string | undefined = process.env.LEIKUR_API

if (!API_URL) {
  throw new Error('API_URL is not defined in the environment variables.')
}

export const forgotPassword = async (username: string): Promise<void> => {
  await fetch(`${API_URL}/api/auth/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: username }),
  })
}

export const resetPassword = async (username: string, newPassword: string, resetToken: string) => {
  await fetch(`${API_URL}/api/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: username, newPassword, token: resetToken }),
  })
}

export const loginUser = async (username: string, password: string): Promise<void> => {
  await fetch(`${API_URL}/api/auth/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username, password }),
    credentials: 'include',
  })
}

export const signInWithGoogleToken = async (token: string): Promise<void> => {
  await fetch(`${API_URL}/api/auth/signin/google`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
    credentials: 'include',
  })
}

export const signOutUser = async (): Promise<void> => {
  await fetch(`${API_URL}/api/auth/signout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
}

export const validateSession = async (): Promise<boolean> => {
  const response = await fetch(`${API_URL}/api/auth/validate-session`, {
    method: 'GET',
    credentials: 'include',
  })

  if (response.status === 200) {
    // Session is valid
    return true
  }

  if (response.status === 401) {
    // Session is invalid
    return false
  }

  // Handle other unexpected responses
  throw new Error('Unexpected error occurred')
}
