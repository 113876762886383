import {
  Group,
  TextInput,
  Select,
  Input,
  SelectProps,
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  useMantineTheme,
} from '@mantine/core'
import { countryCodes } from './CountryCodes'

const renderCountryOption = (renderOptions: ComboboxLikeRenderOptionInput<ComboboxItem>) => {
  const { label, value } = renderOptions.option

  return (
    <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={`https://flagcdn.com/20x15/${value.split('-')[1].toLowerCase()}.png`}
        alt={`${value.split('-')[1]} flag`}
        width='20'
        style={{ marginRight: '8px' }}
      />
      {label}
    </div>
  )
}

export interface SimplePhoneNumberInputProps {
  label?: string
  description?: string
  error?: string
  countryCodeProps?: Partial<SelectProps>
  phoneNumberProps?: any
}

export const SimplePhoneNumberInput = ({
  label,
  description,
  error,
  countryCodeProps,
  phoneNumberProps,
}: SimplePhoneNumberInputProps) => {
  const theme = useMantineTheme()
  const handleCountryCodeChange = (value: string | null, option: ComboboxItem) => {
    if (value) {
      const countryCode = value.split('-')[0]
      if (countryCodeProps?.onChange) {
        countryCodeProps.onChange(countryCode, option)
      }
    }
  }

  return (
    <Input.Wrapper
      labelProps={{ style: { color: theme.colors.secondary[5] } }}
      label={label}
      description={description}
      error={error}
    >
      <Group wrap='nowrap'>
        <Select
          data={countryCodes}
          onChange={handleCountryCodeChange}
          renderOption={renderCountryOption}
          {...countryCodeProps}
          name='country code'
          maxDropdownHeight={150}
          searchable
        />
        <TextInput placeholder='Phone number' {...phoneNumberProps} style={{ flexGrow: 1 }} />
      </Group>
    </Input.Wrapper>
  )
}
