import { createContext, ReactNode, useEffect, useState } from 'react'
import { UseFormReturnType } from '@mantine/form'
import { useGetOnboardingDetails, useSubmitPartialOnboardingDetails } from '@/api/org/hooks'
import { useParams } from 'react-router-dom'
import LoadingOrError from '../../components/loader/LoadingOrError'
import { BusinessOnboardingRequest, PersonRoleType } from '@/api/org/types'
import { Container } from '@mantine/core'
import { useOnboardingFormValidation } from './useOnboardingFormValidation'
import { OnboardingStepId, OnboardingSteps } from '@/routes/onboarding/overview/OnboardingSteps'

interface OnboardingFormContextType {
  form: UseFormReturnType<BusinessOnboardingRequest>
  saveForm: () => void
  fetchForm: () => Promise<void>
  validateFields: (fields: string[]) => boolean
  stepCompletion: Record<OnboardingStepId, boolean> | undefined
  roles: PersonRoleType[]
}

export const OnboardingFormContext = createContext<OnboardingFormContextType | null>(null)

export const OnboardingFormProvider = ({ children }: { children: ReactNode }) => {
  const { invitationCode } = useParams()
  const [formVersion, setFormVersion] = useState<number>(0)
  const [roles, setRoles] = useState<PersonRoleType[]>([])
  const [stepCompletion, setStepCompletion] = useState<Record<OnboardingStepId, boolean>>()

  const {
    data: onboardingDetailsData,
    isSuccess: isOnboardingDetailsSuccess,
    isLoading: isOnboardingDetailsLoading,
    isError: isOnboardingDetailsError,
    error: onboardingDetailsError,
    refetch: refetchOnboardingDetails,
  } = useGetOnboardingDetails(invitationCode ?? '', {
    enabled: !!invitationCode,
  })

  const {
    mutate: submitOnboardingDetails,
    isSuccess: isSubmitOnboardingSuccess,
    isError: isSubmitOnboardingError,
    error: submitOnboardingError,
    data: submitOnboardingDataResponse,
  } = useSubmitPartialOnboardingDetails()

  const form = useOnboardingFormValidation()

  const validateFields = (fields: string[]): boolean => {
    const validationResults = fields.map((field) => form.validateField(field))
    const allFieldsValid = validationResults.every((result) => result.hasError === false)
    return allFieldsValid
  }

  const updateFormCompletion = () => {
    const extractedRolesSet = new Set<PersonRoleType>()
    onboardingDetailsData?.data?.persons?.forEach((person: { roles: PersonRoleType[] }) => {
      person.roles.forEach((role) => {
        extractedRolesSet.add(role)
      })
    })

    const extractedRoles = Array.from(extractedRolesSet) // Convert Set to array
    setRoles(extractedRoles) // Set the roles state

    const steps = OnboardingSteps()
    const updatedStepCompletion = steps.reduce((completionStatus, step) => {
      completionStatus[step.id] = validateFields(step.validationFields)
      return completionStatus
    }, {} as Record<OnboardingStepId, boolean>)

    setStepCompletion(updatedStepCompletion)
    form.clearErrors()
  }

  useEffect(() => {
    if (isSubmitOnboardingSuccess) {
      setFormVersion(submitOnboardingDataResponse!.version)
      updateFormCompletion()
    }
  }, [isSubmitOnboardingSuccess])

  const fetchForm = async () => {
    await refetchOnboardingDetails()
  }

  const saveForm = () => {
    if (invitationCode)
      submitOnboardingDetails({
        invitationCode,
        onboardingDetails: { businessOnboardingRequest: form.getValues(), version: formVersion },
      })
  }

  const retryFormSubmit = async () => {
    const res = await refetchOnboardingDetails()

    if (res.isSuccess && invitationCode) {
      submitOnboardingDetails({
        invitationCode,
        onboardingDetails: { businessOnboardingRequest: form.getValues(), version: res.data!.version },
      })
    }
  }

  useEffect(() => {
    if (isSubmitOnboardingError && submitOnboardingError?.code === 'Org.FormDataIsStale') {
      retryFormSubmit()
    }
  }, [isSubmitOnboardingError, submitOnboardingError])

  const isOnboardingEmpty = isOnboardingDetailsError && onboardingDetailsError.code === 'Org.OnboardingDataNotFound'

  // if the data has been successfully fetched or refetched,
  // then overlay existing edits over the newly fetched data
  // by combining the dirty fields with the fresh data
  useEffect(() => {
    if (isOnboardingDetailsSuccess) {
      const dirtyFieldsStatus = form.getDirty()

      const localUpdates = Object.keys(dirtyFieldsStatus)
        .filter((f) => dirtyFieldsStatus[f])
        .map((k) => ({ dataPath: k, update: form.getInputProps(k).value as unknown }))

      form.setValues(onboardingDetailsData!.data)

      // apply the local changes over the updated ones
      for (const u of localUpdates) {
        form.setFieldValue(u.dataPath, u.update)
      }

      setFormVersion(onboardingDetailsData!.version)
    }

    updateFormCompletion()
  }, [isOnboardingDetailsSuccess, onboardingDetailsData])

  if (isOnboardingDetailsLoading || (isOnboardingDetailsError && !isOnboardingEmpty)) {
    return <LoadingOrError isLoading={isOnboardingDetailsLoading} isError={isOnboardingDetailsError} />
  }

  return (
    <OnboardingFormContext.Provider value={{ form, saveForm, fetchForm, validateFields, roles, stepCompletion }}>
      <Container size='xl' p='xl' style={{ minHeight: 'calc(100vh - 180px)' }}>
        {children}
      </Container>
    </OnboardingFormContext.Provider>
  )
}
