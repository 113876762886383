import { OwnershipType, PersonRoleType, SourceOfWealthType } from '@/api/org/types'
import { IconPlusClear } from '@/components/icons'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { SearchableSelect, usePropsForCountrySelect } from '@/components/inputs/SearchableSelect'
import { colorAliases } from '@/theme/mantineTheme'
import {
  Button,
  Checkbox,
  getThemeColor,
  Group,
  Modal,
  NumberInput,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { FormEvent } from 'react'
import { useRepresentativeModalFormValidation } from './useRepresentativeModalFormValidation'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'

export const SourceOfWealthMapper: Record<SourceOfWealthType, string> = {
  EMPLOYMENT_INCOME: 'Employment income',
  BUSINESS_PROFITS_OR_DIVIDENDS: 'Business profits and dividends',
  SALE_OF_INVESTMENTS_OR_LIQUIDATION_OF_INVESTMENT_PORTIFILIO:
    'Sale of investments or liquidation of investment portfolio',
  SALE_OF_PROPERTY: 'Sale of property',
  LOAN_AND_FINANCING: 'Loan and financing',
  INHERITANCE: 'Inheritance',
  GIFT: 'Gift',
  OTHER: 'Other',
}

export const RepresentativeRoleMapper: Record<PersonRoleType, string> = {
  MAR: '(MAR) Master Authorized Representative',
  UBO: '(UBO) Ultimate Beneficial Owner',
  DIRECTOR: 'Director',
}

export const OwnershipTypeMapper: Record<OwnershipType, string> = {
  PROTECTOR: 'Protector',
  FIDUCIARY: 'Fiduciary',
  TRUSTEE: 'Trustee',
  SETTLOR: 'Settlor',
  BENEFICIARY: 'Beneficiary',
  INDIVIDUAL_WHO_OTHERWISE_EXERCISES_CONTROL: 'Individual who otherwise exercises control',
  DIRECT_OR_INDIRECT_SHAREHOLDER: 'Direct or indirect shareholder',
  OTHER: 'Other',
}

interface IProps {
  opened: boolean
  close: () => void
}

const AddRepresentativeModal = ({ opened, close }: IProps) => {
  const propsForCountrySelect = usePropsForCountrySelect()

  const { form: fullForm, saveForm } = useOnboardingForm()

  const representativeForm = useRepresentativeModalFormValidation()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!representativeForm.validate().hasErrors) {
      fullForm.insertListItem('persons', representativeForm.getValues())
      saveForm()
      close()
    }
  }

  const handleClose = () => {
    representativeForm.reset()
    close()
  }

  return (
    <Modal opened={opened} onClose={handleClose} title='Add a Representative' centered>
      <form onSubmit={handleSubmit}>
        <Stack>
          <Checkbox.Group {...representativeForm.getInputProps('roles')} labelProps={{ c: 'secondary' }} label='Roles'>
            <Stack>
              {(Object.keys(RepresentativeRoleMapper) as PersonRoleType[]).map((k) => (
                <Checkbox
                  c='gray.6'
                  key={k}
                  label={RepresentativeRoleMapper[k]}
                  value={k}
                  checked={representativeForm.getValues().roles.includes(k)}
                />
              ))}
            </Stack>
          </Checkbox.Group>
          {representativeForm.getValues().roles.length > 0 && (
            <>
              <SimpleGrid cols={2}>
                <TextInput
                  label='First name'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Enter first name'
                  {...representativeForm.getInputProps('firstName')}
                />
                <TextInput
                  label='Last name'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Enter last name'
                  {...representativeForm.getInputProps('lastName')}
                />
                <DatePickerInput
                  label='Date of birth'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Pick a date'
                  {...representativeForm.getInputProps('dateOfBirth')}
                  mt='md'
                  value={
                    representativeForm.getValues().dateOfBirth
                      ? new Date(representativeForm.getValues().dateOfBirth)
                      : null
                  }
                  onChange={(e) => {
                    if (e) {
                      const formattedDate = dayjs(e).format('YYYY-MM-DD')
                      representativeForm.setFieldValue('dateOfBirth', formattedDate)
                    } else {
                      representativeForm.setFieldValue('dateOfBirth', '')
                    }
                  }}
                />
                <Select
                  label='Country of incorporation'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Citizenship Country'
                  {...getSelectPropsForCountry(representativeForm.getValues().citizenshipCountryCode)}
                  {...representativeForm.getInputProps('citizenshipCountryCode')}
                  mt='md'
                />
              </SimpleGrid>
              {['LT', 'LV', 'EE'].includes(representativeForm.getValues().citizenshipCountryCode ?? '') && (
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  label='Personal code'
                  {...representativeForm.getInputProps('personalCode')}
                />
              )}
              <Switch
                label='Is (PEP) politically exposed person?'
                defaultValue={false}
                c='secondary'
                {...representativeForm.getInputProps('pep.isPep', { type: 'checkbox' })}
              />
              {representativeForm.getValues().pep.isPep && (
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  label='Please provide (PEP) role/position'
                  {...representativeForm.getInputProps('pep.position')}
                  description={`${representativeForm.getValues().pep.position.length ?? '0'} of 100 characters`}
                  maxLength={100}
                />
              )}
              <Stack>
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  label='Address Line 1'
                  placeholder='21 Diagon Avenue'
                  {...representativeForm.getInputProps('address.addressLine1')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='Address Line 2'
                  label='Address Line 2'
                  {...representativeForm.getInputProps('address.addressLine2')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='Stockholm'
                  label='Town/City'
                  {...representativeForm.getInputProps('address.city')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='103 16'
                  label='Postal/Zip Code'
                  {...representativeForm.getInputProps('address.postcode')}
                />
                <SearchableSelect
                  label='Country'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Sweden'
                  {...propsForCountrySelect}
                  {...representativeForm.getInputProps('address.countryCode')}
                />
              </Stack>
              {representativeForm.getValues().roles.includes('UBO') && (
                <>
                  <TextInput
                    labelProps={{ c: 'secondary' }}
                    label='Tax Identification Number (TIN)'
                    description='If TIN is not available, please explain.'
                    {...representativeForm.getInputProps('taxIdentification.tinCountryOfIncorporation')}
                  />
                  {representativeForm.getValues().taxIdentification.additionalCountries.map((_, index) => (
                    <Stack
                      gap='xl'
                      key={index}
                      px='lg'
                      py='md'
                      sx={(theme) => ({
                        background: getThemeColor(colorAliases.surfaceLight, theme),
                        border: `1px solid ${getThemeColor(colorAliases.surfaceLightgray, theme)}`,
                      })}
                    >
                      <Select
                        labelProps={{ c: 'secondary' }}
                        label='Country of residence for tax purpose'
                        searchable
                        {...getSelectPropsForCountry(
                          representativeForm.getValues().taxIdentification.additionalCountries?.[index].countryCode
                        )}
                        {...representativeForm.getInputProps(
                          `taxIdentification.additionalCountries.${index}.countryCode`
                        )}
                      />
                      <TextInput
                        labelProps={{ c: 'secondary' }}
                        label='Tax Identification Number (TIN)'
                        description='If TIN is not available, please explain.'
                        descriptionProps={{ c: 'dark', pt: '0.25rem' }}
                        {...representativeForm.getInputProps(`taxIdentification.additionalCountries.${index}.tin`)}
                      />
                      <Button
                        bg='negative'
                        c='error'
                        onClick={() =>
                          representativeForm.removeListItem('taxIdentification.additionalCountries', index)
                        }
                        sx={{ justifySelf: 'end' }}
                      >
                        Remove
                      </Button>
                    </Stack>
                  ))}
                  {representativeForm.getValues().taxIdentification?.additionalCountries?.length === undefined ||
                    (representativeForm.getValues().taxIdentification.additionalCountries.length < 2 && (
                      <Button
                        color='secondary'
                        h='2.6rem'
                        size='xs'
                        w='100%'
                        onClick={() =>
                          representativeForm.insertListItem('taxIdentification.additionalCountries', {
                            countryCode: null,
                            tin: '',
                          })
                        }
                        leftSection={<IconPlusClear style={{ color: 'white', height: '1rem' }} />}
                      >
                        Add another tax residency country
                      </Button>
                    ))}
                </>
              )}
              {representativeForm.getValues().roles.includes('UBO') && (
                <>
                  <Select
                    labelProps={{ c: 'secondary' }}
                    data={(Object.keys(OwnershipTypeMapper) as OwnershipType[]).map((k) => ({
                      value: k,
                      label: OwnershipTypeMapper[k],
                    }))}
                    label='Ownership type'
                    {...representativeForm.getInputProps('ownership.type')}
                  />
                  {representativeForm.getValues().ownership.type?.includes('OTHER') && (
                    <TextInput
                      labelProps={{ c: 'secondary' }}
                      label='Please provide details'
                      {...representativeForm.getInputProps('ownership.typeOther')}
                      description={`${
                        representativeForm.getValues().ownership.typeOther?.length ?? '0'
                      } of 500 characters`}
                      maxLength={500}
                    />
                  )}
                  <Group align='center'>
                    <NumberInput
                      min={1}
                      max={100}
                      label='Share size %'
                      labelProps={{ c: 'secondary' }}
                      {...representativeForm.getInputProps('ownership.shareSize')}
                    />

                    <Switch
                      label='Has voting control'
                      c='secondary'
                      {...representativeForm.getInputProps('ownership.hasVotingControl', { type: 'checkbox' })}
                    />
                  </Group>
                  {representativeForm.getValues().roles.includes('UBO') &&
                    representativeForm.getValues().ownership.hasVotingControl && (
                      <TextInput
                        labelProps={{ c: 'secondary' }}
                        label='Please provide voting control details'
                        {...representativeForm.getInputProps('ownership.hasVotingControlAdditionalInformation')}
                        description={`${
                          representativeForm.getValues().ownership?.hasVotingControlAdditionalInformation?.length ?? '0'
                        } of 500 characters`}
                        maxLength={500}
                      />
                    )}
                  <Select
                    labelProps={{ c: 'secondary' }}
                    data={(Object.keys(SourceOfWealthMapper) as SourceOfWealthType[]).map((k) => ({
                      value: k,
                      label: SourceOfWealthMapper[k],
                    }))}
                    label='Ubo Source of wealth'
                    {...representativeForm.getInputProps('uboSourceOfWealth')}
                  />
                  {representativeForm.getValues().uboSourceOfWealth === 'OTHER' && (
                    <TextInput
                      labelProps={{ c: 'secondary' }}
                      label='Please provide details'
                      {...representativeForm.getInputProps('uboSourceOfWealthOther')}
                      description={`${
                        representativeForm.getValues().uboSourceOfWealthOther?.length ?? '0'
                      } of 500 characters`}
                      maxLength={500}
                    />
                  )}
                </>
              )}
              {representativeForm.getValues().roles.includes('DIRECTOR') && (
                <Switch
                  label='Is the director an executive director'
                  c='secondary'
                  {...representativeForm.getInputProps('isDirectorExecutive', { type: 'checkbox' })}
                />
              )}
            </>
          )}
          <Group>
            <Button onClick={close}>Cancel</Button>
            <div style={{ flexGrow: 1 }} />
            <Button type='submit'>Add</Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default AddRepresentativeModal
