import { IconCrossCircle, IconEditModifyPen, IconTickCircle } from '@/components/icons'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { colorAliases } from '@/theme/mantineTheme'
import { Box, Button, Divider, getThemeColor, Group, Paper, Stack, Title, useMantineTheme } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface IReviewBaseProps {
  title: string
  step: number
  validationFields: string[]
  children: React.ReactNode
}

const ReviewBase = ({ title, step, validationFields, children }: IReviewBaseProps) => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { validateFields } = useOnboardingForm()
  const [areFieldsValid, setAreFieldsValid] = useState(false)
  const { invitationCode } = useParams()

  // necessary to call this in a useEffect
  // which runs after the component has been rendered and mounted
  // and not to call validateFields(...) during the render, changing
  // state within the OnboardingFormContext
  useEffect(() => {
    setAreFieldsValid(validateFields(validationFields))
  }, [])

  const navigateToEditDetails = () => navigate(`/onboarding/${invitationCode}/step/${step}`)

  return (
    <Paper
      shadow='xs'
      radius='xs'
      p={{ base: '3xs', desktop: 'lg' }}
      sx={{
        backgroundColor: getThemeColor('light.3', theme),
        borderRightWidth: '4pt',
        borderRightStyle: 'solid',
        borderColor: areFieldsValid ? 'var(--mantine-color-positive-6)' : 'var(--mantine-color-negative-3)',
      }}
    >
      <Group>
        {areFieldsValid ? (
          <IconTickCircle
            style={{
              color: `${getThemeColor(colorAliases.systemSuccess, theme)}`,
              height: '1.5em',
            }}
          />
        ) : (
          <IconCrossCircle
            style={{
              color: `${getThemeColor(colorAliases.systemError, theme)}`,
              height: '1.5em',
            }}
          />
        )}
        <Title order={3} size='h4' fw='bold' fz='md' c='primary' display='inline'>
          {title}
        </Title>
        <div style={{ flexGrow: 1 }} />
        <Button
          variant='outline'
          color='primary'
          size='xs'
          leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
          onClick={navigateToEditDetails}
        >
          Edit Details
        </Button>
      </Group>
      {children}
      {!areFieldsValid && (
        <Stack>
          <Divider my='xl' />
          <Box c='error' fw='bold' mb='md'>
            Mandatory information is missing!
          </Box>
          <Box>
            Please select &quot;Edit details&quot; and fill in the missing information.
            <br />
            <Button
              mt='sm'
              variant='outline'
              color='primary'
              size='xs'
              leftSection={<IconEditModifyPen style={{ width: '1em' }} />}
              onClick={navigateToEditDetails}
            >
              Edit Details
            </Button>
          </Box>
        </Stack>
      )}
    </Paper>
  )
}

export default ReviewBase
