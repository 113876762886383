import { fetchWithErrorHandling } from '@/api/apiBase'
import { BusinessDetailsRequest, BusinessDetailsResponse } from './types'
import {
  DeleteOnboardingDocumentRequest,
  OnboardingInfoResponse,
  SubmitOnboardingInfoRequest,
  UploadOnboardingDocumentRequest,
} from './interface'

const API_URL: string | undefined = process.env.LEIKUR_API

if (!API_URL) {
  throw new Error('API_URL is not defined in the environment variables.')
}

export const validateOrgDetails = async (invitationCode: string): Promise<BusinessDetailsResponse | null> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }
  return await fetchWithErrorHandling<BusinessDetailsResponse>(
    `${API_URL}/api/org/onboarding/invite/validate?invitationCode=${invitationCode}`
  )
}

export const submitOrgSignUp = async (
  invitationCode: string,
  request: BusinessDetailsRequest
): Promise<BusinessDetailsResponse | null> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }

  if (!request) {
    throw new Error('Request body is required.')
  }

  return await fetchWithErrorHandling<BusinessDetailsResponse>(`${API_URL}/api/org/signup/${invitationCode}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
}

export const getOnboardingDetails = async (invitationCode: string): Promise<OnboardingInfoResponse | null> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }

  return await fetchWithErrorHandling<OnboardingInfoResponse>(`${API_URL}/api/org/onboarding/${invitationCode}`)
}

export const submitPartialOnboardingDetails = async (
  invitationCode: string,
  onboardingDetails: SubmitOnboardingInfoRequest
): Promise<OnboardingInfoResponse | null> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }

  if (!onboardingDetails) {
    throw new Error('Onboarding details are required.')
  }

  return await fetchWithErrorHandling<OnboardingInfoResponse>(`${API_URL}/api/org/onboarding/${invitationCode}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(onboardingDetails),
  })
}

export const uploadOnboardingDocument = async (
  invitationCode: string,
  request: UploadOnboardingDocumentRequest
): Promise<void> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }

  if (!request) {
    throw new Error('Request body is required.')
  }

  const formData = new FormData()
  formData.append('file', request.file)
  formData.append('businessOnboardingDocumentType', request.businessOnboardingDocumentType)

  await fetchWithErrorHandling(`${API_URL}/api/org/onboarding/${invitationCode}/document/upload`, {
    method: 'POST',
    body: formData,
  })
}

export const deleteOnboardingDocument = async (
  invitationCode: string,
  request: DeleteOnboardingDocumentRequest
): Promise<void> => {
  if (!invitationCode) {
    throw new Error('Invitation code is required.')
  }

  if (!request) {
    throw new Error('Request body is required.')
  }

  await fetchWithErrorHandling(`${API_URL}/api/org/onboarding/${invitationCode}/document`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(request),
  })
}
