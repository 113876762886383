import {
  AnnualGrossTurnoverType,
  BusinessOnboardingRequest,
  MonthlyGrossTurnoverType,
  MonthlyTransactionVolumeType,
  RequestedProductType,
  SourceOfIncomeType,
} from '@/api/org/types'
import { useForm } from '@mantine/form'

export const useOnboardingFormValidation = () => {
  const form = useForm<BusinessOnboardingRequest>({
    mode: 'uncontrolled',
    initialValues: {
      company: {
        externalReference: '',
        name: '',
        dateOfIncorporation: '',
        registrationNumber: '',
        countryOfIncorporation: undefined,
        businessActivityDescription: '',
        businessActivityIndustry: undefined,
        businessActivitySubIndustry: undefined,
        registeredOfficeAddress: {
          addressLine1: '',
          addressLine2: '',
          postcode: '',
          city: '',
          countryCode: undefined,
        },
        headquartersOfficeAddress: {
          addressLine1: '',
          addressLine2: '',
          postcode: '',
          city: '',
          countryCode: undefined,
        },
        email: '',
        phoneNumber: {
          countryCode: '',
          number: '',
        },
        website: '',
        companyType: undefined,
        companyTypeOther: '',
        isSubsidiary: undefined,
        group: {
          stockExchange: {
            listedSubsidiaries: [],
          },
          governmentalLicense: {
            licensedSubsidiaries: [],
          },
        },
        governmentalLicense: {
          isLicensed: undefined,
          licensePublicSource: '',
          fileId: '',
        },
        stockExchange: {
          isListed: undefined,
          isinCode: '',
        },
        shareholders: {
          isOwnedByNominee: undefined,
          nominee: {
            fullName: '',
            regulatingCountryCode: undefined,
          },
        },
        numberOfEmployees: undefined,
        crsFatcaType: undefined,
        taxIdentification: {
          tinCountryOfIncorporation: '',
          additionalCountries: [],
        },
        beneficialOwnershipDocument: [],
        extractFromCompanyRegistry: [],
        uboStructureChart: [],
        memorandumArticleOfAssociation: [],
        amlFraudProcedures: [],
        certificateIncorporation: [],
        certificateIncumbency: [],
        purposeOfAccount: [],
        businessActivityDocuments: [],
        moneyFlowChart: [],
        companyGroupChart: [],
      },
      account: {
        purposesOfAccount: [],
        purposesOfAccountOther: '',
        purposesOfAccountAdditionalInformation: {
          serviceProviders: [],
          clients: [],
          receiveFundsOnBehalfOfOthers: '',
        },
        receiveFunds: {
          countries: [],
        },
        sendFunds: {
          countries: [],
        },
        sourceOfIncome: [],
        sourceOfIncomeOther: '',
        segregatedAccountDocument: [],
        monthlyGrossTurnover: undefined,
        annualGrossTurnover: undefined,
        monthlyTransactionVolume: undefined,
        limits: {
          daily: 0,
          monthly: 0,
        },
        requestedProducts: [],
      },
      persons: [],
      additionalDocuments: [],
      hasAcceptedPrivacyPolicy: false,
    },
    validate: {
      company: {
        name: (v: string) => (v?.length > 2 ? null : 'Company name must be at least 3 characters'),
        dateOfIncorporation: (v) =>
          /^\d{4}-\d{2}-\d{2}$/.test(v.toString()) ? null : 'Invalid date format (YYYY-MM-DD)',
        registeredOfficeAddress: {
          addressLine1: (v) => (v ? null : 'Address Line 1 is required'),
          postcode: (v) => (v ? null : 'Postal code is required'),
          city: (v) => (v ? null : 'City is required'),
          countryCode: (v) => (v ? null : 'Country code is required'),
        },
        headquartersOfficeAddress: {
          addressLine1: (v) => (v ? null : 'Address Line 1 is required'),
          postcode: (v) => (v ? null : 'Postal code is required'),
          city: (v) => (v ? null : 'City is required'),
          countryCode: (v) => (v ? null : 'Country code is required'),
        },
        registrationNumber: (v) => (v ? null : 'Registration number is required'),
        companyType: (value) => {
          return !value ? 'Please select the company type' : null
        },
        email: (v) => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(v) ? null : 'Invalid email address'),
        phoneNumber: {
          countryCode: (v) => (v?.length > 0 ? null : 'Country code is required'),
          number: (v) => (v?.length > 0 ? null : 'Phone number is required'),
        },
        businessActivityDescription: (value) =>
          !value || value?.length === 0
            ? 'Please enter a description.'
            : value?.length < 50
            ? 'Description is too short.'
            : null,
        businessActivityIndustry: (value) =>
          value === undefined ? 'Please select the industry of your business' : null,
        businessActivitySubIndustry: (value) =>
          value === undefined ? 'Please select the industry sub-type of your business' : null,
        website: (v) => (v.startsWith('http') ? null : 'Website must be a valid URL'),
        numberOfEmployees: (value) => (value === undefined ? 'Please select a valid number of employees' : null),
        group: {
          stockExchange: {
            listedSubsidiaries: {
              isinCode: (value) => (value === undefined || value === '' ? 'Please specify the ISIN code' : null),
              legalName: (value) => (value === undefined || value === '' ? 'Please specify the legal name' : null),
            },
          },
          governmentalLicense: {
            licensedSubsidiaries: {
              licensePublicSource: (value) =>
                value === undefined || value === '' ? 'Please specify the public source' : null,
              legalName: (value) => (value === undefined || value === '' ? 'Please specify the legal name' : null),
            },
          },
        },
        shareholders: {
          isOwnedByNominee: (value) => {
            return value === undefined ? 'Please select whether the company is owned by a nominee shareholder' : null
          },
          nominee: {
            fullName: (value, values) =>
              values?.company?.shareholders?.isOwnedByNominee && (value === undefined || value === '')
                ? 'Please provide the full name of the nominee shareholder'
                : null,
            regulatingCountryCode: (value, values) =>
              values?.company?.shareholders?.isOwnedByNominee && !value
                ? 'Please select the regulating country for the shareholder'
                : null,
          },
        },
        governmentalLicense: {
          isLicensed: (value) => (value === undefined ? 'Please select whether the company is licensed' : null),
          licensePublicSource: (value, values) =>
            values.company?.governmentalLicense?.isLicensed && (value === undefined || value === '')
              ? 'Please provide the public source where the license can be validated'
              : null,
        },
        stockExchange: {
          isListed: (value) =>
            value === undefined ? 'Please select whether the company is listed on any stock exchange' : null,
          isinCode: (value, values) =>
            values.company?.stockExchange?.isListed && (value === undefined || value === '')
              ? 'Please provide the ISIN code for the stock exchange listing'
              : null,
        },
        taxIdentification: {
          tinCountryOfIncorporation: (v) => (v?.length ? null : 'Please fill this field.'),
          additionalCountries: {
            countryCode: (v) => (v === undefined || v === null ? 'Please specify the residency country.' : null),
            tin: (v) => (v === undefined || v === '' ? 'Please fill this field.' : null),
          },
        },
        crsFatcaType: (v) => (v === undefined ? 'Please select an option.' : null),
        beneficialOwnershipDocument: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        extractFromCompanyRegistry: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        uboStructureChart: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        memorandumArticleOfAssociation: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        amlFraudProcedures: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        certificateIncorporation: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        certificateIncumbency: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        purposeOfAccount: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        businessActivityDocuments: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        moneyFlowChart: (v) => (v?.length > 0 ? null : 'Please upload a document'),
        companyGroupChart: (v) => (v?.length > 0 ? null : 'Please upload a document'),
      },
      account: {
        purposesOfAccount: (value) => (value?.length > 0 ? null : 'At least one purpose of account is required'),
        purposesOfAccountAdditionalInformation: {
          serviceProviders: {
            locationCountryCode: (v) => (v === undefined || v === null ? 'Please specify the country' : null),
            name: (v) => (v && v?.length > 0 ? null : 'Please provide a name'),
          },
          clients: {
            locationCountryCode: (v) => (v === undefined || v === null ? 'Please specify the country' : null),
            name: (v) => (v && v?.length > 0 ? null : 'Please provide a name'),
          },
          receiveFundsOnBehalfOfOthers: (v, values) => {
            if (
              values.account?.purposesOfAccount?.includes('RECEIVING_FUNDS_ON_BEHALF_OF_OTHER_COMPANIES_OR_PERSONS')
            ) {
              return v === undefined || v === '' ? 'Please enter a company or person name' : null
            }
          },
        },
        purposesOfAccountOther: (v, values) => {
          if (values.account?.purposesOfAccount?.includes('OTHER')) {
            return v === undefined || v === '' ? 'Please describe the purpose of the account' : null
          }
        },
        monthlyGrossTurnover: (value?: MonthlyGrossTurnoverType) =>
          value === undefined || value === null || Number(value) <= 0
            ? 'Monthly gross turnover must be greater than zero'
            : null,

        annualGrossTurnover: (value?: AnnualGrossTurnoverType) =>
          value === undefined || value === null || Number(value) <= 0
            ? 'Annual gross turnover must be greater than zero'
            : null,
        monthlyTransactionVolume: (value?: MonthlyTransactionVolumeType) =>
          value === undefined || value === null || Number(value) <= 0
            ? 'Monthly transaction volume must be greater than zero'
            : null,
        limits: {
          daily: (value?: number) =>
            value === undefined || value === null || value < 1 ? 'Daily limit must be 0 or greater' : null,

          monthly: (value?: number) =>
            value === undefined || value === null || value < 1 ? 'Monthly limit must be 0 or greater' : null,
        },
        requestedProducts: (products: RequestedProductType[]) =>
          products?.length === 0 ? 'At least one requested product must be selected' : null,
        sourceOfIncome: (incomeSources: SourceOfIncomeType[]) =>
          incomeSources?.length === 0 ? 'At least one source of income must be selected' : null,
        sourceOfIncomeOther: (v, values) => {
          if (values.account?.sourceOfIncome?.includes('OTHER')) {
            return v === undefined || v === '' ? 'Please provide more details for "Other"' : null
          }
          return null
        },
        receiveFunds: {
          countries: (value) =>
            value === undefined || value.length === 0
              ? 'Please select at least one country to receive funds from'
              : null,
        },
        sendFunds: {
          countries: (value) =>
            value === undefined || value.length === 0 ? 'Please select at least one country to send funds to' : null,
        },
      },
      persons: (values) => {
        const isMarUboDirectorAssigned = new Set(values.flatMap((v) => v.roles)).size === 3

        if (!isMarUboDirectorAssigned) {
          return 'At least one MAR, UBO and Director is required'
        }
      },
      hasAcceptedPrivacyPolicy: (value) => (value ? null : 'You must accept the privacy policy'),
    },
  })

  return form
}
