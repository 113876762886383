import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Stack, Text } from '@mantine/core'
import {
  BusinessActivityIndustryMapper,
  BusinessActivitySubIndustryMapper,
  BusinessActivityValidationFields,
} from '../step7/BusinessActivity'
import ReviewBase from './ReviewBase'

const ReviewBusinessActivity = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 7 | Business Activity' step={7} validationFields={BusinessActivityValidationFields}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Business description
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().company.businessActivityDescription.length
            ? form.getValues().company.businessActivityDescription
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Industry type
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {(form.getValues().company.businessActivityIndustry &&
            BusinessActivityIndustryMapper[form.getValues().company.businessActivityIndustry!]) ||
            '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Industry sub type
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {(form.getValues().company.businessActivitySubIndustry &&
            BusinessActivitySubIndustryMapper[form.getValues().company.businessActivitySubIndustry!]) ||
            '-'}
        </Text>
      </Stack>
    </ReviewBase>
  )
}

export default ReviewBusinessActivity
