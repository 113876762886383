import { useQuery, useMutation } from '@tanstack/react-query'
import {
  forgotPassword,
  loginUser,
  resetPassword,
  signInWithGoogleToken,
  signOutUser,
  validateSession,
} from '@/api/auth/service'

export const useSignIn = () => {
  return useMutation<void, Error, { username: string; password: string }>(
    ({ username, password }) => loginUser(username, password),
    {
      onSuccess: () => {
        console.log('Successfully signed in')
      },
      onError: (error: Error) => {
        console.error('Login error:', error)
      },
    }
  )
}

export const useSignInWithGoogle = () => {
  return useMutation<void, Error, { token: string }>(({ token }) => signInWithGoogleToken(token), {
    onSuccess: () => {
      console.log('Successfully signed in with Google')
    },
    onError: (error: Error) => {
      console.error('Google Sign-In error:', error)
    },
  })
}

export const useSignOut = () => {
  return useMutation<void, Error>(() => signOutUser(), {
    onSuccess: () => {
      console.log('Successfully signed out')
    },
    onError: (error: Error) => {
      console.error('Sign out error:', error)
    },
  })
}

export const useValidateSession = () => {
  return useQuery<boolean>(['validateSession'], validateSession, {
    retry: false,
    onError: (error: unknown) => {
      if (error instanceof Error) {
        console.error('Session validation error:', error.message)
      } else {
        console.error('Unexpected session validation error:', error)
      }
    },
  })
}

export const useForgotPassword = () => {
  return useMutation<void, Error, { username: string }>(({ username }) => forgotPassword(username), {
    onSuccess: () => {
      console.log('Reset link has been sent.')
    },
    onError: (error: Error) => {
      console.error('Forgot password error:', error)
    },
  })
}

export const useResetPassword = () => {
  return useMutation<void, Error, { username: string; newPassword: string; resetToken: string }>(
    ({ username, newPassword, resetToken }) => resetPassword(username, newPassword, resetToken),
    {
      onSuccess: () => {
        console.log('Password successfully rest.')
      },
      onError: (error: Error) => {
        console.error('Reset password error:', error)
      },
    }
  )
}
