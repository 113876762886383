export const countryCodes = [
  {
    group: 'Africa',
    items: [
      { value: '+213-DZ', label: '+213 Algeria' },
      { value: '+244-AO', label: '+244 Angola' },
      { value: '+229-BJ', label: '+229 Benin' },
      { value: '+267-BW', label: '+267 Botswana' },
      { value: '+226-BF', label: '+226 Burkina Faso' },
      { value: '+257-BI', label: '+257 Burundi' },
      { value: '+237-CM', label: '+237 Cameroon' },
      { value: '+238-CV', label: '+238 Cabo Verde' },
      { value: '+236-CF', label: '+236 Central African Republic' },
      { value: '+235-TD', label: '+235 Chad' },
      { value: '+269-KM', label: '+269 Comoros' },
      { value: '+243-CD', label: '+243 Congo (Democratic Republic)' },
      { value: '+242-CG', label: '+242 Congo' },
      { value: '+225-CI', label: '+225 Côte d’Ivoire' },
      { value: '+253-DJ', label: '+253 Djibouti' },
      { value: '+20-EG', label: '+20 Egypt' },
      { value: '+240-GQ', label: '+240 Equatorial Guinea' },
      { value: '+291-ER', label: '+291 Eritrea' },
      { value: '+268-SZ', label: '+268 Eswatini' },
      { value: '+251-ET', label: '+251 Ethiopia' },
      { value: '+241-GA', label: '+241 Gabon' },
      { value: '+220-GM', label: '+220 Gambia' },
      { value: '+233-GH', label: '+233 Ghana' },
      { value: '+224-GN', label: '+224 Guinea' },
      { value: '+245-GW', label: '+245 Guinea-Bissau' },
      { value: '+254-KE', label: '+254 Kenya' },
      { value: '+266-LS', label: '+266 Lesotho' },
      { value: '+231-LR', label: '+231 Liberia' },
      { value: '+218-LY', label: '+218 Libya' },
      { value: '+261-MG', label: '+261 Madagascar' },
      { value: '+265-MW', label: '+265 Malawi' },
      { value: '+223-ML', label: '+223 Mali' },
      { value: '+222-MR', label: '+222 Mauritania' },
      { value: '+230-MU', label: '+230 Mauritius' },
      { value: '+212-MA', label: '+212 Morocco' },
      { value: '+258-MZ', label: '+258 Mozambique' },
      { value: '+264-NA', label: '+264 Namibia' },
      { value: '+227-NE', label: '+227 Niger' },
      { value: '+234-NG', label: '+234 Nigeria' },
      { value: '+250-RW', label: '+250 Rwanda' },
      { value: '+290-SH', label: '+290 Saint Helena' },
      { value: '+221-SN', label: '+221 Senegal' },
      { value: '+248-SC', label: '+248 Seychelles' },
      { value: '+232-SL', label: '+232 Sierra Leone' },
      { value: '+27-ZA', label: '+27 South Africa' },
      { value: '+211-SS', label: '+211 South Sudan' },
      { value: '+249-SD', label: '+249 Sudan' },
      { value: '+255-TZ', label: '+255 Tanzania' },
      { value: '+228-TG', label: '+228 Togo' },
      { value: '+216-TN', label: '+216 Tunisia' },
      { value: '+256-UG', label: '+256 Uganda' },
      { value: '+260-ZM', label: '+260 Zambia' },
      { value: '+263-ZW', label: '+263 Zimbabwe' },
    ],
  },
  {
    group: 'Asia',
    items: [
      { value: '+93-AF', label: '+93 Afghanistan' },
      { value: '+374-AM', label: '+374 Armenia' },
      { value: '+994-AZ', label: '+994 Azerbaijan' },
      { value: '+973-BH', label: '+973 Bahrain' },
      { value: '+880-BD', label: '+880 Bangladesh' },
      { value: '+975-BT', label: '+975 Bhutan' },
      { value: '+673-BN', label: '+673 Brunei Darussalam' },
      { value: '+86-CN', label: '+86 China' },
      { value: '+91-IN', label: '+91 India' },
      { value: '+62-ID', label: '+62 Indonesia' },
      { value: '+98-IR', label: '+98 Iran' },
      { value: '+964-IQ', label: '+964 Iraq' },
      { value: '+972-IL', label: '+972 Israel' },
      { value: '+81-JP', label: '+81 Japan' },
      { value: '+962-JO', label: '+962 Jordan' },
      { value: '+7-KZ', label: '+7 Kazakhstan' },
      { value: '+965-KW', label: '+965 Kuwait' },
      { value: '+996-KG', label: '+996 Kyrgyzstan' },
      { value: '+856-LA', label: '+856 Laos' },
      { value: '+961-LB', label: '+961 Lebanon' },
      { value: '+960-MV', label: '+960 Maldives' },
      { value: '+976-MN', label: '+976 Mongolia' },
      { value: '+95-MM', label: '+95 Myanmar' },
      { value: '+977-NP', label: '+977 Nepal' },
      { value: '+92-PK', label: '+92 Pakistan' },
      { value: '+970-PS', label: '+970 Palestine' },
      { value: '+63-PH', label: '+63 Philippines' },
      { value: '+974-QA', label: '+974 Qatar' },
      { value: '+966-SA', label: '+966 Saudi Arabia' },
      { value: '+65-SG', label: '+65 Singapore' },
      { value: '+82-KR', label: '+82 South Korea' },
      { value: '+963-SY', label: '+963 Syrian Arab Republic' },
      { value: '+886-TW', label: '+886 Taiwan' },
      { value: '+992-TJ', label: '+992 Tajikistan' },
      { value: '+66-TH', label: '+66 Thailand' },
      { value: '+670-TL', label: '+670 Timor-Leste' },
      { value: '+90-TR', label: '+90 Turkey' },
      { value: '+993-TM', label: '+993 Turkmenistan' },
      { value: '+971-AE', label: '+971 United Arab Emirates' },
      { value: '+998-UZ', label: '+998 Uzbekistan' },
      { value: '+84-VN', label: '+84 Vietnam' },
      { value: '+967-YE', label: '+967 Yemen' },
    ],
  },
  {
    group: 'Europe',
    items: [
      { value: '+355-AL', label: '+355 Albania' },
      { value: '+43-AT', label: '+43 Austria' },
      { value: '+375-BY', label: '+375 Belarus' },
      { value: '+32-BE', label: '+32 Belgium' },
      { value: '+387-BA', label: '+387 Bosnia and Herzegovina' },
      { value: '+359-BG', label: '+359 Bulgaria' },
      { value: '+385-HR', label: '+385 Croatia' },
      { value: '+357-CY', label: '+357 Cyprus' },
      { value: '+420-CZ', label: '+420 Czechia' },
      { value: '+45-DK', label: '+45 Denmark' },
      { value: '+372-EE', label: '+372 Estonia' },
      { value: '+358-FI', label: '+358 Finland' },
      { value: '+33-FR', label: '+33 France' },
      { value: '+49-DE', label: '+49 Germany' },
      { value: '+30-GR', label: '+30 Greece' },
      { value: '+36-HU', label: '+36 Hungary' },
      { value: '+354-IS', label: '+354 Iceland' },
      { value: '+353-IE', label: '+353 Ireland' },
      { value: '+39-IT', label: '+39 Italy' },
      { value: '+371-LV', label: '+371 Latvia' },
      { value: '+370-LT', label: '+370 Lithuania' },
      { value: '+352-LU', label: '+352 Luxembourg' },
      { value: '+389-MK', label: '+389 Macedonia' },
      { value: '+356-MT', label: '+356 Malta' },
      { value: '+373-MD', label: '+373 Moldova' },
      { value: '+382-ME', label: '+382 Montenegro' },
      { value: '+31-NL', label: '+31 Netherlands' },
      { value: '+47-NO', label: '+47 Norway' },
      { value: '+48-PL', label: '+48 Poland' },
      { value: '+351-PT', label: '+351 Portugal' },
      { value: '+40-RO', label: '+40 Romania' },
      { value: '+7-RU', label: '+7 Russia' },
      { value: '+381-RS', label: '+381 Serbia' },
      { value: '+421-SK', label: '+421 Slovakia' },
      { value: '+386-SI', label: '+386 Slovenia' },
      { value: '+34-ES', label: '+34 Spain' },
      { value: '+46-SE', label: '+46 Sweden' },
      { value: '+41-CH', label: '+41 Switzerland' },
      { value: '+44-GB', label: '+44 United Kingdom' },
    ],
  },
  {
    group: 'North America',
    items: [
      { value: '+1-AS', label: '+1 American Samoa' },
      { value: '+1-AG', label: '+1 Antigua and Barbuda' },
      { value: '+1-BS', label: '+1 Bahamas' },
      { value: '+1-BB', label: '+1 Barbados' },
      { value: '+1-BM', label: '+1 Bermuda' },
      { value: '+1-CA', label: '+1 Canada' },
      { value: '+1-KY', label: '+1 Cayman Islands' },
      { value: '+1-DM', label: '+1 Dominica' },
      { value: '+1-DO', label: '+1 Dominican Republic' },
      { value: '+1-GD', label: '+1 Grenada' },
      { value: '+1-GU', label: '+1 Guam' },
      { value: '+1-JM', label: '+1 Jamaica' },
      { value: '+1-PR', label: '+1 Puerto Rico' },
      { value: '+1-VC', label: '+1 Saint Vincent and the Grenadines' },
      { value: '+1-TT', label: '+1 Trinidad and Tobago' },
      { value: '+1-US', label: '+1 United States' },
      { value: '+1-VI', label: '+1 U.S. Virgin Islands' },
    ],
  },
  {
    group: 'South America',
    items: [
      { value: '+54-AR', label: '+54 Argentina' },
      { value: '+591-BO', label: '+591 Bolivia' },
      { value: '+55-BR', label: '+55 Brazil' },
      { value: '+56-CL', label: '+56 Chile' },
      { value: '+57-CO', label: '+57 Colombia' },
      { value: '+593-EC', label: '+593 Ecuador' },
      { value: '+500-FK', label: '+500 Falkland Islands' },
      { value: '+595-PY', label: '+595 Paraguay' },
      { value: '+51-PE', label: '+51 Peru' },
      { value: '+598-UY', label: '+598 Uruguay' },
      { value: '+58-VE', label: '+58 Venezuela' },
    ],
  },
  {
    group: 'Oceania',
    items: [
      { value: '+61-AU', label: '+61 Australia' },
      { value: '+672-NF', label: '+672 Norfolk Island' },
      { value: '+674-NR', label: '+674 Nauru' },
      { value: '+683-NU', label: '+683 Niue' },
      { value: '+687-NC', label: '+687 New Caledonia' },
      { value: '+64-NZ', label: '+64 New Zealand' },
      { value: '+680-PW', label: '+680 Palau' },
      { value: '+675-PG', label: '+675 Papua New Guinea' },
      { value: '+677-SB', label: '+677 Solomon Islands' },
      { value: '+676-TO', label: '+676 Tonga' },
      { value: '+688-TV', label: '+688 Tuvalu' },
      { value: '+678-VU', label: '+678 Vanuatu' },
      { value: '+681-WF', label: '+681 Wallis and Futuna' },
    ],
  },
]
