import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Container, Paper, PasswordInput, Text, Flex } from '@mantine/core'
import { useResetPassword } from '@/api/auth/hooks'

// Password complexity validation function
const validatePasswordComplexity = (password: string) => {
  const hasMinLength = password.length >= 12
  const hasDigit = /\d/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasUppercase = /[A-Z]/.test(password)
  const hasNonAlphanumeric = /[^a-zA-Z0-9]/.test(password)
  const uniqueChars = new Set(password).size >= 4 // At least 4 unique characters

  return hasMinLength && hasDigit && hasLowercase && hasUppercase && hasNonAlphanumeric && uniqueChars
}

const ResetPassword = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  // Extract the token and email from the query parameters
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')
  const email = queryParams.get('email')

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword() // Replace with your API call

  useEffect(() => {
    if (isSuccess) {
      setSuccessMessage('Password reset successful. You will be redirected shortly.')
      setTimeout(() => navigate('/sign-in'), 3000)
    }
  }, [isSuccess, navigate])

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.')
      return
    }

    if (!validatePasswordComplexity(password)) {
      setErrorMessage(
        'Password must be at least 12 characters long, contain at least 1 digit, 1 lowercase letter, 1 uppercase letter, 1 non-alphanumeric character, and at least 4 unique characters.'
      )
      return
    }

    if (token && email) {
      resetPassword({ resetToken: token, username: email, newPassword: password })
    } else {
      setErrorMessage('Invalid password reset link.')
    }
  }

  return (
    <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <Paper p='xl' shadow='md' radius='md' w={500}>
        <Text size='xl' ta='center' mb='lg'>
          Reset Your Password
        </Text>

        <PasswordInput
          label='New Password'
          placeholder='Enter your new password'
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          mb='md'
          required
        />

        <PasswordInput
          label='Confirm New Password'
          placeholder='Re-enter your new password'
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          mb='md'
          required
        />

        {errorMessage && (
          <Text color='red' size='sm'>
            {errorMessage}
          </Text>
        )}

        {isSuccess && (
          <Text color='green' size='sm'>
            {successMessage}
          </Text>
        )}

        <Flex mt='lg' justify='space-between'>
          <Button fullWidth onClick={handleResetPassword} disabled={isLoading} loading={isLoading}>
            Reset Password
          </Button>
        </Flex>
      </Paper>
    </Container>
  )
}

export default ResetPassword
