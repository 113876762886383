import { IconArrowRight } from '@/components/icons'
import { Anchor, Box, Breadcrumbs, Stack } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import ReviewContactDetails from '../review/ReviewContactDetails'
import ReviewRepresentativeDetails from '../review/ReviewRepresentativeDetails'
import ReviewFinancialDetails from '../review/ReviewFinancialDetails'
import ReviewAdvancedDetails from '../review/ReviewAdvancedDetails'
import ReviewBasicDetails from '../review/ReviewBasicDetails'
import ReviewBusinessActivity from '../review/ReviewBusinessActivity'
import ReviewDataProtection from '../review/ReviewDataProtection'
import ReviewTaxRegulations from '../review/ReviewTaxRegulations'
import ReviewDocuments from '../review/ReviewDocuments'

const ReviewApplication = () => {
  const { invitationCode } = useParams()
  const navigate = useNavigate()

  return (
    <Stack>
      <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl' fw={500} lh='1.3'>
        <Anchor onClick={() => navigate(`/onboarding/${invitationCode}/overview/`)} c='secondary'>
          Dashboard
        </Anchor>
        <Box>Step 11 | Review Application</Box>
      </Breadcrumbs>
      <ReviewBasicDetails />
      <ReviewContactDetails />
      <ReviewAdvancedDetails />
      {/* { todo } */}
      <ReviewRepresentativeDetails />
      <ReviewFinancialDetails />
      <ReviewTaxRegulations />
      <ReviewBusinessActivity />
      <ReviewDataProtection />
      <ReviewDocuments />
    </Stack>
  )
}

export default ReviewApplication
