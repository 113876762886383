import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Box, Group, Stack, Text } from '@mantine/core'
import { ConvertBooleanToYesNoUndefined } from '@/utils/formatting'
import { AdvancedDetailsValidationFields, CompanyTypeMapper } from '../step3/AdvancedDetails'
import { DisplayCountryNameWithFlag } from '@/components/common/DisplayCountryNameWithFlag'
import ReviewBase from './ReviewBase'

const ReviewAdvancedDetails = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 3 | Advanced Details' step={3} validationFields={AdvancedDetailsValidationFields}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          What is your company type?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {(form.getValues().company.companyType && CompanyTypeMapper[form.getValues().company.companyType!]) || '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Is the company part of a larger group structure of companies forming the overall business?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {ConvertBooleanToYesNoUndefined(form.getValues().company.isSubsidiary) ?? '-'}
        </Text>
        {form.getValues().company.isSubsidiary && (
          <Stack pl='md'>
            {form.getValues().company.group.governmentalLicense.licensedSubsidiaries?.length > 0 && (
              <>
                <Text fz='sm' my='3xs' c='secondary'>
                  Are any of the other companies in the group structure forming the overall business licensed by any
                  government or government supported institution?
                </Text>
                {form.getValues().company.group.governmentalLicense.licensedSubsidiaries.map((s) => (
                  <Text fz='md' fw='500' c='textPrimary' key={s.legalName}>
                    {`${s.legalName} - ${s.licensePublicSource}`}
                  </Text>
                ))}
              </>
            )}
            {form.getValues().company.group.stockExchange.listedSubsidiaries?.length > 0 && (
              <>
                <Text fz='sm' my='3xs' c='secondary'>
                  Are any of the other companies in the group structure forming the overall business listed on any stock
                  exchange?
                </Text>
                {form.getValues().company.group.stockExchange.listedSubsidiaries.map((s) => (
                  <Text fz='md' fw='500' c='textPrimary' key={s.legalName}>
                    {`${s.legalName} - ${s.isinCode}`}
                  </Text>
                ))}
              </>
            )}
          </Stack>
        )}
        <Text fz='sm' my='3xs' c='secondary'>
          Is the company licensed by any government or government supported institution?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {ConvertBooleanToYesNoUndefined(form.getValues().company.governmentalLicense.isLicensed) ?? '-'}
        </Text>
        {form.getValues().company.governmentalLicense.isLicensed && (
          <Stack pl='md'>
            <Text fz='sm' my='3xs' c='secondary'>
              Name of the public source where the license can be validated
            </Text>
            <Text fz='md' fw='500' c='textPrimary'>
              {form.getValues().company.governmentalLicense.licensePublicSource ?? '-'}
            </Text>
          </Stack>
        )}
        <Text fz='sm' my='3xs' c='secondary'>
          Is the company owned directly or indirectly by a nominee shareholder?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {ConvertBooleanToYesNoUndefined(form.getValues().company.shareholders.isOwnedByNominee) ?? '-'}
        </Text>
        {form.getValues().company.shareholders.isOwnedByNominee && (
          <>
            <Text fz='sm' my='3xs' c='secondary'>
              Full name of the nominee shareholder
            </Text>
            <Text fz='md' fw='500' c='textPrimary'>
              {form.getValues().company.shareholders.nominee.fullName ?? '-'}
            </Text>
            <Text fz='sm' my='3xs' c='secondary'>
              The nominee shareholder is regulated in the following country
            </Text>
            {form.getValues().company.shareholders.nominee.regulatingCountryCode ? (
              <DisplayCountryNameWithFlag
                countryCode={form.getValues().company.shareholders.nominee.regulatingCountryCode!}
              />
            ) : (
              <Text fz='md' fw='500' c='textPrimary'>
                -
              </Text>
            )}
          </>
        )}
        <Text fz='sm' my='3xs' c='secondary'>
          Is the company listed on any stock exchange?
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {ConvertBooleanToYesNoUndefined(form.getValues().company.stockExchange.isListed) ?? '-'}
        </Text>
        {form.getValues().company.stockExchange.isListed && (
          <Stack pl='md'>
            <Text fz='sm' my='3xs' c='secondary'>
              ISIN code
            </Text>
            <Text fz='md' fw='500' c='textPrimary'>
              {form.getValues().company.stockExchange.isinCode ?? '-'}
            </Text>
          </Stack>
        )}
        <Text fz='sm' my='3xs' c='secondary'>
          List the countries from where you will receive funds
        </Text>
        <Group>
          {form.getValues().account.receiveFunds.countries.length
            ? form.getValues().account.receiveFunds.countries.map((c) => (
                <Box key={c} bg='gray' p='xs' style={{ borderRadius: '1rem' }}>
                  <DisplayCountryNameWithFlag countryCode={c} />
                </Box>
              ))
            : '-'}
        </Group>
        <Text fz='sm' my='3xs' c='secondary'>
          List the countries to where you will send funds
        </Text>
        <Group>
          {form.getValues().account.sendFunds.countries.length
            ? form.getValues().account.sendFunds.countries.map((c) => (
                <Box key={c} bg='gray' p='xs' style={{ borderRadius: '1rem' }}>
                  <DisplayCountryNameWithFlag countryCode={c} />
                </Box>
              ))
            : '-'}
        </Group>
      </Stack>
    </ReviewBase>
  )
}

export default ReviewAdvancedDetails
