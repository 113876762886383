import { CompanyType } from '@/api/org/types'
import { IconArrowRight, IconCloseCross, IconViewInfo } from '@/components/icons'
import { getMultiSelectPropsForCountry, getSelectPropsForCountry } from '@/components/inputs/helper'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { ConvertBooleanToYesNoUndefined } from '@/utils/formatting'
import {
  Breadcrumbs,
  Anchor,
  Box,
  Select,
  Radio,
  Group,
  MultiSelect,
  TextInput,
  Tooltip,
  UnstyledButton,
  Stack,
  Flex,
  Text,
  getThemeColor,
  MantineTheme,
  Button,
  Paper,
} from '@mantine/core'
import React, { FormEvent, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import OnboardingStatus from '../status/OnboardingStatus'

export const CompanyTypeMapper: Record<CompanyType, string> = {
  ASSOCIATION: 'Association',
  CHARITABLE_ORGANIZATION: 'Charitable Organization',
  FOUNDATION: 'Foundation',
  GOVERNMENT_OWNED_COMPANY: 'Government Owned Company',
  HOLDING_COMPANY: 'Holding Company',
  NON_PROFIT_ORGANIZATION: 'Non-Profit Organization',
  ONE_PERSON_COMPANY_OR_SOLE_PROPRIETORSHIP: 'One Person Company or Sole Proprietorship',
  OTHER: 'Other',
  PARTNERSHIP: 'Partnership',
  PRIVATELY_OWNED_COMPANY: 'Privately Owned Company',
  PUBLICLY_TRADED_COMPANY: 'Publicly Traded Company',
  TRADING_COMPANY: 'Trading Company',
  TRUST: 'Trust',
}

export const AdvancedDetailsValidationFields = [
  'company.companyType',
  'company.companyTypeOther',
  'company.isSubsidiary',
  'company.group.stockExchange.listedSubsidiaries.legalName',
  'company.group.stockExchange.listedSubsidiaries.isinCode',
  'company.group.governmentalLicense.licensedSubsidiaries.legalName',
  'company.group.governmentalLicense.licensedSubsidiaries.licensePublicSource',
  'company.shareholders.isOwnedByNominee',
  'company.shareholders.fullName',
  'company.shareholders.regulatingCountryCode',
  'company.governmentalLicense.isLicensed',
  'company.governmentalLicense.licensePublicSource',
  'company.stockExchange.isListed',
  'company.stockExchange.isinCode',
  'account.receiveFunds.countries',
  'account.sendFunds.countries',
]

const AdvancedDetails = () => {
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()
  const [isGovGroup, setIsGovGroup] = useState<boolean>()
  const [isStockGroup, setIsStockGroup] = useState<boolean>()
  const navigate = useNavigate()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(AdvancedDetailsValidationFields)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/4`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='dark' mb='xl' fw={500} lh='1.3'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 3 | Advanced Details</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <Select
              label='What is your company type?'
              labelProps={{ c: 'secondary' }}
              placeholder='Select company type'
              data={(Object.keys(CompanyTypeMapper) as CompanyType[]).map((k) => ({
                value: k,
                label: CompanyTypeMapper[k],
              }))}
              {...form.getInputProps('company.companyType')}
              key={form.key('company.companyType')}
            />
            <Radio.Group
              labelProps={{ c: 'secondary' }}
              label='Is the company part of a larger group structure of companies forming the overall business?'
              value={ConvertBooleanToYesNoUndefined(form.getValues().company.isSubsidiary)}
              onChange={(value) => {
                const isPartOfGroup = value === 'Yes'
                form.setFieldValue('company.isSubsidiary', isPartOfGroup)

                if (!isPartOfGroup) {
                  form.setFieldValue('company.group.stockExchange.listedSubsidiaries', [])
                  form.setFieldValue('company.group.governmentalLicense.licensedSubsidiaries', [])
                  setIsStockGroup(undefined)
                  setIsGovGroup(undefined)
                }
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {form.getValues().company.isSubsidiary && (
              <>
                <Radio.Group
                  label='Are any of the other companies in the group structure forming the overall business listed on any stock exchange?'
                  value={ConvertBooleanToYesNoUndefined(isStockGroup)}
                  onChange={(value) => {
                    const isStockGroup = value === 'Yes'
                    setIsStockGroup(isStockGroup)

                    form.setFieldValue(
                      'company.group.stockExchange.listedSubsidiaries',
                      isStockGroup ? [{ legalName: '', isinCode: '' }] : []
                    )
                  }}
                  pl='sm'
                  labelProps={{
                    c: 'dark',
                    fw: 500,
                    pl: 'lg',
                    sx: (theme: MantineTheme) => ({
                      borderLeft: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}`,
                    }),
                  }}
                  errorProps={{ mt: 'xs', c: 'red' }}
                >
                  <Group mt='xs'>
                    <Radio value='Yes' label='Yes' />
                    <Radio value='No' label='No' />
                  </Group>
                </Radio.Group>
                {isStockGroup && (
                  <>
                    {form.getValues().company?.group?.stockExchange?.listedSubsidiaries?.map((_subsidiary, index) => (
                      <React.Fragment key={index}>
                        <Flex gap='lg' align='start' pl='sm'>
                          <TextInput
                            w='50%'
                            labelProps={{ c: 'secondary' }}
                            label='Company Name'
                            placeholder='Company Name'
                            {...form.getInputProps(`company.group.stockExchange.listedSubsidiaries.${index}.legalName`)}
                          />
                          <TextInput
                            w='50%'
                            labelProps={{ c: 'secondary' }}
                            label='ISIN code'
                            placeholder='ISIN code'
                            rightSection={
                              <Tooltip color='secondary' label='International Securities Identification Number (ISIN)'>
                                <UnstyledButton variant='outline'>
                                  <IconViewInfo style={{ height: '1rem' }} />
                                </UnstyledButton>
                              </Tooltip>
                            }
                            {...form.getInputProps(`company.group.stockExchange.listedSubsidiaries.${index}.isinCode`)}
                          />
                        </Flex>
                        {index > 0 && (
                          <Button
                            ml='sm'
                            leftSection={<IconCloseCross style={{ color: 'black', height: '1rem' }} />}
                            onClick={() => form.removeListItem('company.group.stockExchange.listedSubsidiaries', index)}
                            variant='subtle'
                            size='sm'
                          >
                            Remove Company
                          </Button>
                        )}
                      </React.Fragment>
                    ))}
                    <Button
                      variant='filled'
                      color='secondary'
                      h='2.6rem'
                      size='xs'
                      onClick={() =>
                        form.insertListItem('company.group.stockExchange.listedSubsidiaries', {
                          legalName: '',
                          isinCode: '',
                        })
                      }
                      ml='sm'
                    >
                      + Add another company
                    </Button>
                  </>
                )}
                <Radio.Group
                  label='Are any of the other companies in the group structure forming the overall business licensed by any government or government supported institution?'
                  pl='sm'
                  value={ConvertBooleanToYesNoUndefined(isGovGroup)}
                  onChange={(value) => {
                    const isGovGroup = value === 'Yes'
                    setIsGovGroup(isGovGroup)
                    form.setFieldValue(
                      'company.group.governmentalLicense.licensedSubsidiaries',
                      isGovGroup ? [{ legalName: '', licensePublicSource: '' }] : []
                    )
                  }}
                  labelProps={{
                    c: 'dark',
                    fw: 500,
                    pl: 'lg',
                    sx: (theme: MantineTheme) => ({
                      borderLeft: `solid 1px ${getThemeColor('backgroundMuted.1', theme)}`,
                    }),
                  }}
                  errorProps={{ mt: 'xs', c: 'red' }}
                >
                  <Group mt='xs'>
                    <Radio value='Yes' label='Yes' />
                    <Radio value='No' label='No' />
                  </Group>
                </Radio.Group>
                {isGovGroup && (
                  <>
                    {form
                      .getValues()
                      .company?.group?.governmentalLicense?.licensedSubsidiaries?.map((_institution, index) => (
                        <React.Fragment key={index}>
                          <Flex gap='lg' align='start' pl='sm'>
                            <TextInput
                              w='50%'
                              labelProps={{ c: 'secondary' }}
                              label='Company Name'
                              placeholder='Company Name'
                              {...form.getInputProps(
                                `company.group.governmentalLicense.licensedSubsidiaries.${index}.legalName`
                              )}
                            />
                            <TextInput
                              w='50%'
                              labelProps={{ c: 'secondary' }}
                              label='Public source for validation'
                              placeholder='Public source where the license can be validated'
                              rightSection={
                                <Tooltip color='secondary' label='If license is not available, please explain.'>
                                  <UnstyledButton variant='outline'>
                                    <IconViewInfo style={{ height: '1rem' }} />
                                  </UnstyledButton>
                                </Tooltip>
                              }
                              {...form.getInputProps(
                                `company.group.governmentalLicense.licensedSubsidiaries.${index}.licensePublicSource`
                              )}
                            />
                          </Flex>
                          {index > 0 && (
                            <Button
                              leftSection={<IconCloseCross style={{ color: 'black', height: '0.6rem' }} />}
                              onClick={() =>
                                form.removeListItem('company.group.governmentalLicense.licensedSubsidiaries', index)
                              }
                              variant='subtle'
                              h='2.6rem'
                              size='xs'
                              ml='sm'
                            >
                              Remove company
                            </Button>
                          )}
                        </React.Fragment>
                      ))}
                    <Button
                      variant='filled'
                      color='secondary'
                      h='2.6rem'
                      size='xs'
                      onClick={() =>
                        form.insertListItem('company.group.governmentalLicense.licensedSubsidiaries', {
                          legalName: '',
                          licensePublicSource: '',
                        })
                      }
                      ml='sm'
                    >
                      + Add another company
                    </Button>
                  </>
                )}
              </>
            )}

            <Radio.Group
              label='Is the company licensed by any government or government supported institution?'
              labelProps={{ c: 'secondary' }}
              {...form.getInputProps('company.governmentalLicense.isLicensed')}
              value={ConvertBooleanToYesNoUndefined(form.getValues().company?.governmentalLicense?.isLicensed)}
              onChange={(value) => {
                const isLicensed = value === 'Yes'
                console.log('is licensed', isLicensed)
                form.setFieldValue('company.governmentalLicense.isLicensed', isLicensed)
                if (!isLicensed) {
                  form.setFieldValue('company.governmentalLicense.licensePublicSource', '')
                }
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {form.getValues().company?.governmentalLicense?.isLicensed && (
              <TextInput
                label='Public source where the license can be validated'
                labelProps={{ c: 'secondary' }}
                rightSection={
                  <Tooltip color='secondary' label='If license is not available, please explain.'>
                    <UnstyledButton variant='outline'>
                      <IconViewInfo style={{ height: '1rem' }} />
                    </UnstyledButton>
                  </Tooltip>
                }
                {...form.getInputProps('company.governmentalLicense.licensePublicSource')}
              />
            )}

            <Radio.Group
              label='Is the company owned directly or indirectly by a nominee shareholder?'
              labelProps={{ c: 'secondary' }}
              {...form.getInputProps('company.shareholders.isOwnedByNominee')}
              value={ConvertBooleanToYesNoUndefined(form.getValues().company?.shareholders?.isOwnedByNominee)}
              onChange={(value) => {
                const isOwnedByShareholder = value === 'Yes'
                form.setFieldValue('company.shareholders.isOwnedByNominee', isOwnedByShareholder)
                if (!isOwnedByShareholder) {
                  form.setFieldValue('company.shareholders.nominee.fullName', '')
                  form.setFieldValue('company.shareholders.nominee.regulatingCountryCode', 'ZZ')
                }
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {form.getValues()?.company?.shareholders?.isOwnedByNominee && (
              <Flex direction='column' gap='sm'>
                <Text fz='sm' fw='600' w='100%'>
                  Shareholder details
                </Text>

                <Stack gap='md'>
                  <TextInput
                    labelProps={{ c: 'secondary' }}
                    label='Full name of the nominee shareholder'
                    {...form.getInputProps('company.shareholders.nominee.fullName')}
                  />

                  <Select
                    label='Country where the nominee shareholder is regulated'
                    labelProps={{ c: 'secondary' }}
                    placeholder='Enter country of incorporation'
                    {...getSelectPropsForCountry(
                      form.getValues().company?.shareholders?.nominee?.regulatingCountryCode
                    )}
                    {...form.getInputProps('company.countryOfIncorporation')}
                    key={form.key('company.shareholders.nominee.regulatingCountryCode')}
                  />
                </Stack>
              </Flex>
            )}

            <Radio.Group
              label='Is the company listed on any stock exchange?'
              labelProps={{ c: 'secondary' }}
              {...form.getInputProps('company.stockExchange.isListed')}
              value={ConvertBooleanToYesNoUndefined(form.getValues().company?.stockExchange?.isListed)}
              onChange={(value) => {
                const isListed = value === 'Yes'
                form.setFieldValue('company.stockExchange.isListed', isListed)
                if (!isListed) {
                  form.setFieldValue('company.stockExchange.isinCode', '')
                }
              }}
              errorProps={{ mt: 'xs', c: 'red' }}
            >
              <Group mt='xs'>
                <Radio value='Yes' label='Yes' />
                <Radio value='No' label='No' />
              </Group>
            </Radio.Group>
            {form.getValues().company?.stockExchange?.isListed && (
              <TextInput
                label='ISIN code'
                labelProps={{ c: 'secondary' }}
                rightSection={
                  <Tooltip color='secondary' label='International Securities Identification Number (ISIN)'>
                    <UnstyledButton variant='outline'>
                      <IconViewInfo style={{ height: '1rem' }} />
                    </UnstyledButton>
                  </Tooltip>
                }
                {...form.getInputProps('company.stockExchange.isinCode')}
              />
            )}
            <MultiSelect
              label='List the countries you will receive funds from'
              multiple
              labelProps={{ c: 'secondary' }}
              searchable
              placeholder='Select countries'
              {...form.getInputProps('account.receiveFunds.countries')}
              key={form.key('account.receiveFunds.countries')}
              {...getMultiSelectPropsForCountry()}
            />
            <MultiSelect
              label='List the countries you will send funds to'
              multiple
              labelProps={{ c: 'secondary' }}
              searchable
              placeholder='Select countries'
              {...form.getInputProps('account.sendFunds.countries')}
              key={form.key('account.sendFunds.countries')}
              {...getMultiSelectPropsForCountry()}
            />
            <Group justify='space-between' mt='lg'>
              <Button onClick={() => navigate(`/onboarding/${invitationCode}/step/2`)} color='dark'>
                Previous
              </Button>
              <Group>
                <Button name='save' type='submit'>
                  Save
                </Button>
                <Button name='saveAndContinue' type='submit'>
                  Save and continue
                </Button>
              </Group>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Flex>
  )
}

export default AdvancedDetails
