import { useEffect } from 'react'
import { Button, Flex, Paper, Select, Stack, TextInput, Title, Text, Space, Container } from '@mantine/core'
import { IconCampaign } from '@/components/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from '@mantine/form'
import { industrySelectData, subIndustrySelectDataMap } from '../onboardingUtils'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { useValidateOrgDetails, useSubmitOrgSignUp } from '@/api/org/hooks'
import LoadingOrError from '@/components/loader/LoadingOrError'
import { BusinessDetailsRequest } from '@/api/org/types'

const SignUp = () => {
  const { invitationCode } = useParams()
  const navigate = useNavigate()

  const form = useForm<BusinessDetailsRequest>({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      organisationName: '',
      countryOfIncorporation: null,
      industryOfBusiness: '',
      industrySubType: '',
    },
    validate: {
      emailAddress: (value) =>
        value.length === 0
          ? 'Please enter an email'
          : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
          ? 'Invalid email address'
          : null,
      firstName: (value) => (value.length === 0 ? 'Please enter a first name' : null),
      lastName: (value) => (value.length === 0 ? 'Please enter a last name' : null),
      countryOfIncorporation: (value) => (value === null ? 'Please select a country' : null),
      industryOfBusiness: (value) => (value.length === 0 ? 'Please select the industry of your business' : null),
      industrySubType: (value) => (value.length === 0 ? 'Please select the industry sub-type of your business' : null),
    },
  })

  const {
    data: orgDetails,
    isLoading,
    isError,
  } = useValidateOrgDetails(invitationCode ?? '', {
    enabled: !!invitationCode,
  })

  const { mutate: submitSignUp, isLoading: isSubmitting } = useSubmitOrgSignUp()

  useEffect(() => {
    if (orgDetails) {
      form.setValues((currentValues) => ({
        ...currentValues,
        ...orgDetails,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails])

  const onSubmit = () => {
    const validationResult = form.validate()
    if (!validationResult.hasErrors && invitationCode) {
      submitSignUp(
        { invitationCode, request: form.getValues() },
        {
          onSuccess: () => {
            navigate(`/onboarding/overview/${invitationCode}`)
          },
        }
      )
    }
  }

  if (isLoading || isError) {
    return <LoadingOrError isLoading={isLoading} isError={isError} />
  }

  return (
    <Flex style={{ minHeight: 'calc(100vh - 180px)' }}>
      <Container size='lg' p='xl' style={{ minHeight: 'calc(100vh - 180px)', display: 'flex', alignItems: 'center' }}>
        <Paper>
          <Flex p='xl' gap={0} direction={{ base: 'column', desktop: 'row' }}>
            <Stack
              align='center'
              justify='center'
              pos='relative'
              px={{ base: 'lg', desktop: '3xl' }}
              py={{ base: 'md', desktop: '3xl' }}
              sx={{ flex: 1 }}
            >
              <Paper
                component={IconCampaign}
                h={{ base: '4rem', desktop: '5rem' }}
                c='white'
                bg='primary'
                radius='50%'
                shadow='md'
                p='xs'
                style={{ borderRadius: '50%' }}
              />
              <Title ta='center' order={1} mt='sm' c='primary'>
                Great News!
              </Title>
              <Space />
              <Text ta='center' c='grey'>
                <b>Leikur&aposs application process is completed entirely online!</b>
              </Text>
              <Space />
              <Text ta='center' c='grey'>
                We&apos;ve taken great care to create an easy and pain-free system for you to follow. Effortlessly edit,
                review, and submit all your information when you are ready. Here goes...
              </Text>
            </Stack>
            <Stack
              sx={(theme) => ({
                borderLeft: `1px solid ${theme.colors.primary[6]}`,
                flex: 1,
                '@media (max-width: 1024px)': {
                  borderLeft: 'none',
                },
              })}
              px={{ base: 'lg', desktop: '3xl' }}
              py={{ base: 'md', desktop: '3xl' }}
              gap='md'
            >
              <TextInput
                label='First name'
                description='First name of the person filling this application'
                {...form.getInputProps('firstName')}
              />
              <TextInput
                label='Last name'
                description='Last name of the person filling this application'
                {...form.getInputProps('lastName')}
              />
              <TextInput
                label='Email address'
                description='Email address of the person filling this application'
                {...form.getInputProps('emailAddress')}
              />
              <Select
                label='Industry of your business'
                description='One core industry that is applicable to your business'
                searchable
                {...form.getInputProps('industryOfBusiness')}
                onChange={(v) => {
                  form.setFieldValue('industryOfBusiness', v ?? '')
                  form.setFieldValue('industrySubType', '')
                }}
                data={industrySelectData}
              />
              <Select
                disabled={form.getValues().industryOfBusiness === ''}
                label='Industry sub-type of your business'
                description='Type of industry that is applicable to your business'
                searchable
                {...form.getInputProps('industrySubType')}
                data={
                  subIndustrySelectDataMap[
                    form.getValues().industryOfBusiness as keyof typeof subIndustrySelectDataMap
                  ] ?? []
                }
              />
              <Select
                label='Country of incorporation'
                description='Country where the company is incorporated'
                searchable
                {...getSelectPropsForCountry(form.getValues().countryOfIncorporation)}
                {...form.getInputProps('countryOfIncorporation')}
              />
              <Flex align='center' justify='end' mt='md'>
                <Button onClick={onSubmit} disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Start your application'}
                </Button>
              </Flex>
            </Stack>
          </Flex>
        </Paper>
      </Container>
    </Flex>
  )
}

export default SignUp
