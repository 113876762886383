import { BasicDetailsValidationFields } from '../step1/BasicDetails'
import { ContactDetailsValidation } from '../step2/ContactDetails'
import { AdvancedDetailsValidationFields } from '../step3/AdvancedDetails'
import { FinancialDetailsValidation } from '../step5/FinancialDetails'
import { TaxRegulationsValidationFields } from '../step6/TaxRegulations'
import { BusinessActivityValidationFields } from '../step7/BusinessActivity'
import { DataProtectionValidationFields } from '../step8/DataProtection'
import { DocumentsValidationFields } from '../step9/Documents'
import { RepresentativeDetailsValidation } from '../step4/RepresentativeDetails'

export type OnboardingStepId =
  | 'step_1_basic_details'
  | 'step_2_contact_details'
  | 'step_3_advanced_details'
  | 'step_4_representatives'
  | 'step_5_financial_details'
  | 'step_6_tax_regulations'
  | 'step_7_business_activity'
  | 'step_8_data_protection'
  | 'step_9_documents'
  | 'step_10_review_application'

export interface OnboardingStep {
  id: OnboardingStepId
  title: string
  description: string
  isCompleted: boolean
  href: string
  validationFields: string[]
}

export const OnboardingSteps = (): OnboardingStep[] => [
  {
    id: 'step_1_basic_details',
    title: 'STEP 1 | Basic Details',
    description: 'Company name, registration number, main clients, service providers, other service entities, etc.',
    isCompleted: true,
    href: 'step/1',
    validationFields: BasicDetailsValidationFields,
  },
  {
    id: 'step_2_contact_details',
    title: 'STEP 2 | Contact Details',
    description: 'Registered company address, headquarters address, and contact details.',
    isCompleted: false,
    href: 'step/2',
    validationFields: ContactDetailsValidation,
  },
  {
    id: 'step_3_advanced_details',
    title: 'STEP 3 | Advanced Details',
    description: 'Company type, structure, account use, and other details.',
    isCompleted: false,
    href: 'step/3',
    validationFields: AdvancedDetailsValidationFields,
  },
  {
    id: 'step_4_representatives',
    title: 'STEP 4 | Representatives',
    description: 'Master Authorised Representative, directors, and Ultimate Beneficial Owners.',
    isCompleted: false,
    href: 'step/4',
    validationFields: RepresentativeDetailsValidation,
  },
  {
    id: 'step_5_financial_details',
    title: 'STEP 5 | Financial Details',
    description: 'Expected gross turnover, account limits, services, source of income, etc.',
    isCompleted: true,
    href: 'step/5',
    validationFields: FinancialDetailsValidation,
  },
  {
    id: 'step_6_tax_regulations',
    title: 'STEP 6 | Tax Regulations',
    description: 'Tax residency and CRS/FATCA information.',
    isCompleted: true,
    href: 'step/6',
    validationFields: TaxRegulationsValidationFields,
  },
  {
    id: 'step_7_business_activity',
    title: 'STEP 7 | Business Activity',
    description:
      'Detailed description of the business activity and industry/sub-industry that applies to your company.',
    isCompleted: true,
    href: 'step/7',
    validationFields: BusinessActivityValidationFields,
  },
  {
    id: 'step_8_data_protection',
    title: 'STEP 8 | Data Protection',
    description: 'GDPR and other applicable legal acts.',
    isCompleted: false,
    href: 'step/8',
    validationFields: DataProtectionValidationFields,
  },
  {
    id: 'step_9_documents',
    title: 'STEP 9 | Documents',
    description: 'Documents to support all the requested information.',
    isCompleted: false,
    href: 'step/9',
    validationFields: DocumentsValidationFields,
  },
  {
    id: 'step_10_review_application',
    title: 'STEP 10 | Review Application',
    description: 'Review the details of the application and submit.',
    isCompleted: false,
    href: 'step/10',
    validationFields: [],
  },
]
