import {
  Anchor,
  Box,
  Text,
  Breadcrumbs,
  Button,
  List,
  Stack,
  Group,
  Grid,
  useMantineTheme,
  Flex,
  Paper,
} from '@mantine/core'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { helpBorder } from '../onboardingUtils'
import { IconArrowRight } from '@/components/icons'
import { colorAliases } from '@/theme/mantineTheme'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { BusinessOnboardingDocumentType, BusinessOnboardingRequest } from '@/api/org/types'
import { FormEvent } from 'react'
import DocumentUploadButton from './DocumentUploadButton'
import { useMediaQuery } from '@mantine/hooks'
import { DeepKeys } from '@/utils/advancedTyping'
import OnboardingStatus from '../status/OnboardingStatus'

interface FileField {
  key: DeepKeys<BusinessOnboardingRequest>
  label: string
  formKey: BusinessOnboardingDocumentType
}

export const DocumentsValidationFields = [
  'company.beneficialOwnershipDocument',
  'company.extractFromCompanyRegistry',
  'company.uboStructureChart',
  'company.memorandumArticleOfAssociation',
  'company.amlFraudProcedures',
  'company.certificateIncorporation',
  'company.certificateIncumbency',
  'company.purposeOfAccount',
  'company.businessActivityDocuments',
  'company.moneyFlowChart',
  'company.companyGroupChart',
]

export const OnboardingDocumentFields: FileField[] = [
  {
    key: 'company.memorandumArticleOfAssociation',
    label: 'Memorandum and Articles of Association (or the equivalent for the applicable jurisdiction)',
    formKey: 'MemorandumArticleOfAssociation',
  },
  {
    key: 'company.extractFromCompanyRegistry',
    label: 'Dated extract from the company register, issued within the past 3 months',
    formKey: 'ExtractFromCompanyRegistry',
  },
  {
    key: 'company.beneficialOwnershipDocument',
    label: 'Documents confirming the beneficial ownership',
    formKey: 'BeneficialOwnershipDocument',
  },
  {
    key: 'company.uboStructureChart',
    label: 'Detailed corporate chart clearly defining the Ultimate Beneficial Owner(s)',
    formKey: 'UboStructureChart',
  },
  {
    key: 'company.amlFraudProcedures',
    label: 'Anti-money laundering and fraud prevention procedures',
    formKey: 'AmlFraudProcedures',
  },
  {
    key: 'company.certificateIncorporation',
    label: 'Certificate of Incorporation',
    formKey: 'CertificateIncorporation',
  },
  {
    key: 'company.certificateIncumbency',
    label: 'Certificate of Incumbency',
    formKey: 'CertificateIncumbency',
  },
  {
    key: 'company.purposeOfAccount',
    label: 'Statement of the purpose of the account',
    formKey: 'PurposeOfAccount',
  },
  {
    key: 'company.businessActivityDocuments',
    label: 'Supporting documents for business activities',
    formKey: 'BusinessActivityDocuments',
  },
  {
    key: 'company.moneyFlowChart',
    label: 'Detailed money flow chart',
    formKey: 'MoneyFlowChart',
  },
  {
    key: 'company.companyGroupChart',
    label: 'Corporate group structure chart',
    formKey: 'CompanyGroupChart',
  },
  {
    key: 'additionalDocuments',
    label: 'Any other documents you would like to add to support this application (Optional)',
    formKey: 'AdditionalDocument',
  },
]

const Documents = () => {
  const { invitationCode } = useParams()
  const navigate = useNavigate()
  const { validateFields } = useOnboardingForm()

  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(DocumentsValidationFields)) {
      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/11`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 9 | Documents</Box>
        </Breadcrumbs>

        <Box lh='1.55' fz='sm' p='md' pr='xl' pb={0} bg={colorAliases.surfaceLightgray} mb='md'>
          <Text c='secondary'>General note</Text>
          <List px='xs' py='md' fz='xs'>
            <List.Item>
              To make your onboarding as easy as possible, it&apos;s essential that you provide all the documents
              requested.
            </List.Item>
            <List.Item>We reserve the right at any time to request additional documents if they are needed.</List.Item>
            <List.Item>
              All documents must be provided in English or Lithuanian language. If this is not possible, we can only
              accept documents that have been professionally translated (certified copy of the original alongside the
              translation).
            </List.Item>
          </List>
        </Box>

        <Stack>
          <Box lh='1.55' p='md' pr='xl' sx={helpBorder} bg={colorAliases.surfaceLightgray} mb='md'>
            <Box fz='sm' c='secondary' fw='bold' pr='1rem' lh='1.6' pb='sm'>
              ID verification for all UBOs, directors and other individuals listed on this application form NOT marked
              as Authorised Representatives
            </Box>
            <Box fz='xs' pr='1rem'>
              Verification for all such individuals is completed remotely. Please upload the soft copy of the below
              stated document for each individual listed on this application.
            </Box>
          </Box>

          <form onSubmit={handleSubmit}>
            <Grid>
              {OnboardingDocumentFields.map(({ key, label, formKey }) => (
                <Grid.Col span={isMobile ? 12 : 6} key={key}>
                  <DocumentUploadButton label={label} keyPath={key} businessOnboardingDocumentType={formKey} />
                </Grid.Col>
              ))}
            </Grid>

            <Group justify='space-between' mt='lg'>
              <Button onClick={() => navigate(`/onboarding/${invitationCode}/step/8`)} color='dark'>
                Previous
              </Button>
              <Group>
                <Button name='save' type='submit'>
                  Save
                </Button>
                <Button name='saveAndContinue' type='submit'>
                  Save and continue
                </Button>
              </Group>
            </Group>
          </form>
        </Stack>
      </Paper>
    </Flex>
  )
}

export default Documents

// for lazy loading via react-router-dom
export const Component: React.FC = Documents
Component.displayName = 'OnboardingDocumentsComponent'
