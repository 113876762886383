import { BusinessRepresentative } from '@/api/org/types'
import { hasLength, isInRange, isNotEmpty, matches, useForm } from '@mantine/form'

export const useRepresentativeModalFormValidation = () => {
  const form = useForm<BusinessRepresentative>({
    initialValues: {
      personId: '',
      externalReference: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      personalCode: '',
      citizenshipCountryCode: undefined,
      roles: [],
      identityDocument: {
        fileId: '',
        number: '',
        issueDate: '',
        expiryDate: '',
        issuingCountryCode: undefined,
        type: undefined,
      },
      powerOfAttorney: [],
      pep: {
        isPep: false,
        position: '',
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        city: '',
        countryCode: undefined,
      },
      taxIdentification: {
        tinCountryOfIncorporation: '',
        additionalCountries: [],
      },
      ownership: {
        type: undefined,
        typeOther: '',
        shareSize: 0,
        hasVotingControl: false,
        hasVotingControlAdditionalInformation: '',
      },
      uboSourceOfWealth: undefined,
      isDirectorExecutive: false,
    },
    validate: {
      firstName: hasLength({ min: 2 }, 'First name must be at least 2 characters'),
      lastName: hasLength({ min: 2 }, 'Last name must be at least 2 characters'),
      dateOfBirth: matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)'),
      citizenshipCountryCode: isNotEmpty('Citizenship country code is required'),
      personalCode: (v, values) =>
        ['LT', 'LV', 'EE'].includes(values.citizenshipCountryCode ?? '') && isNotEmpty('Personal Code is required')(v),
      roles: isNotEmpty('At least one role must be assigned'),
      // identityDocument: {
      //   number: isNotEmpty('Identity document number is required'),
      //   issueDate: matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid issue date format (YYYY-MM-DD)'),
      //   expiryDate: matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid expiry date format (YYYY-MM-DD)'),
      //   issuingCountryCode: isNotEmpty('Issuing country code is required'),
      //   type: isNotEmpty('Identity document type is required'),
      // },
      pep: {
        position: (v, values) => {
          return values.pep.isPep && isNotEmpty('Position is required for PEP')(v)
        },
      },
      address: {
        addressLine1: isNotEmpty('Address Line 1 is required'),
        postcode: isNotEmpty('Postal code is required'),
        city: isNotEmpty('City is required'),
        countryCode: isNotEmpty('Country code is required'),
      },
      taxIdentification: {
        tinCountryOfIncorporation: (v, values) =>
          values.roles.includes('UBO') && isNotEmpty('TIN is required for UBO')(v),
        additionalCountries: {
          countryCode: isNotEmpty('Please specify the residency country'),
          tin: isNotEmpty('TIN is required'),
        },
      },
      ownership: {
        type: (v, values) => values.roles.includes('UBO') && isNotEmpty('Ownership type is required')(v),
        typeOther: (v, values) =>
          values.ownership.type === 'OTHER' && isNotEmpty('Please provide other ownership type')(v),
        shareSize: (v, values) =>
          values.roles.includes('UBO') && isInRange({ min: 1 }, 'Share size must be greater than 0')(v),
        hasVotingControl: (v, values) =>
          values.roles.includes('UBO') && isNotEmpty('Please specify if the person has voting control')(v),
      },
      uboSourceOfWealth: (v, values) =>
        values.roles.includes('UBO') && isNotEmpty('Source of wealth is required for UBO')(v),
      uboSourceOfWealthOther: (v, values) =>
        values.uboSourceOfWealth === 'OTHER' && isNotEmpty('Please describe other source of wealth')(v),
      isDirectorExecutive: (v, values) =>
        values.roles.includes('DIRECTOR') && isNotEmpty('Please specify if the person is an executive director')(v),
    },
  })

  return form
}
