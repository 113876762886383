import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { Stack, Text } from '@mantine/core'
import ReviewBase from './ReviewBase'
import {
  AnnualGrossTurnoverMap,
  FinancialDetailsValidation,
  MonthlyGrossTurnoverMap,
  MonthlyTransactionVolumeMap,
  NumberOfEmployeesMapper,
  RequestedProductTypeMap,
  SourceOfIncomeTypeMap,
} from '../step5/FinancialDetails'

const ReviewFinancialDetails = () => {
  const { form } = useOnboardingForm()

  return (
    <ReviewBase title='Step 5 | Financial Details' step={5} validationFields={FinancialDetailsValidation}>
      <Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Expected annual gross turnover of the account(s) within Leikur in euro
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().account.annualGrossTurnover?.length
            ? AnnualGrossTurnoverMap[form.getValues().account.annualGrossTurnover!]
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Expected monthly turnover to and from your account(s) in Leikur in euro
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().account.monthlyGrossTurnover?.length
            ? MonthlyGrossTurnoverMap[form.getValues().account.monthlyGrossTurnover!]
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Expected monthly volume (an approximate number) of transactions to and from your account(s) in Leikur in euro
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().account.monthlyTransactionVolume?.length
            ? MonthlyTransactionVolumeMap[form.getValues().account.monthlyTransactionVolume!]
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Desired daily account(s) limits in euro:
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().account.limits.daily}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Desired monthly account(s) limits in euro:
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().account.limits.monthly}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Which Leikur products would you like to use?
        </Text>
        <Stack>
          {form.getValues().account.requestedProducts.length
            ? form.getValues().account.requestedProducts.map((p) => (
                <Text key={p} fz='md' fw='500' c='textPrimary'>
                  {RequestedProductTypeMap[p]}
                </Text>
              ))
            : '-'}
        </Stack>
        <Text fz='sm' my='3xs' c='secondary'>
          Total number of employees and exclusive contractors that work for the company
        </Text>
        <Text fz='md' fw='500' c='textPrimary'>
          {form.getValues().company.numberOfEmployees
            ? NumberOfEmployeesMapper[form.getValues().company.numberOfEmployees!]
            : '-'}
        </Text>
        <Text fz='sm' my='3xs' c='secondary'>
          Sources of Income
        </Text>
        <Stack>
          {form.getValues().account.sourceOfIncome.length
            ? form.getValues().account.sourceOfIncome.map((s) => (
                <Text key={s} fz='md' fw='500' c='textPrimary'>
                  {SourceOfIncomeTypeMap[s]}
                </Text>
              ))
            : '-'}
        </Stack>
        {form.getValues().account.sourceOfIncomeOther.length > 0 && (
          <>
            <Text fz='sm' my='3xs' c='secondary'>
              Sources of Income other
            </Text>
            <Text fz='md' fw='500' c='textPrimary'>
              {form.getValues().account.sourceOfIncomeOther}
            </Text>
          </>
        )}
      </Stack>
    </ReviewBase>
  )
}

export default ReviewFinancialDetails
