import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Text,
  Group,
  Paper,
  Stack,
  TextInput,
  Select,
  Flex,
} from '@mantine/core'
import { FormEvent, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IconArrowRight } from '../../../components/icons'
import { SearchableSelect, usePropsForCountrySelect } from '../../../components/inputs/SearchableSelect'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import { SimplePhoneNumberInput } from '@/components/inputs/PhoneNumberNew/PhoneNumberInput'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import OnboardingStatus from '../status/OnboardingStatus'

export const ContactDetailsValidation = [
  'company.registeredOfficeAddress.addressLine1',
  'company.registeredOfficeAddress.addressLine2',
  'company.registeredOfficeAddress.postcode',
  'company.registeredOfficeAddress.city',
  'company.registeredOfficeAddress.countryCode',
  'company.email',
  'company.phoneNumber.countryCode',
  'company.phoneNumber.number',
  'company.website',
]

const ContactDetails = () => {
  const navigate = useNavigate()
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()

  const propsForCountrySelect = usePropsForCountrySelect()

  const [hasDifferentHeadquartersAddress, setHasDifferentHeadquartersAddress] = useState<boolean>(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    const headquartersFields = [
      'company.headquartersOfficeAddress.addressLine1',
      'company.headquartersOfficeAddress.addressLine2',
      'company.headquartersOfficeAddress.postcode',
      'company.headquartersOfficeAddress.city',
      'company.headquartersOfficeAddress.countryCode',
    ]

    const fieldsToValidate = hasDifferentHeadquartersAddress
      ? [...ContactDetailsValidation, ...headquartersFields]
      : ContactDetailsValidation

    if (validateFields(fieldsToValidate)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/3`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 2 | Contact Details</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <Box p='1rem' bg='light.7'>
              <Text fz='sm' fw='bold' c='secondary' pr='1rem' h='2rem'>
                Company registration address
              </Text>
              <Text fz='sm' c='dark.0'>
                Registered office address as it is mentioned in the company documents.
              </Text>
            </Box>
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Address Line 1'
              placeholder='21 Diagon Avenue'
              {...form.getInputProps('company.registeredOfficeAddress.addressLine1')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='Address Line 2'
              label='Address Line 2'
              {...form.getInputProps('company.registeredOfficeAddress.addressLine2')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='Stockholm'
              label='Town/City'
              {...form.getInputProps('company.registeredOfficeAddress.city')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='103 16'
              label='Postal/Zip Code'
              {...form.getInputProps('company.registeredOfficeAddress.postcode')}
            />
            <Select
              label='Country code'
              labelProps={{ c: 'secondary' }}
              placeholder='Enter country code'
              {...getSelectPropsForCountry(form.getValues().company.registeredOfficeAddress.countryCode)}
              {...form.getInputProps('company.registeredOfficeAddress.countryCode')}
              key={form.key('company.registeredOfficeAddress.countryCode')}
              mt='md'
            />
            <Checkbox
              label='My company has a different headquarters address'
              description="Is the company's headquarters/main office at a different location than the registered address?"
              checked={hasDifferentHeadquartersAddress}
              onChange={(e) => setHasDifferentHeadquartersAddress(e.target.checked)}
            />
            <SimplePhoneNumberInput
              label='Company Phone Number'
              key={form.key('company.phoneNumber.countryCode')}
              countryCodeProps={form.getInputProps('company.phoneNumber.countryCode')}
              phoneNumberProps={form.getInputProps('company.phoneNumber.number')}
            />
            {hasDifferentHeadquartersAddress && (
              <Stack>
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  label='Address Line 1'
                  placeholder='21 Diagon Avenue'
                  {...form.getInputProps('company.headquartersOfficeAddress.addressLine1')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='Address Line 2'
                  label='Address Line 2'
                  {...form.getInputProps('company.headquartersOfficeAddress.addressLine2')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='Stockholm'
                  label='Town/City'
                  {...form.getInputProps('company.headquartersOfficeAddress.city')}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  placeholder='103 16'
                  label='Postal/Zip Code'
                  {...form.getInputProps('company.headquartersOfficeAddress.postcode')}
                />

                <SearchableSelect
                  label='Country'
                  labelProps={{ c: 'secondary' }}
                  placeholder='Sweden'
                  {...propsForCountrySelect}
                  {...form.getInputProps('company.headquartersOfficeAddress.countryCode')}
                />
              </Stack>
            )}
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Company email address'
              placeholder='info@mycompany.com'
              {...form.getInputProps('company.email')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              placeholder='https://www.mycompany.com'
              label='Company website'
              {...form.getInputProps('company.website')}
            />
            <Group justify='space-between' mt='lg'>
              <Button onClick={() => navigate(`/onboarding/${invitationCode}/step/1`)} color='dark'>
                Previous
              </Button>
              <Group>
                <Button name='save' type='submit'>
                  Save
                </Button>
                <Button name='saveAndContinue' type='submit'>
                  Save and continue
                </Button>
              </Group>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Flex>
  )
}

export default ContactDetails
