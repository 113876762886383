import { useValidateOrgDetails } from '@/api/org/hooks'
import LoadingOrError from '@/components/loader/LoadingOrError'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const OnboardingRouteHandler = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate()
  const { invitationCode } = useParams<{ invitationCode: string }>()
  const location = useLocation()
  const lastSegment = location.pathname.split('/').pop()

  const {
    data: orgDetails,
    isLoading,
    isError,
  } = useValidateOrgDetails(invitationCode ?? '', {
    enabled: !!invitationCode,
  })

  useEffect(() => {
    if (!isLoading && invitationCode) {
      if (isError || !orgDetails) {
        console.log('Either an error or not inviation code')
        return
      }
      const isComplete = Object.values(orgDetails).every((value) => value)

      if (isComplete) {
        if (lastSegment === invitationCode) {
          navigate(`/onboarding/${invitationCode}/overview`)
        } else {
          navigate(location)
        }
      } else {
        navigate(`/onboarding/${invitationCode}/sign-up`)
      }
    }
  }, [isLoading, isError, orgDetails, invitationCode])

  if (isLoading || isError) {
    return <LoadingOrError isLoading={isLoading} isError={isError} />
  }

  return <>{children}</>
}

export default OnboardingRouteHandler
