import { Anchor, Box, Button, Container, Flex, Loader, Paper, PasswordInput, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { PageEncryptedReminder } from '../components/common/PageEncryptionReminder'
import ThemedBackgroundImage from '../components/common/ThemedBackgroundImage'
import { LogoLeikur } from '../components/logos'
import { GoogleLogin } from '@react-oauth/google'
import { useSignIn, useSignInWithGoogle, useValidateSession } from '@/api/auth/hooks'

const SignIn = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {
    mutate: loginWithUsernamePassword,
    isLoading: isLoadingEmailPassword,
    isError: isErrorEmailPassword,
    error: errorEmailPassword,
  } = useSignIn()
  const {
    mutate: signInWithGoogle,
    isLoading: isLoadingGoogle,
    isError: isErrorGoogle,
    error: errorGoogle,
  } = useSignInWithGoogle()
  const { data: isValidSession, isLoading: isValidatingSession } = useValidateSession()

  useEffect(() => {
    if (isValidSession) {
      navigate('/dashboard')
    }
  }, [isValidSession, navigate])

  const handleEmailPasswordLogin = () => {
    loginWithUsernamePassword(
      { username: email, password },
      {
        onSuccess: () => {
          navigate('/dashboard')
        },
      }
    )
  }

  // Handler for Google Sign-In
  const handleGoogleSignIn = (googleToken: string) => {
    signInWithGoogle(
      { token: googleToken },
      {
        onSuccess: () => {
          navigate('/dashboard')
        },
      }
    )
  }

  if (isValidatingSession) return <Loader />

  return (
    <Box pos='relative' mih='100vh'>
      <ThemedBackgroundImage theme='leikur' />
      <Helmet>
        <title>Leikur - Sign In</title>
      </Helmet>
      <Container
        style={{ zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        pos='relative'
        mih='100vh'
      >
        <Paper miw={390}>
          <Flex py='xl' fz={{ base: '2.25rem', desktop: '3rem' }} justify='center'>
            <LogoLeikur style={{ height: '1.25em' }} />
          </Flex>
          <Flex direction='column' px='lg' gap='xl'>
            <TextInput
              type='email'
              label='Email address'
              id='email'
              description='The email address linked to your profile'
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
              autoFocus={true}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleEmailPasswordLogin()
                return
              }}
            />
            <PasswordInput
              label='Password'
              description='Enter your profile password to log in'
              id='password'
              onChange={(e) => setPassword(e.currentTarget.value)}
              value={password}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleEmailPasswordLogin()
                return
              }}
            />
            {isErrorEmailPassword && (
              <Text c='red' size='xs'>
                {(errorEmailPassword as { message: string })?.message || 'Login failed'}
              </Text>
            )}

            {/* Forgot Password */}
            <Text fz='sm' c='textPrimary'>
              Forgot your password? Click <Anchor href='/forgot-password'>here</Anchor> to reset.
            </Text>

            <Flex justify='space-between' align='center' gap='sm'>
              <Button variant='outline' onClick={() => navigate('/onboarding/sign-up')}>
                Create account
              </Button>

              {/* Google Sign-In */}
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  const googleToken = credentialResponse.credential // Get Google token
                  handleGoogleSignIn(googleToken!) // Pass token to mutation
                }}
                onError={() => {
                  console.log('Google Login Failed')
                }}
              />

              <Button disabled={!email || !password || isLoadingEmailPassword} onClick={handleEmailPasswordLogin}>
                {isLoadingEmailPassword ? 'Logging in...' : 'Log in'}
              </Button>
            </Flex>

            {isLoadingGoogle && <Text>Logging in with Google...</Text>}
            {isErrorGoogle && (
              <Text c='red' size='xs'>
                {(errorGoogle as { message: string })?.message || 'Google Sign-In failed'}
              </Text>
            )}
          </Flex>
          <PageEncryptedReminder sx={{ justifyContent: 'center' }} mt='xl' />
        </Paper>
      </Container>
    </Box>
  )
}

export default SignIn
