import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Flex,
  getThemeColor,
  Group,
  Paper,
  Radio,
  Select,
  Stack,
  TextInput,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconArrowRight, IconPlusClear } from '@/components/icons'
import { helpBorder } from '../onboardingUtils'
import TaxRegulationsModal from './TaxRegulationsModal'
import { getSelectPropsForCountry } from '@/components/inputs/helper'
import { colorAliases } from '@/theme/mantineTheme'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CrsFatcaType } from '@/api/org/types'
import { FormEvent } from 'react'
import { useOnboardingForm } from '@/contexts/onboarding/useOnboardingForm'
import OnboardingStatus from '../status/OnboardingStatus'

export const CrsFatcaMapper: Record<CrsFatcaType, string> = {
  ACTIVE_FINANCIAL_ENTITY: 'Active Financial Entity',
  PASSIVE_FINANCIAL_ENTITY: 'Passive Financial Entity',
  FINANCIAL_AND_FOREIGN_ENTITY: 'Financial and Foreign Entity',
}

export const TaxRegulationsValidationFields = [
  'company.taxIdentification.tinCountryOfIncorporation',
  'company.taxIdentification.additionalCountries',
  'company.crsFatcaType',
]

const TaxRegulations = () => {
  const [helpOpened, { open: openHelp, close: closeHelp }] = useDisclosure(false)
  const { invitationCode } = useParams()
  const { form, saveForm, validateFields } = useOnboardingForm()
  const navigate = useNavigate()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const submitterName = (e?.nativeEvent as Event & { submitter?: HTMLButtonElement })?.submitter?.name

    if (validateFields(TaxRegulationsValidationFields)) {
      saveForm()

      if (submitterName === 'saveAndContinue') {
        navigate(`/onboarding/${invitationCode}/step/8`)
      }
    }
  }

  return (
    <Flex gap='sm'>
      <Box>
        <OnboardingStatus />
      </Box>
      <Paper p='lg' style={{ flexGrow: 1 }}>
        <Breadcrumbs separator={<IconArrowRight />} fz='sm' c='textPrimary' mb='xl'>
          <Anchor to={`/onboarding/${invitationCode}/overview/`} c='secondary' component={Link}>
            Dashboard
          </Anchor>
          <Box>Step 6 | Tax Regulations</Box>
        </Breadcrumbs>
        <form onSubmit={handleSubmit}>
          <Stack>
            <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
              <Box fz='sm' fw='bold' c='secondary' pr='1rem'>
                Tax residency
              </Box>
              <Box fz='xs' c={colorAliases.textInvertSecondary}>
                Tax regulations require us to collect information regarding the account holder&apos;s tax residency.{' '}
                <br />
                We may be required to share this information with the relevant tax authorities
              </Box>
            </Box>
            <Select
              labelProps={{ c: 'secondary' }}
              label='Company country of residence for tax purpose'
              {...form.getInputProps('company.countryOfIncorporation')}
              {...getSelectPropsForCountry(form.getValues().company.countryOfIncorporation)}
              key={form.key('company.countryOfIncorporation')}
            />
            <TextInput
              labelProps={{ c: 'secondary' }}
              label='Tax Identification Number (TIN)'
              description='If TIN is not available, please explain.'
              {...form.getInputProps('company.taxIdentification.tinCountryOfIncorporation')}
            />
            {form.getValues().company?.taxIdentification?.additionalCountries?.map((_taxCountry, index) => (
              <Stack
                gap='xl'
                key={index}
                px='lg'
                py='md'
                sx={(theme) => ({
                  background: getThemeColor(colorAliases.surfaceLight, theme),
                  border: `1px solid ${getThemeColor(colorAliases.surfaceLightgray, theme)}`,
                })}
              >
                <Select
                  labelProps={{ c: 'secondary' }}
                  label='Company country of residence for tax purpose'
                  searchable
                  {...getSelectPropsForCountry(
                    form.getValues().company?.taxIdentification?.additionalCountries?.[index]?.countryCode
                  )}
                  {...form.getInputProps(`company.taxIdentification.additionalCountries.${index}.countryCode`)}
                />
                <TextInput
                  labelProps={{ c: 'secondary' }}
                  label='Tax Identification Number (TIN)'
                  description='If TIN is not available, please explain.'
                  descriptionProps={{ c: 'dark', pt: '0.25rem' }}
                  {...form.getInputProps(`company.taxIdentification.additionalCountries.${index}.tin`)}
                />
                <Button
                  bg='negative'
                  c='error'
                  onClick={() => form.removeListItem('company.taxIdentification.additionalCountries', index)}
                  sx={{ justifySelf: 'end' }}
                >
                  Remove
                </Button>
              </Stack>
            ))}
            {form.getValues().company?.taxIdentification?.additionalCountries?.length === undefined ||
              (form.getValues().company.taxIdentification.additionalCountries.length < 2 && (
                <Button
                  color='secondary'
                  h='2.6rem'
                  size='xs'
                  w='100%'
                  onClick={() =>
                    form.insertListItem('company.taxIdentification.additionalCountries', {
                      countryCode: null,
                      tin: '',
                    })
                  }
                  leftSection={<IconPlusClear style={{ color: 'white', height: '1rem' }} />}
                >
                  Add another tax residency country
                </Button>
              ))}

            <Box lh='1.55' p='1rem' sx={helpBorder} bg={colorAliases.surfaceLightgray}>
              <Box fz='sm' fw='bold' c='secondary' pr='1rem'>
                CRS/FATCA entity type
              </Box>
              <Box fz='xs' c={colorAliases.textInvertSecondary} pr='1rem'>
                Click&nbsp;
                <Anchor component='button' type='button' c='primary' fw='bold' onClick={openHelp}>
                  here
                </Anchor>
                &nbsp; to learn more about Active and Passive Non Financial Entity/Non Financial Foreign Entity.
                <TaxRegulationsModal opened={helpOpened} onClose={closeHelp} />
              </Box>
            </Box>
            <Radio.Group
              labelProps={{ c: 'secondary' }}
              label='Is the company an Active or Passive Non Financial Entity/Non Financial Foreign Entity for CRS/FATCA purposes?'
              {...form.getInputProps('company.crsFatcaType')}
              key={form.key('company.crsFatcaType')}
            >
              <Stack gap='sm' mt='sm' mb={form.getInputProps('company.crsFatcaType').error ? 'sm' : 0}>
                {(Object.keys(CrsFatcaMapper) as CrsFatcaType[]).map((k) => (
                  <Radio key={k} value={k} label={CrsFatcaMapper[k]} />
                ))}
              </Stack>
            </Radio.Group>
            <Group justify='space-between' mt='lg'>
              <Button color='dark' onClick={() => navigate(`/onboarding/${invitationCode}/step/5`)}>
                Previous
              </Button>
              <Group>
                <Button name='save' type='submit'>
                  Save
                </Button>
                <Button name='saveAndContinue' type='submit'>
                  Save and continue
                </Button>
              </Group>
            </Group>
          </Stack>
        </form>
      </Paper>
    </Flex>
  )
}

export default TaxRegulations
